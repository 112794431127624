<template>
  <v-card :elevation="1"
          rounded="lg"
          class="pa-4"
          style="height:100%; width:100%; overflow: scroll">
    <div class="font-weight-bold text-lg">
      {{ conf.title }}
      <span
          class="font-weight-medium grey--text text--lighten-1">périodes n-1 {{
          conf.data.fullperiod ? "complète" : "à dates"
        }}</span>
    </div>
    <v-data-table class="mt-3"
                  :loading="items.length < 1 && loading"
                  :headers="headers"
                  :items="items"
                  search-field="name"
                  item-key="name"
                  hide-default-footer
                  v-if="items.length > 0"
    >
      <template v-slot:header.day="{header}" v-if="!conf.data.fullperiod">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex flex-column justify-space-around">
              <p v-bind="attrs" class="mb-0"
                 v-on="on">{{ header.text }}
              </p>
              <span class="caption grey--text lighten-1">{{ getDescSubHeader(header.value) }}</span>
            </div>

          </template>
          <div class="d-flex flex-column caption" v-html="getTooltipPeriod(header.value)"/>
        </v-tooltip>

      </template>
      <template v-slot:header.week="{header}" v-if="!conf.data.fullperiod">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex flex-column justify-space-around">
              <p v-bind="attrs" class="mb-0"
                 v-on="on">{{ header.text }}
              </p>
              <span class="caption grey--text lighten-1">{{ getDescSubHeader(header.value) }}</span>
            </div>

          </template>
          <div class="d-flex flex-column caption" v-html="getTooltipPeriod(header.value)"/>
        </v-tooltip>

      </template>
      <template v-slot:header.month="{header}" v-if="!conf.data.fullperiod">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex flex-column justify-space-around">
              <p v-bind="attrs" class="mb-0"
                 v-on="on">{{ header.text }}
              </p>
              <span class="caption grey--text lighten-1">{{ getDescSubHeader(header.value) }}</span>
            </div>

          </template>
          <div class="d-flex flex-column caption" v-html="getTooltipPeriod(header.value)"/>
        </v-tooltip>

      </template>
      <template v-slot:header.year="{header}" v-if="!conf.data.fullperiod">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex flex-column justify-space-around">
              <p v-bind="attrs" class="mb-0"
                 v-on="on">{{ header.text }}
              </p>

              <div><span class="caption grey--text lighten-1">{{ getDescSubHeader(header.value).old }}</span> / <span>{{getDescSubHeader(header.value).act}}</span></div>
            </div>

          </template>
          <div class="d-flex flex-column caption" v-html="getTooltipPeriod(header.value)"/>
        </v-tooltip>

      </template>
      <template v-slot:body="{ items }">
        <tbody>
        <tr
            v-for="item in items"
            :key="item.name"
        >
          <td class="">{{ item.name }}</td>
          <td class="text-right">
            <div class="d-flex flex-column">
              <span class=""> {{ _formatCurrency(item.day.value, "EUR") }}</span>
              <span class="caption grey--text lighten-1">{{
                  _formatCurrency(item.day.oldValue, "EUR")
                }}
                <span :class="['ml-2',item.day.evo[0] === '-' ? 'red--text' : 'green--text']">{{ item.day.evo }}</span></span>
            </div>
          </td>
          <td class="text-right">
            <div class="d-flex flex-column">
              <span class=""> {{ _formatCurrency(item.week.value, "EUR") }}</span>
              <span class="caption grey--text lighten-1">{{
                  _formatCurrency(item.week.oldValue, "EUR")
                }}
                <span :class="['ml-2',item.week.evo[0] === '-' ? 'red--text' : 'green--text']">{{
                    item.week.evo
                  }}</span>
              </span>
            </div>
          </td>
          <td class="text-right">
            <div class="d-flex flex-column">
              <span class=""> {{ _formatCurrency(item.month.value, "EUR") }}</span>
              <span class="caption grey--text lighten-1">{{
                  _formatCurrency(item.month.oldValue, "EUR")
                }}
                <span :class="['ml-2',item.month.evo[0] === '-' ? 'red--text' : 'green--text']">{{
                    item.month.evo
                  }}</span>
              </span>
            </div>
          </td>
          <td class="text-right">
            <div class="d-flex flex-column">
              <span class=""> {{ _formatCurrency(item.year.value, "EUR") }}</span>
              <span class="caption grey--text lighten-1">{{
                  _formatCurrency(item.year.oldValue, "EUR")
                }}
                <span :class="['ml-2',item.year.evo[0] === '-' ? 'red--text' : 'green--text']">{{
                    item.year.evo
                  }}</span>
              </span>
            </div>
          </td>
        </tr>
        </tbody>

      </template>


    </v-data-table>
  </v-card>
</template>
<script>
import {EventBus} from "@/components/commons/event-bus";
import {get, post} from "@/utils/api";
// Chiffres d'affaires par site (données année n-1)
export default {
  name: "WidgetCA",

  data() {
    return {
      loading: false,
      items: [],
      headers: [
        {
          text: "Site",
          value: "name",
          align: "left",
          sortable: false,
        },
        {
          text: "CA du Jour (€)",
          value: "day",
          align: "right",
          sortable: false,
        },
        {
          text: "CA de la semaine (€)",
          value: "week",
          align: "right",
          sortable: false,
        },
        {
          text: "CA du mois (€)",
          value: "month",
          align: "right",
          sortable: false,
        },
        {
          text: "CA de l'année (€)",
          value: "year",
          align: "right",
          sortable: false,
        }],
      url: `/api/widget/dashboard/ca/site`,
      totaux: {},
      totauxPast: {}
    }
  },
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  created() {
    this.init()
    EventBus.$on('searchDashboard', () => this.init());
  },
  computed: {},
  methods: {
    init() {
      if (this.conf.data.fullperiod) {
        this.url += "/full"
      }

      this.items = []
      post(this.url, this.conf.data.sites, {"Content-Type": "application/json"})
          .json(r => {
            let dataList = []
            r.by_days.forEach(site => {
              dataList.push({
                name: site.name,
                day: {
                  startPeriod: site.startPeriod,
                  endPeriod: site.endPeriod,
                  oldStartPeriod: site.oldStartPeriod,
                  oldEndPeriod: site.oldEndPeriod,
                  value: this._formatCurrency(site.value, "EUR"),
                  oldValue: this._formatCurrency(site.oldValue, "EUR"),
                  evo: this.evolution(site.value, site.oldValue)
                },
              })
            })
            dataList.map(data => {

              const byWeeks = r.by_weeks.find(site => site.name == data.name)
              const byMonths = r.by_month.find(site => site.name == data.name)
              const byYear = r.by_year.find(site => site.name == data.name)

              data.week = {
                startPeriod: byWeeks.startPeriod,
                endPeriod: byWeeks.endPeriod,
                oldStartPeriod: byWeeks.oldStartPeriod,
                oldEndPeriod: byWeeks.oldEndPeriod,
                value: this._formatCurrency(byWeeks.value, "EUR"),
                oldValue: this._formatCurrency(byWeeks.oldValue, "EUR"),
                evo: this.evolution(byWeeks.value, byWeeks.oldValue)
              }

              data.month = {
                startPeriod: byMonths.startPeriod,
                endPeriod: byMonths.endPeriod,
                oldStartPeriod: byMonths.oldStartPeriod,
                oldEndPeriod: byMonths.oldEndPeriod,
                value: this._formatCurrency(byMonths.value, "EUR"),
                oldValue: this._formatCurrency(byMonths.oldValue, "EUR"),
                evo: this.evolution(byMonths.value, byMonths.oldValue)
              }

              data.year = {
                startPeriod: byYear.startPeriod,
                endPeriod: byYear.endPeriod,
                oldStartPeriod: byYear.oldStartPeriod,
                oldEndPeriod: byYear.oldEndPeriod,
                value: this._formatCurrency(byYear.value, "EUR"),
                oldValue: this._formatCurrency(byYear.oldValue, "EUR"),
                evo: this.evolution(byYear.value, byYear.oldValue)
              }
            })
            this.items.push(...dataList, this.getTotal(r))
          })
    },
    getTotal(list) {
      let dayTotal = 0
      let dayOld = 0
      let weekTotal = 0
      let weekOld = 0
      let monthTotal = 0
      let monthOld = 0
      let yearTotal = 0
      let yearOld = 0
      list.by_days.forEach(day => dayTotal += day.value)
      list.by_days.forEach(day => dayOld += day.oldValue)
      list.by_weeks.forEach(w => weekTotal += w.value)
      list.by_weeks.forEach(w => weekOld += w.oldValue)
      list.by_month.forEach(m => monthTotal += m.value)
      list.by_month.forEach(m => monthOld += m.oldValue)
      list.by_year.forEach(y => yearTotal += y.value)
      list.by_year.forEach(y => yearOld += y.oldValue)

      return {
        name: "Totaux",
        day:
            {
              value: dayTotal,
              oldValue: dayOld,
              evo: this.evolution(dayTotal, dayOld)
            },
        week: {
          value: weekTotal,
          oldValue: weekOld,
          evo: this.evolution(weekTotal, weekOld)
        },
        month: {value: monthTotal, oldValue: monthOld, evo: this.evolution(monthTotal, monthOld)},
        year: {value: yearTotal, oldValue: yearOld, evo: this.evolution(yearTotal, yearOld)},
      }
    },
    evolution(act, old) {
      const result = this._formatEvolutionPercent(old, act)
      return result[0] == "-" ? result : "+" + result;
    },
    getDescSubHeader(duration) {
      if(duration == "day"){
        return this._formatDate(new Date(this.items[0][duration].startPeriod), 'dd MMM')
      } else if (duration == 'week') {
        return "Semaine " + this._formatDate(new Date(this.items[0][duration].startPeriod), 'I')
      } else if (duration == "month"){
        return this._formatDate(new Date(this.items[0][duration].startPeriod), 'MMMM')
      } else if (duration == 'year'){
        return { old: this._formatDate(new Date(this.items[0][duration].oldStartPeriod), 'yyyy'),
        act:this._formatDate(new Date(this.items[0][duration].startPeriod), 'yyyy')}
      }
      return ""
    },
    getTooltipPeriod(duration) {
      if (this.items.length > 0) {
        /*this.items[0][duration].startPeriod === this.items[0][duration].endPeriod &&
                    this.items[0][duration].oldStartPeriod === this.items[0][duration].oldEndPeriod*/
        if (duration == 'day') {

          return `Le ${this._formatDate(new Date(this.items[0][duration].startPeriod), 'dd MMM yyyy')}
           comparé au ${this._formatDate(new Date(this.items[0][duration].oldStartPeriod), 'dd MMM yyyy')}`


        } else {
          return `
                Période du ${this._formatDate(new Date(this.items[0][duration].startPeriod), ' EEE dd MMM')}
                au ${this._formatDate(new Date(this.items[0][duration].endPeriod), 'EEE dd MMM yyyy')}, <br/>
                par rapport au ${this._formatDate(new Date(this.items[0][duration].oldStartPeriod), 'EEE dd MMM')}
                jusqu'au ${this._formatDate(new Date(this.items[0][duration].oldEndPeriod), 'EEE dd MMM yyyy')}`

        }
      } else {
        return 'pas de données'
      }
    },

  },

}
</script>