<template>
  <v-card ref="vCardRef" :elevation="1"
          rounded="lg"
          class="pa-4"
          style="height:100%; width:100%; overflow: hidden">
    <div class="mr-10 font-weight-bold">
      {{ conf.title }}
    </div>
    <apexchart
        ref="apexcharts"
        type="line"
        :height="series.length > 0 ? chartHeight : null"
        :options="options"
        :series="series"
        v-if="series.length > 0"

    ></apexchart>
  </v-card>
</template>
<script>
import {post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";
import {ref} from "vue";
import {isValid} from "date-fns";

export default {
  name: "CountLeadChart",
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  data() {
    return {
      vCardRef: ref(null),
      url: `/api/widget/dashboard/lead/charts`,
      series: [],
      searchform: null,
      options: {
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          width: 3,
          dashArray: 0,
        },
        chart: {
          defaultLocale: 'fr',
          id: "area",
          datetimeUTC: false,
          type: "area",
          stacked: false,
          height: 350,
          zoom: {
            enabled: false,
            autoScaleYaxis: true,
          },
          locales: [{
            name: "fr",
            options: {
              months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"],
              shortMonths: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
              days: ["Dimanche", "Lundi", "Merdi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
              shortDays: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
            }
          }],
        },
        yaxis: {
          type: 'numeric',
          decimalsInFloat: false,
        },
        xaxis: {
          floating: false,
          position: 'bottom',
          axisTicks: {
            show: true,
          },
          min: new Date(`${new Date().getFullYear()}-01-01`),
          max: new Date(`${new Date().getFullYear()}-12-31`),
          type: 'datetime',
          tickAmount: 'dataPoints',
          labels: {
            datetimeUTC: false,
            show: true,
            datetimeFormatter: {
              year: 'yyyy',
              month: 'MMMM ',
              day: 'dd MMMM',
              hour: 'HH:mm'
            },
            rotate: -45,
            rotateAlways: false,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: true,
            minHeight: undefined,
            maxHeight: 120,
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
          }
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          custom: {},
          x: {
            show: true,

          },
          fixed: {
            enabled: false,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },

        },
        fill: {
          type: 'gradient',
        },
        legend: {position: 'top'},
        annotations: {
          xaxis: [],
        },
      },
    }
  },
  created() {
    EventBus.$on('editMode', () => this.updateSize())
    EventBus.$on('searchDashboard', () => this.init());
    this.init()
  },
  mounted() {
    this.vCardRef = this.$refs.vCardRef.$el.clientHeight
    this.conf.chartOptions = {...this.options, ...this.conf.chartOptions}
    delete this.conf.chartOptions.xaxis.min
    delete this.conf.chartOptions.xaxis.max
  },
  computed: {
    chartHeight() {
      return this.vCardRef != null ? `${this.$refs.vCardRef.$el.clientHeight - 50}px` : 'auto';
    },
  },
  methods: {
    init() {
      this.searchform = this._formatPayloadWidgetDashboard(this.conf, this.form)
      this.options.tooltip.y = {
        formatter: function (val) {
          return val.count
        }
      }
      post(this.url, this.searchform, {"Content-Type": "application/json"})
          .json(r => {
            this.series = [{data: this.fullFilledEmptyData(r)}]


            const self = this
            this.options.tooltip.custom = function ({series, seriesIndex, dataPointIndex, w}) {
              const day = w.globals.initialSeries[0].data[dataPointIndex]
              return `
                  <div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                  ${self._formatDate(new Date(day.x), "eee dd MMMM yyyy")}
                  </div>
                  <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;" >
                  <span class="apexcharts-tooltip-marker" style="background-color: rgb(200, 200, 200)"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                      <div class="apexcharts-tooltip-y-group d-flex flex-row justify-space-between">
                        <span class="apexcharts-tooltip-text-y-label">Compte journalier:</span>
                        <span class="apexcharts-tooltip-text-y-value"> + ${day.count} </span>
                      </div>
                    </div>
                    </div>
                    <div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">
                  <span class="apexcharts-tooltip-marker" style="background-color: rgb(90, 158, 245)"></span>
                    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">
                      <div class="apexcharts-tooltip-y-group d-flex flex-row justify-space-between">
                        <span class="apexcharts-tooltip-text-y-label">Total: </span>
                        <span class="apexcharts-tooltip-text-y-value">${day.y}</span>
                      </div>
                    </div>
                  </div>
                </div>`

            }
          })
    },
    updateSize() {
      this.vCardRef = this.$refs.vCardRef != null ? this.$refs.vCardRef.$el.clientHeight : 0
    },

    fullFilledEmptyData(datas) {
      const start = new Date(this.searchform.startDateAct)
      const end = new Date(this.searchform.stopDateAct)
      let dates = []
      let i = 0;
      while (this._isSameOrBefore(this._addDays(start, i), end, "days")) {
        const day = this._formatDate(this._addDays(start, i), 'yyyy-MM-dd')
        const data = datas.data.find(d => day === d.date)
        dates.push({x: day, count: data != null ? data.count : 0, y: 0})
        i++;
      }
      let count = datas.totalAtStart
      dates.forEach(date => {
        count += date.count
        date.y = count
      })
      return dates
    },
  },
}
</script>