<template>
  <div>
    <v-data-table class="elevation-1 tbl"
                  dense
                  :loading="loading"
                  :headers="headers"
                  :items="leadsList"
                  :items-per-page="-1"
                  search-field="name"
                  item-key="name"
                  hide-default-footer>

      <template #item="{item}">
        <tr
            @click.ctrl="toLead(item, true)"
            @click.meta="toLead(item, true)"
            @click.exact="toLead(item)">
          <td v-if="getHeader('site')">
            <div class="d-flex flex-column">
              <span class="caption grey--text text-lighten-2">#{{ item.id }}</span>
              <site-label :sitename="item.virtualSite"/>
            </div>
          </td>

          <td v-if="getHeader('score')" colspan="0.7%">
            <div class="d-flex flex-column">
              <score-lead :score="item.score"/>
            </div>
          </td>

          <td v-if="getHeader('customer')">
            <div class="d-flex flex-column">
              <div class="body-2 d-flex flex-column" v-if="item.customer">
                <span>{{ item.customer.name }} </span>
                <span>
                  <span class="caption grey--text text--darken-1">{{ item.customer.email }}</span> <span
                    class="caption grey--text " v-if="item.customer.phone"> | {{ item.customer.phone }}</span>

              </span>

              </div>
              <span class="body-2" v-else>-</span>

            </div>
          </td>

          <td v-if="getHeader('user')">
            <div class="d-flex flex-column" v-if="item.user?.name">
              {{ item.user.name }}
            </div>
          </td>

          <td v-if="getHeader('creationDate')">
            <div class="d-flex flex-column">
              <span>{{
                  _parseAndFormatDate(item.creationDate, datePatternConfig.serverLocalDateTime, "dd/MM/yyyy")
                }}</span>
              <span class="caption grey--text ">{{
                  _parseAndFormatDate(item.creationDate, datePatternConfig.serverLocalDateTime, "HH'h'mm")
                }}</span>
            </div>
          </td>
          <td v-if="getHeader('earlierStartdate')">
            <div class="d-flex flex-column" v-if="item.earlierStartdate && item.quotes > 0">
              <span>{{
                  _parseAndFormatDate(item.earlierStartdate, datePatternConfig.serverLocalDateTime, "dd/MM/yyyy")
                }}</span>
              <span class="caption grey--text ">{{
                  _parseAndFormatDate(item.earlierStartdate, datePatternConfig.serverLocalDateTime, "HH'h'mm")
                }}</span>
            </div>
            <span v-else>-</span>
          </td>


          <td v-if="getHeader('quotes')">
            <div class="d-flex justify-start caption">
              {{ item.quotes }}
            </div>
          </td>
          <td v-if="getHeader('destinations')">
            <div class="d-flex justify-start body-2">
              {{ item.destinations.join(", ") || "-" }}
            </div>
          </td>
          <td v-if="getHeader('budget')">
            {{ item.budget |currency('EUR') }}
          </td>
          <td v-if="getHeader('action')">
            <div class="d-flex flex-column" v-if="item.lastActionType">

              <span class="caption">{{ $t("lead.action." + item.lastActionType) }}</span>
              <span class="caption grey--text lighten-1"
                    v-if="item.lastActionDate">{{ item.lastActionDate.replace(" ", " - ") }}</span>
            </div>
            <div v-else>-</div>
          </td>
          <td v-if="getHeader('source')">
            <div class="">
              <v-chip small label :color="sourceColor(item)" text-color="white" class="mr-3">{{
                  $t('lead.source.' + item.source)
                }}
              </v-chip>
            </div>
          </td>
          <td v-if="getHeader('status')">
            <div class="">
              <v-chip :text-color="colorTag(item)" small label outlined
                      :color="colorTag(item)">{{
                  item.status == 'close' && item.converted ? "Converti" : $t('lead.status.' + item.status)
                }}
              </v-chip>
            </div>
          </td>
        </tr>
      </template>

    </v-data-table>
  </div>
</template>
<script>
import ScoreLead from "@/components/booking/ScoreLead.vue";
import SiteLabel from "@/components/commons/SiteLabel.vue";
import FormMixin from "@/components/mixins/FormMixin";
import {EventBus} from "@/components/commons/event-bus";

export default {
  components: {
    ScoreLead,
    SiteLabel,
  },
  mixins: [FormMixin],
  props: {
    leadsList: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      loading: false,
      newLeadModal: false,
      headers: [
        {
          text: "Site",
          value: "site",
          align: "left",
          width: '5%',
          sortable: true,
        },
        {
          text: "Score",
          value: "score",
          align: "left",
          width: '5%',
          sortable: true,
        },
        {
          text: "Client",
          value: "customer",
          align: "left",
          width: '15%',
          sortable: true,
          sort: (x, y) => {
            if (!x && y) {
              return 1
            } else if (x && !y) {
              return -1
            }

            if (x < y) {
              return -1;
            } else if (x > y) {
              return 1;
            } else return 0;
          }
        },
        {
          text: "Utilisateur",
          value: "user",
          align: "left",
          width: '10%',
          sortable: true,
          sort: (x, y) => {
            if (!x && y) {
              return 1
            } else if (x && !y) {
              return -1
            }

            if (x < y) {
              return -1;
            } else if (x > y) {
              return 1;
            } else return 0;
          }
        },
        {
          text: "Création",
          value: "creationDate",
          align: "left",
          sortable: true,
          sort: this.sortByDateTime
        },
        {
          text: "Départ",
          value: "earlierStartdate",
          align: "left",
          sortable: true,
          sort: this.sortByDateTime
        },
        {
          text: "Devis",
          value: "quotes",
          align: "left",
          sortable: true,
        },
        {
          text: "Destinations",
          value: "destinations",
          align: "left",
          sortable: false,
          width: '10%',
        }
        ,
        {
          text: "Budget",
          value: "budget",
          align: "left",
          sortable: true,
        },
        {
          text: "Dernière action",
          value: "action",
          align: "left",
          sortable: true,
        },
        {
          text: "source",
          value: "source",
          align: "left",
          sortable: true,
          width: 90,
        },
        {
          text: "status",
          value: "status",
          align: "right",
          sortable: true,
          width: 90,
        },
      ],
    }
  },
  destroyed() {
    EventBus.$off("reloadListLead");
  },
  methods: {
    getHeader(value) {
      return this.headers.find((x) => x.value === value) != null;
    },
    toLead(lead, newTab) {
      if (newTab) {
        let route = this.$router.resolve({
          name: "lead",
          params: {id: lead.id},
        });
        window.open(route.href, "_blank");

      } else {
        this.$router.push({name: "lead", params: {id: lead.id}});
      }
    }
    ,
    getDestiString(item) {
      return item.destinations.length > 0 ? item.destinations.map(i => i.name).join(', ') : "-"
    },
    sortByDateTime(a, b) {
      return this._sortDates(a, b, this.datePatternConfig.serverLocalDateTime);
    },
    sourceColor(item) {
      switch (item.source) {
        case 'phone':
          return "green";
        case 'form':
          return "#095464";
        case 'email':
          return "purple lighten-2";
        case 'quotation':
          return "orange lighten-1";
        case 'option':
          return 'orange lighten-1';
        case 'payment':
          return 'red';
        default: //backoffice
          return 'blue'
    }
  },
  colorTag(item) {
    return item.status == 'open' || (item.status == 'close' && item.converted)
        ? 'green'
        :
        item.status == 'close'
            ? 'red'
            : 'orange'
  },
  getIcon(action) {
    switch (action) {
      case 'update':
        return "mdi-update";
      case 'call':
        return "mdi-phone";
      case 'email':
        return "mdi-mail";
      case 'proposition':
        return "mdi-currency-eur";
    }
  },

}

}
</script>