<template>
  <layout>
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{ name: 'supplier-invoice-list' }"
          >Liste des Factures</v-tab
        >
        <v-tab :to="{ name: 'supplier-invoice-imports' }">Imports</v-tab>
        <v-tab :to="{ name: 'supplier-invoice-payment' }">Liste des paiements</v-tab>
      </v-tabs>
    </template>

    <template slot="content">
      <v-container>
        <v-row>
          <v-col cols="3">
            <v-card>
              <v-card-title>Import Vols</v-card-title>
              <v-divider />
              <v-card-text>
                <document-uploader
                  url="/api/accounting/supplier-invoice/import-flights"
                  acceptedFiles=".csv"
                  @uploaded="uploaded"
                  @error="onError"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card>
              <v-card-title>Import Assurances</v-card-title>
              <v-divider />
              <v-card-text>
                <document-uploader
                  url="/api/accounting/supplier-invoice/import-insurances"
                  acceptedFiles=".csv"
                  @uploaded="uploaded"
                  @error="onError"
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card>
              <v-card-title>Import multiple</v-card-title>
              <v-divider />
              <v-card-text>
                <document-uploader
                  url="/api/accounting/supplier-invoice/import-prestations"
                  acceptedFiles=".csv"
                  @uploaded="multipleUploaded"
                  @error="onError"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <smart-dialog
        v-model="error.active"
        max-width="600"
        title="Erreur"
        scrollable
      >
        <template slot="actions">
          <v-btn small @click="error.active = false">fermer</v-btn>
        </template>
        <div class="pa-2">
          Aucune Facture n'a été importé:
          <ul>
            <li v-for="(e, eIndex) in error.errors" :key="eIndex">{{ e }}</li>
          </ul>
        </div>
      </smart-dialog>

      <smart-dialog
        v-model="dialog.multiple.show"
        width="1200"
        title="Import multiple"
        scrollable
      >
        <template slot="actions">
          <v-btn small @click="dialog.multiple.show = false">fermer</v-btn>
          <v-btn small @click="sendInvoices">Créer les factures</v-btn>
        </template>
        <v-card-text>
          <v-row dense>
            <v-col cols="3">
              <v-autocomplete
                v-model="supplierInvoice.supplierAccountingId"
                :items="listSuppliers"
                item-value="id"
                item-text="name"
                label="Fournisseur"
                :rules="rules.abs('Fournisseur obligatoire')"
                required
                validate-on-blur
                @change="supplierChange"
              />
            </v-col>

            <v-col cols="3">
              <v-select
                v-model="supplierInvoice.bankAccount"
                :items="bankAccounts"
                :rules="rules.nonEmpty()"
                validate-on-blur
                label="Compte banque"
                required
              />
            </v-col>
            <v-spacer></v-spacer>

            <v-col cols="2">
              <v-select
                v-model="supplierInvoice.currencyCode"
                required
                label="Monnaie"
                :items="listCurrency"
                :rules="rules.nonEmpty()"
                item-value="code"
                item-text="symbol"
              />
            </v-col>
            <v-col cols="2">
              <v-select
                v-if="supplierInvoice.currencyCode !== 'EUR'"
                label="Portefeuille"
                v-model="supplierInvoice.walletId"
                :items="wallet4currency"
                item-value="id"
                item-text="name"
                @change="changeWallet"
              >
                <template slot="selection" slot-scope="{ item, index }">
                  <span v-if="item.id === null">{{ item.name }}</span>
                  <span v-else>@{{ item.euroRate }}€</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="1">
              <v-text-field
                v-if="
                  supplierInvoice.currencyCode !== 'EUR' && showCustomEuroRate
                "
                :disabled="!disableChangeRate"
                reverse
                :prefix="$t(supplierInvoice.currencyCode + '-symbol')"
                suffix="@"
                v-model="supplierInvoice.euroRate"
                right
                :label="'Taux: 1€ = ?'"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="3">
              <smart-picker
                v-model="supplierInvoice.creationDate"
                label="Date de la facture"
                format="dd/MM/yyyy HH:mm:ss"
                class="pt-0"
              ></smart-picker>
            </v-col>
            <v-col cols="3">
              <smart-picker
                v-model="supplierInvoice.maxPaymentDate"
                format="dd/MM/yyyy HH:mm:ss"
                label="Date limite de paiement"
              ></smart-picker>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <div class="pa-2">
            <v-data-table
              v-if="supplierInvoice.supplierAccountingId > 0"
              :headers="dialog.multiple.headers"
              :items="dialog.multiple.items"
              :expanded.sync="dialog.multiple.expanded"
              :loading="dialog.multiple.loading"
              item-key="bookingId"
              show-expand
              class="elevation-1"
              disable-pagination
              hide-default-footer
            >
              <template #item.invoicePrice="{ item }">
                <span
                  :class="[
                    item.invoicePrice < item.total ? 'blue--text' : '',
                    item.invoicePrice > item.total ? 'red--text' : '',
                  ]"
                  >{{ item.invoicePrice }}</span
                >
              </template>
              <template #item.warn="{ item }">
                {{ item.warn ? "/!\\ Prestations déjà facturés" : "" }}
              </template>

              <template #item.discord="{ item, index }">
                <v-checkbox
                  v-model="dialog.multiple.items[index].discord"
                ></v-checkbox>
              </template>

              <template v-slot:expanded-item="{ headers, item, index }">
                <td :colspan="headers.length" class="py-2">
                  <v-data-table
                    :headers="dialog.multiple.headersPrestation"
                    :items="item.prestations"
                    disable-pagination
                    hide-default-footer
                  >
                    <template
                      #item.active="{
                        item: prestation,
                        index: prestationIndex,
                      }"
                    >
                      <v-checkbox
                        v-model="
                          dialog.multiple.items[item.line - 1].prestations[
                            prestationIndex
                          ].active
                        "
                        @click="updateTotalAmount(item.line - 1)"
                      ></v-checkbox>
                    </template>
                    <template
                      #item.realPurchasePrice="{
                        item: prestation,
                        index: prestationIndex,
                      }"
                    >
                      <v-text-field
                        v-model.number="
                          dialog.multiple.items[item.line - 1].prestations[
                            prestationIndex
                          ].realPurchasePrice
                        "
                        @input="updateTotalAmount(item.line - 1)"
                      ></v-text-field>
                    </template>
                    <template #item.attachedInvoices="{ item }">
                      <div>
                        <a
                          v-for="(ai, i) in item.attachedInvoices"
                          :key="i"
                          :href="`/accounting/supplier-invoice/${ai}`"
                          target="_blank"
                          class="mr-2"
                          >{{ ai }}</a
                        >
                      </div>
                    </template>
                    <template #item.purchase="{ item }">
                      {{ item.purchase | currency(item.purchaseCurrency) }}
                    </template>
                    <template #item.supplierStatus="{ item }">
                      <availability
                        v-model="item.supplierStatus"
                        kind="SupplierStatus"
                      />
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>
          </div>
        </v-card-text>
      </smart-dialog>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import { get, post } from "@/utils/api";
import DocumentUploader from "@/components/commons/DocumentUploader";
import FormMixin from "@/components/mixins/FormMixin";
import Availability from "@/components/commons/Availability";

export default {
  name: "SupplierInvoiceImports",
  mixins: [FormMixin],
  components: { Layout, DocumentUploader, Availability },
  props: [],
  data: () => ({
    error: {
      active: false,
      errors: [],
    },
    dialog: {
      multiple: {
        supplierId: null,
        show: false,
        loading: false,
        headers: [
          { value: "line", text: "Ligne" },
          { value: "bookingId", text: "Dossier" },
          { value: "invoiceRef", text: "Ref facture" },
          { value: "invoiceDate", text: "Date facture" },
          { value: "invoicePrice", text: "Prix facture" },
          { value: "total", text: "Total achats" },
          { value: "warn", text: "" },
          { value: "discord", text: "Désaccord" },
          { text: "", value: "data-table-expand" },
        ],
        headersPrestation: [
          { value: "active", text: "" },
          { value: "id", text: "#" },
          { value: "name", text: "Nom" },
          { value: "supplierStatus", text: "Statut" },
          { value: "creationDate", text: "Date résa" },
          { value: "startDate", text: "Date départ" },
          { value: "purchase", text: "Montant prév" },
          { value: "realPurchasePrice", text: "Montant réél" },
          { value: "attachedInvoices", text: "Factures" },
        ],
        raw: [],
        items: [],
        expanded: [],
      },
    },
    listSuppliers: [],
    bankAccounts: [],
    listCurrency: [],
    listWallet: [],
    supplierInvoice: {},
  }),

  created() {
    get("/api/accounting/list-supplier").json(
      (data) => (this.listSuppliers = data)
    );

    get("/api/accounting/supplier-invoice/empty").json(
      (data) => (this.supplierInvoice = data)
    );

    get("/api/accounting/bank-accounts").json(
      (data) => (this.bankAccounts = data)
    );

    get("/api/accounting/list-status").json((data) => (this.listStatus = data));

    get("/api/accounting/list-currency").json(
      (data) => (this.listCurrency = data)
    );

    get("/api/accounting/wallets").json(
      (data) =>
        (this.listWallet = this.listWallet.concat(
          data.map((w) => {
            w.name =
              "@" +
              w.euroRate +
              "€ (" +
              w.amount +
              "/" +
              w.creationAmount +
              " " +
              w.currencyCode +
              ")";
            return w;
          })
        ))
    );
  },
  computed: {
    showCustomEuroRate() {
      return this.supplierInvoice.walletId === null;
    },
    wallet4currency() {
      return this.listWallet.filter((w) => {
        return (
          w.id == null || w.currencyCode === this.supplierInvoice.currencyCode
        );
      });
    },
  },
  methods: {
    uploaded() {},
    changeWallet(wallet) {
      if (wallet.id !== null) {
        this.euroRate = wallet.euroRate;
      }
    },
    updateTotalAmount(index) {
      const total = this.dialog.multiple.items[index].prestations.reduce(
        (total, p) => total + (p.active ? p.realPurchasePrice : 0),
        0
      );
      this.dialog.multiple.items[index].total = total;
    },
    multipleUploaded(resp) {
      this.dialog.multiple.show = true;
      this.dialog.multiple.raw = JSON.parse(resp[0].xhr.response);
    },
    supplierChange() {
      this.dialog.multiple.items = [];
      this.dialog.multiple.loading = true;
      post(
        `/api/accounting/supplier-invoice/import-prestations/supplier?supplierId=${this.supplierInvoice.supplierAccountingId}`,
        this.dialog.multiple.raw
      ).json((data) => {
        this.dialog.multiple.items = data;
        this.dialog.multiple.loading = false;
      });
    },
    sendInvoices() {
      post(`/api/accounting/supplier-invoice/import-prestations/invoices`, {
        invoice: this.supplierInvoice,
        invoices: this.dialog.multiple.items,
      }).res((resp) => {
        this.dialog.multiple.show = false;
      });
    },
    onError(errorMsg) {
      this.error = {
        active: true,
        errors: errorMsg.errors,
      };
    },
  },
};
</script>
