<template>
  <v-card :elevation="1" rounded="lg"
          style="height:100%; width:100%; background-color:white" class="d-flex flex-column ">
    <div class="d-flex flex-row justify-space-between pl-5 pt-5 pr-5 mb-4">
      <div class="font-weight-bold text-lg">
        <span>{{ this.conf.title }}</span>
      </div>
    </div>
    <div class="overflow-y-auto">
      <v-data-table
                    :headers="headers"
                    :items="rowData"
                    :custom-sort="sortByDate"
                    fixed-header
                    hide-default-footer
                    disable-pagination
                    item-key="name"
                    class=""
      >
        <template v-for="header in headers" v-slot:[`item.${header.value}`]="{ item }">
          <span style="cursor:pointer" v-bind:key="header.date"
                @click="showIds(item,header.value)">{{ item[header.value] }}</span>
        </template>

      </v-data-table>
      <smart-dialog :title="idsDialog.title"
                    v-model="idsDialog.open"
      >
        <v-row class="pa-4">
          <v-col cols="2" v-for="id in ids" :key="id.id">

            <v-chip
                class=""
                small
                label
                outlined
                @click="toBookingUrl(id.id)"
            >
              {{ id.id }}
              <v-icon x-small class="ml-2" l>open_in_new</v-icon>
            </v-chip>
          </v-col>
        </v-row>
      </smart-dialog>
    </div>
  </v-card>
</template>
<script>
import {EventBus} from "@/components/commons/event-bus";
import {post} from "@/utils/api";
//Trésorerie Date de résa & Date de solde
export default {
  name: "LeftAmountMatrix",
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  data() {
    return {
      url: `/api/widget/dashboard/matrix/leftamount`,
      data: null,
      loading: true,
      idsDialog: {
        open: false,
        title: ""
      },
      searchForm: null,
      ids: [],
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'name',
          date: "1970-01-01"
        },
      ],
      rowData: [],
    }
  },
  created() {
    EventBus.$on('searchDashboard', () => this.init());
    this.init();
  },
  methods: {
    init() {
      this.reset()
      this.searchForm = this._formatPayloadWidgetDashboard(this.conf, this.form)

      post(this.url, this.searchForm, {
        "Content-Type": "application/json",
      }).json((r) => {
        r.forEach(data => {
          if (!this.headers.some(h => h.value == data.departureDate)) {
            this.headers.push({
              text: this._formatDate(new Date(data.departureDate), "MMM yyyy"),
              align: 'start',
              sortable: false,
              value: data.departureDate,
              date: data.departureDate,
            })
          }
          if (!this.rowData.some(r => r.date == data.bookingDate)) {
            this.rowData.push({
              name: this._formatDate(new Date(data.bookingDate), "MMM yyyy"),
              [data.departureDate]: data.remainingAmount == 0 ? "-" : this._formatDecimal(data.remainingAmount),
              date: data.bookingDate
            })
          } else {
            let row = this.rowData.find(r => r.date == data.bookingDate)
            row[data.departureDate] = data.remainingAmount == 0 ? "-" : this._formatDecimal(data.remainingAmount),
                row.name = this._formatDate(new Date(data.bookingDate), "MMM yyyy")
          }
        })
        this.headers = this.sortByDate(this.headers, null, false)
      })

    },
    sortByDate(items, index, isDesc) {
      items.sort((a, b) => {

        if (!isDesc[0]) {
          return this._sortDates(
              a.date,
              b.date,
              this.datePatternConfig.en.localDate
          );
        } else {
          return this._sortDates(
              b.date,
              a.date,
              this.datePatternConfig.en.localDate
          );
        }
      });
      return items;
    },
    reset() {
      this.headers = [{
        text: '',
        align: 'start',
        sortable: true,
        value: 'name',
        date: "1970-01-01"
      }]
      this.rowData = []
    },
    showIds(item, header) {
      this.ids = []
      const departureStartDate = new Date(header)
      const bookingDateStart = new Date(item.date)
      const body = {
        departureDateStart: this._formatDate(departureStartDate, "yyyy-MM-dd"),
        departureDateStop: this._formatDate(this._subDays(this._addMonths(new Date(header), 1), 1), "yyyy-MM-dd"),
        bookingDateStart: this._formatDate(bookingDateStart, "yyyy-MM-dd"),
        bookingDateStop: this._formatDate(this._subDays(this._addMonths(new Date(item.date), 1), 1), "yyyy-MM-dd"),
        destinations: this.searchForm.destinations,
        sites: this.searchForm.sites
      }
      post('/api/widget/dashboard/matrix/infos/leftamount', body, {
        "Content-Type": "application/json",
      }).json((r) => {
        this.ids = r
        this.idsDialog = {
          open: true,
          title: `Références des dossiers de ${this._formatDate(bookingDateStart, "MMMM yyyy")} & solde en ${this._formatDate(departureStartDate, "MMMM yyyy")}`
        }

      })
    },
    toBookingUrl(id) {
      const isDev = process.env.NODE_ENV == 'development'
      const baseUrl = isDev ? 'http://localhost:8080/vente/dossier/' : 'https://resas.smart-be.com/vente/dossier/'
      window.open(baseUrl + id, "_blank");
    }

  },
}
</script>