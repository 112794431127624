<template>

  <div ref="dialogAfterCall" @keyup="onKeyUp">
    <v-row class="pa-4 mx-3 mt-3 pb-7 ">
      <v-col :cols="btns.length-1 == i ? '12' : btns.length == hlBtns.length ? '6' : '4'" v-for="(btn,i) in btns"
             :key="btn.id" class="d-flex flex-column justify-center text-center ">
        <v-btn elevation="1" height="70px" @click="selectCategory(btn)">
          <v-icon size="25" style="position:absolute; top:0;left:0;">{{ btn.shortcutIcon }}</v-icon>
          <div class="rel d-flex flex-column justify-center text-center">
                <span>
                  <v-icon size="40" class="mr-1">{{ btn.icon }}</v-icon>
                </span>
            <span>{{ btn.name }}</span>
          </div>
        </v-btn>
      </v-col>
    </v-row>
    <button type="button" style="width: 0;height:0;" ref="focusToKUp"/>
  </div>


</template>
<script>
import {post} from "@/utils/api";

export default {
  name: "CallbackPopover",
  props: {
    call: {type: Object, required: true},
    modelValue: {type: Boolean, default: false}
  },
  emits: ['selected'],
  watch: {
    value: {
      immediate: true,
      handler(newVal) {
        if (newVal && this.call) {
          this.btns = this.call.site == "HLI" || this.call.site == "HLF" ? this.hlBtns : this.hotzilBtns

        }
        this.localValue = newVal;
        if (!this.localValue) {
          this.$emit("close");
        }
      },
    },
  },
  data() {
    return {
      localValue: false,
      btns: [],
      hotzilBtns: [
        {
          id: 1,
          category: "Payment",
          name: "Paiement",
          icon: "mdi-cash-multiple",
          shortcutIcon: "mdi-numeric-1",
          shortcut: "1"
        },
        {
          id: 2,
          category: "Reinsurance",
          name: "Réassurance",
          icon: "mdi-shield-home-outline",
          shortcutIcon: "mdi-numeric-2",
          shortcut: "2"
        },
        {
          id: 3,
          category: "Formalities",
          name: "Formalités",
          icon: "mdi-list-box-outline",
          shortcutIcon: "mdi-numeric-3",
          shortcut: "3"
        },
        {
          id: 4,
          category: "InfoBooking",
          name: "Rens / Résas",
          icon: "mdi-cart-check",
          shortcutIcon: "mdi-numeric-4",
          shortcut: "4"
        },
        {
          id: 5,
          category: "InfoQuote",
          name: "Rens / Devis",
          icon: "mdi-cart-arrow-down",
          shortcutIcon: "mdi-numeric-5", shortcut: "5"
        },
        {
          id: 6,
          category: "Other",
          name: "Autre",
          icon: "mdi-chat-question-outline",
          shortcutIcon: "mdi-numeric-6",
          shortcut: "6"
        },
        {
          id: 7,
          category: null,
          name: "Ne pas enregistrer",
          icon: "mdi-content-save-off-outline",
          shortcutIcon: "mdi-keyboard-esc", shortcut: "esc"
        },
      ],
      hlBtns: [
        {
          id: 1,
          category: "Advice",
          name: "Conseil",
          icon: "mdi-cash-multiple",
          shortcutIcon: "mdi-numeric-1",
          shortcut: "1"
        },
        {
          id: 2,
          category: "Technical",
          name: "Technique",
          icon: "mdi-shield-home-outline",
          shortcutIcon: "mdi-numeric-2",
          shortcut: "2"
        },
        {
          id: 4,
          category: "InfoBooking",
          name: "Résas",
          icon: "mdi-cart-check",
          shortcutIcon: "mdi-numeric-4",
          shortcut: "4"
        },
        {
          id: 6,
          category: "Other",
          name: "Autre",
          icon: "mdi-chat-question-outline",
          shortcutIcon: "mdi-numeric-6",
          shortcut: "6"
        },
        {
          id: 7,
          category: null,
          name: "Ne pas enregistrer",
          icon: "mdi-content-save-off-outline",
          shortcutIcon: "mdi-keyboard-esc", shortcut: "esc"
        },
      ],
    }
  },
  created() {
  },
  mounted() {
    this.btns = this.call.site == "HOT" || this.call.site == "ZIL" ? this.hotzilBtns : this.hlBtns
    this.$refs.focusToKUp.focus()
  },
  methods: {
    saveCategoryCall(btn) {
      if (btn != null) {
        const eventId = this.call.phoneCallId ?? this.call.id ?? null
        post(`/api/phonecall/categorize`, {id: eventId, category: btn.category}).res((data) => {
          this.localValue = false
          this.emitClose()
        })
      }
    },
    selectCategory(btn) {
      this.saveCategoryCall(btn)
    },
    onKeyUp(event) {
      const btn = this.btns.find(b => {
        return b.shortcut == event.key
      })
      if (btn != null) {
        this.saveCategoryCall(btn)
      } else {
        console.log("No category selected")
      }
    },
    emitClose() {
      this.$emit('selected', true)
    }
  }
}

</script>
<style scoped>
.rel {
  position: relative;
}

.abs {
  position: absolute;
}

.hl-corner {
  top: 0;
  left: 0;
}

input:focus {
  outline: none;
}
</style>