<template>
  <div>
    <v-card-text class="pa-0">
      <v-row dense>
        <v-col cols="12">
          <vue-dropzone
            ref="dropzone"
            id="dropzone"
            :options="options"
            :useCustomSlot="true"
            :duplicateCheck="true"
            @vdropzone-sending-multiple="sending"
            @vdropzone-complete-multiple="complete"
            @vdropzone-error-multiple="error"
          >
            <div>
              <h3 class="dropzone-custom-title">{{title}}</h3>
              <div class="subtitle">{{subtitle}}</div>
            </div>
          </vue-dropzone>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="actions-btn" v-if="!auto">
      <v-spacer />
      <smart-btn small primary @click.native="upload" :loading="isLoading">
        <v-icon small class="mr-1">send</v-icon>
        {{$t('send')}}
      </smart-btn>
    </v-card-actions>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
//import {EventBus} from '@/components/commons/event-bus'
import { authMethods } from "@/utils/user";

export default {
  name: "DocumentUploader",
  mixins: [],
  props: {
    url: {
      type: String,
      required: true,
    },
    acceptedFiles: {
      type: String,
      default: ".pdf",
    },
    title: {
      type: String,
      default: "Glisser les fichiers ici",
    },
    subtitle: {
      type: String,
      default:
        "...Ou cliquer pour sélectionner les fichiers depuis votre ordinateur",
    },
    auto: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      withError: false,
      isLoading: false,
      options: {
        //url: `/api/document/upload/prestation/${this.prestationid}`,
        url: this.url,
        timeout: 5 * 60 * 1000, // 5mn
        acceptedFiles: this.acceptedFiles,
        thumbnailWidth: 20,
        thumbnailHeight: 20,
        maxFilesize: 10,
        autoProcessQueue: this.auto,
        uploadMultiple: true,
        parallelUploads: 100,
        maxFiles: 100,
        addRemoveLinks: true,
        headers: {
          Authorization: authMethods._getToken(),
        },
      },
    };
  },
  created() {},
  mounted() {
    this.dropzone = this.$refs.dropzone;
  },
  watch: {
    url(newVal){
      this.options.url = newVal
    }
  },
  computed: {},
  methods: {
    upload() {
      this.dropzone.processQueue();
    },
    sending() {
      this.isLoading = true;
    },
    complete(files) {
      if (this.withError) {
        //reset state
        this.withError = false;
      } else {
        this.isLoading = false;
        files.forEach((file) => this.dropzone.removeFile(file));

        //console.log("uploaded", files)
        this.$emit("uploaded", files);
        //show success toaster
      }
    },
    error(file, errorMsg) {
      this.isLoading = false;
      this.withError = true;
      this.$emit("error", errorMsg);
    },
  },
};
</script>

<style scoped>
>>> .dz-preview {
  height: 100px;
  padding: 0;
  width: 100px;
  margin: 10px;
}

>>> .dz-preview .dz-progress {
  height: 7px;
  top: 54%;
}

>>> .dz-preview .dz-remove {
  bottom: 3px;
  font-size: 8px;
  height: 15px;
  border: 1px solid;
  font-weight: normal;
  padding: 2px;
}
</style>
