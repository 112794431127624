<template>
  <v-card ref="vCardRef" :elevation="1" rounded="lg"
          class="pa-4"
          style="height:100%; width:100%; overflow:hidden">
    <span>
    <slot name="title">
      <span class="font-weight-bold">
      {{ conf.title }}
        </span>
    </slot>
    </span>

    <apexchart
        ref="apexcharts"
        type="donut"
        :height="options.series.length > 0 ? chartHeight : 0"
        :options="options"
        :series="options.series"
        v-if="options.series.length > 0"/>
    <div class="d-flex flex-row justify-center">
      <span>Total: {{ _formatDecimal(totalQuote) }}</span>
    </div>
  </v-card>
</template>
<script>
import {defineComponent, ref} from "vue";
import {EventBus} from "@/components/commons/event-bus";
import {post} from "@/utils/api";

export default defineComponent({
  name: "DistributionQuotationChart",
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  data() {
    return {
      vCardRef: ref(null),
      url: `/api/widget/dashboard/quotation/number`,
      options: {
        fill: {
          type: 'fill',
        },
        series: [],
        labels: [],
        chart: {
          type: 'donut',
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 90,
            offsetY: 10
          }
        },
        grid: {
          padding: {
            bottom: -100
          }
        },
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 40,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      }
    }
  },
  created() {
    EventBus.$on('editMode', () => this.updateSize())
    this.init()
    EventBus.$on("searchDashboard", () => this.init());
  },
  mounted() {
    this.vCardRef = this.$refs.vCardRef.$el.clientHeight
    this.options = {...this.options, ...this.conf.chartOptions}
    this.conf.chartOptions = this.options
    delete this.conf.chartOptions.xaxis.min
    delete this.conf.chartOptions.xaxis.max
  },
  computed: {
    chartHeight() {
      return this.vCardRef != null ? `${this.$refs.vCardRef.$el.clientHeight - 100}px` : 'auto';
    },
    totalQuote() {
      let total = 0
      this.options.series.forEach((nb) => total += nb)
      return total
    }
  },
  methods: {
    init() {
      const searchForm = this._formatPayloadWidgetDashboard(this.conf, this.form)
      this.options.series = []
      this.options.labels = []
      post(this.url, searchForm, {"Content-Type": "application/json"})
          .json(r => {
            r.forEach(val => {
              if (val.name != null && val.value != null) {
                this.options.series.push(val.value)
                this.options.labels.push(val.name)
              }
            })

          })
    },
    updateSize() {
      this.vCardRef = this.$refs.vCardRef != null ? this.$refs.vCardRef.$el.clientHeight : 0
    },
  },
})
</script>
<style scoped>
.rel {
  position: relative;
}

.abs {
  position: absolute;
  left: 45%;
  bottom: 40px;

}
</style>