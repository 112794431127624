<template>
  <v-card ref="vCardRef" :elevation="1"
          rounded="lg"
          class="pa-4"
          style="height:100%; width:100%; overflow:hidden">
    <div class="font-weight-bold text-lg mb-10">
      {{ conf.title }}
    </div>
    <apexchart style="width:100%"
               :series="options.series"
               type="bar"
               :height="options.series.length > 0 ? chartHeight : 0"
               :options="options"
    ></apexchart>
  </v-card>
</template>
<script>
import {EventBus} from "@/components/commons/event-bus";
import {post} from "@/utils/api";
import {ref} from "vue";
//Reste à payer par jours avant départ
export default {
  name: "BarLeftPayChart",
  component: {},
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  data: function () {
    return {
      vCardRef: ref(null),
      url: `/api/widget/dashboard/booking/paid-left`,
      options: {
        colors: ['rgb(90, 158, 245)', '#E91E63'],
        series: [],
        chart: {
          defaultLocale: 'fr',
          stacked: true,
          datalabels: {enabled: false},
          toolbar: {
            show: false
          },
          locales: [{
            name: "fr",
            options: {
              months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"],
              shortMonths: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
              days: ["Dimanche", "Lundi", "Merdi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
              shortDays: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
            }
          }],
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 5,
            datalabels: {
              enabled: false
            }
          }
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {width: 2},
        xaxis: {
          type: 'datetime',
          dataLabels: {
            enabled: false,
          },
        },
        yaxis: {
          logarithmic: false,
          dataLabels: {
            enabled: false,
          },
        },
        tooltip: {
          shared: true,
          intersect: false,
          fillSeriesColor: false,
          onDatasetHover: {
            highlightDataSeries: true,
          },
        }
      },
      months: [
        {name: "Janvier", value: 1},
        {name: "Fevrier", value: 2},
        {name: "Mars", value: 3},
        {name: "Avril", value: 4},
        {name: "Mai", value: 5},
        {name: "Juin", value: 6},
        {name: "Juillet", value: 7},
        {name: "Août", value: 8},
        {name: "Septembre", value: 9},
        {name: "Octobre", value: 10},
        {name: "Novembre", value: 11},
        {name: "Décembre", value: 12}
      ]
    }
  },
  created() {
    this.init()
    EventBus.$on('editMode', () => this.updateSize())
    EventBus.$on('searchDashboard', () => this.init());
  },
  mounted() {
    this.vCardRef = this.$refs.vCardRef.$el.clientHeight
    if (this.conf.chartOptions == null) {
      this.conf.chartOptions = this.options
      delete this.conf.chartOptions.xaxis.min
      delete this.conf.chartOptions.xaxis.max
    } else {
      this.options = {...this.options, ...this.conf.chartOptions}
    }
  },
  computed: {
    chartHeight() {
      return this.vCardRef != null ? `${this.$refs.vCardRef.$el.clientHeight - 100}px` : 'auto';
    },
  },
  methods: {
    init() {
      const searchForm = this._formatPayloadWidgetDashboard(this.conf,this.form)
      const self = this
      this.options.yaxis.labels = {
        formatter: (val) => {
          return self._formatCurrency(val, "EUR")
        }
      }
      this.options.tooltip.y = {
        formatter: function (val) {
          return self._formatCurrency(val, 'EUR')
        }
      }

      this.options.series = []
      let datas = post(this.url, searchForm, {"Content-Type": "application/json"})
          .json(r => {

            this.options.series.push({name: "Payé", data: r.yearN.map(d => [d.name, d.sales])})
            this.options.series.push({name: "Reste à payer", data: r.yearN.map(d => [d.name, d.count])});
          })

      Promise.all([datas]).then()
    },
    updateSize() {
      this.vCardRef = this.$refs.vCardRef != null ? this.$refs.vCardRef.$el.clientHeight : 0
    },
  }
}
</script>