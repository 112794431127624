<template>
  <layout :drawer="false">
    <template #nav>
      <v-tabs>
        <v-tab :to="{ name: 'WalletList' }">Portefeuilles</v-tab>
      </v-tabs>
    </template>
    <template #nav-tools>
      <smart-btn
          class="mx-2"
          secondary
          @click.native="walletCreation()"
      >
        <v-icon>mdi-plus</v-icon>
        Nouveau Portefeuille
      </smart-btn>
    </template>
    <template #drawer></template>
    <template #content>
      <div class="pa-4 d-flex justify-center">

        <v-data-table
            class="tbl"
            style="width:70%"
            :items="list.wallets"
            :headers="list.headers"
            :items-per-page="-1"
            hide-default-footer>
          <template #item.actions="{item}">
            <smart-btn secondary small @click.native="edit(item)" class="mr-2">
              <v-icon size="15" class="mr-1">mdi-pen</v-icon>
              Editer
            </smart-btn>
          </template>
          <template #item.id="{value}">
            <span class="ml-2">#{{ value }}</span>
          </template>
          <template #item.active="{value}">
            <div style="width:75px" class="d-flex ">
              <v-chip label small outlined color="green" v-if="value" class="d-flex justify-center" style="width:100%">
                ACTIF
              </v-chip>
              <v-chip label small outlined color="red" v-else class="d-flex justify-center" style="width:100%">INACTIF
              </v-chip>
            </div>
          </template>
          <template #item.creationDate="{value}">
            {{ _parseAndFormatDate(value, "yyyy-MM-dd", "dd/MM/yyyy") }}
          </template>
          <template #item.creationAmount="{value, item}">
            {{ _formatDecimal(value) }} {{$t(item.currencyCode + '-symbol')}}
          </template>
          <template #item.amount="{value, item}">
            {{ _formatDecimal(value) }} {{$t(item.currencyCode + '-symbol')}}
          </template>
        </v-data-table>

        <smart-dialog
            v-model="creation.show"
            title="Nouveau portefeuille"
            @close="closeCreation">
          <v-row class="pa-3">
            <v-col cols="4">
              <v-text-field dense class="mt-5 mb-3" type="text" v-model="creation.name" label="Nom du portefeuille"/>

            </v-col>
            <v-col cols="4">
              <smart-picker dense v-model="creation.creationDate"
                            :inputOpts="{

                label: 'Date de disponibilité'}">
              </smart-picker>
            </v-col>
            <v-col cols="4" class="text-right">
              <v-btn-toggle dense v-model="creation.active" mandatory class="mt-4">
                <v-btn small :value="true" class="green--text">
                  Actif
                </v-btn>
                <v-btn small :value="false" class="red--text">
                  Inactif
                </v-btn>
              </v-btn-toggle>
            </v-col>

            <v-col cols="4">
              <v-text-field dense reverse class="mb-3" type="number" v-model="creation.amount" label="Montant"  :disabled="creation.editMode"/>
            </v-col>
            <v-col cols="4">
              <v-select label="Devise" dense :disabled="creation.editMode" :items="currencies"
                        v-model="creation.currencyCode" item-text="value"
                        item-value="value">
                <template #selection="{item}">
                  {{$t(item.value+'-symbol')}}
                  <span class="ml-2 grey--text caption lighten-1">{{ item.value }}</span>
                </template>
                <template #item="{item}">
                  {{$t(item.value+'-symbol')}}
                  <span class="ml-2 grey--text caption lighten-1">{{ item.value }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field dense class="mb-3" reverse :disabled="creation.editMode" v-model="creation.euroRate" label="Taux: 1€ = ?" :prefix="$t(creation.currencyCode + '-symbol')"/>
            </v-col>
          </v-row>
          <template #actions>
            <v-spacer></v-spacer>
            <smart-btn secondary @click.native="closeCreation()">Annuler</smart-btn>
            <smart-btn class="px-4" :loading="loadingDialog" @click.native="valid">
              {{ creation.editMode ? 'Editer' : 'Créer' }}
            </smart-btn>
          </template>
        </smart-dialog>
      </div>
    </template>
  </layout>
</template>
<script>
import {get, post} from "@/utils/api";
import Layout from "@/views/Layout";
import SmartSelect from "@/components/commons/SmartSelect.vue";

export default {
  name: 'WalletList',
  components: {SmartSelect, Layout},
  data() {
    return {
      loadingDialog: false,
      list: {
        headers: [
          {text: "Ref", value: "id", align: "left", sortable: true, width: '4%'},
          {text: "Actif", value: "active", align: "left", sortable: true, width: '8%'},
          {text: "Date dispo", value: "creationDate", align: "left", sortable: true, width: '14%'},
          {text: "Nom", value: "name", align: "left", sortable: true, width: '18%'},
          {text: "Devise", value: "currencyCode", align: "left", sortable: true, width: '14%'},
          {text: "Taux", value: "euroRate", align: "left", sortable: true, width: '10%'},
          {text: "Montant", value: "amount", align: "left", sortable: true, width: '14%'},

          {text: "Montant création", value: "creationAmount", align: "left", sortable: true, width: '14%'},
          {text: "", value: "actions", align: "right", sortable: false, width: '2%'},
        ],
        wallets: []
      },
      creation: {
        editMode: false,
        show: false,
        id: null,
        name: "",
        amount: 0,
        currencyCode: "USD",
        euroRate: 1,
        creationAmount: 0,
        creationDate: this._formatDate(new Date(), "dd/MM/yyyy"),
        active: true,
      },
      currencies: [
        {value: "USD", icon: "mdi-currency-usd"},
        {value: "XPF", icon: "mdi-currency-fra"},
        {value: "GBP", icon: "mdi-currency-gbp"}
      ],
      defaultCreation: {
        editMode: false,
        show: false,
        id: null,
        name: "",
        amount: 0,
        currencyCode: "USD",
        euroRate: 1,
        creationAmount: 0,
        creationDate: this._formatDate(new Date(), "dd/MM/yyyy"),
        active: true,
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.resetDefaultCreation()
      this.list.wallets = []
      get('/api/accounting/wallets').json((rs) => {
        this.list.wallets = rs
      })
    },
    valid() {
      this.loadingDialog = true
      const body = JSON.parse(JSON.stringify(this.creation))
      body.creationDate = this._parseAndFormatDate(body.creationDate, 'dd/MM/yyyy', 'yyyy-MM-dd')
      delete body.show
      delete body.editMode

      if (this.creation.editMode) {
        post("/api/accounting/wallet/update", body).json(() => {
          this.loadingDialog = false
          this.init()
        })
      } else {
        body.creationAmount = body.amount
        post("/api/accounting/wallet/add", body).json(() => {
          this.loadingDialog = false
          this.init()
        })
      }
    },
    walletCreation() {
      this.creation.show = true
    },
    closeCreation() {
      this.resetDefaultCreation()
    },
    resetDefaultCreation() {
      this.creation = JSON.parse(JSON.stringify(this.defaultCreation))
    },
    edit(wallet) {
      this.creation = {
        editMode: true,
        show: true,
        id: wallet.id,
        name: wallet.name,
        amount: wallet.amount,
        currencyCode: wallet.currencyCode,
        euroRate: wallet.euroRate,
        creationAmount: wallet.creationAmount,
        creationDate: this._parseAndFormatDate(wallet.creationDate, "yyyy-MM-dd", "dd/MM/yyyy"),
        active: wallet.active
      }
    },
    logoCurrency() {
      switch (this.creation.currencyCode) {
        case "XPF":
          return "mdi-currency-fra"
        default:
          return `mdi-currency-${this.creation.currencyCode.toLowerCase()}`
      }

    }
  }
}
</script>
