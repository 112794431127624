<template>
  <v-tabs v-model="tab" centered fill-height>
    <v-tab ripple>
      <v-icon class="mr-2">mdi-account-plus</v-icon>
      {{ maybeCustomer ? 'Modifier' : 'Créer' }}
    </v-tab>
    <v-tab v-if="!isAgency" ripple>
      <v-icon class="mr-2">search</v-icon>
      Rechercher client
    </v-tab>
    <v-tab v-if="isAgency" ripple>
      <v-icon class="mr-2">search</v-icon>
      Rechercher agence
    </v-tab>

    <v-tab-item>
      <v-form
          v-model="customerFormValid"
          ref="customerform"
          @submit.prevent="save"
          autocomplete="off"
      >
        <div v-if="errorUpdateClient != null" class="d-flex flex-column alert">
          <span>Un client avec l'adresse email {{ customer.form.infos.email }} existe déjà <span
              @click="toClient(errorUpdateClient)"
              class="underline btn white--text font-weight-medium">[Voir le client]</span></span>
          <span>Vous devez changer l'adresse email de l'ancien compte client avant de mettre à jour celui ci.</span>
        </div>

        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <customer-infos v-model="customer.form.infos" :is-agency="customer.form.isAgency"></customer-infos>
            </v-col>
            <v-col cols="12">
              <customer-address
                  v-model="customer.form.address"
              ></customer-address>
            </v-col>
            <v-col cols="12" v-if="!customer.form.isAgency">
              <v-checkbox
                  v-model="customer.form.billingAddressIsSame"
                  label="Utiliser la même adresse pour la facturation"
                  hide-details
              ></v-checkbox>
            </v-col>
            <div v-if="!customer.form.billingAddressIsSame">
              <v-subheader light class="pa-0 ma-0"
              >Adresse de facturation
              </v-subheader
              >
            </div>
            <v-col cols="12" v-if="!customer.form.billingAddressIsSame">
              <customer-address
                  v-model="customer.form.billingAddress"
              ></customer-address>
            </v-col>
            <v-col cols="12" v-if="snapshot && snapshot.bookingId && !customer.form.isAgency">
              <v-checkbox
                  v-model="snapshot.saveCustomer"
                  label="Mettre à jour le compte client"
                  hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="actions-btn">
          <v-spacer></v-spacer>
          <smart-btn
              primary
              type="submit"
              :loading="customer.formloading"
              :disabled="!customerFormValid"
          >Enregistrer
          </smart-btn
          >
        </v-card-actions>
      </v-form>
    </v-tab-item>

    <v-tab-item v-if="!isAgency">
      <v-card-text class="card-customer-dialog">
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-model="search.val"
                clearable
                label="Nom, Prénom, Email, Téléphone ou Réf."
                hint="Elements séparés par une virgule: Jean Martin, jean-martin@gmail.com, 0611223344, 1234"
                persistent-hint
                append-icon="mdi-magnify"
                @keypress.enter="searchcustomer"
                @click:append="searchcustomer"
                :loading="search.loading"
            ></v-text-field>
          </v-col>

          <v-col cols="12">

            <v-data-table
                class="elevation-1 tbl"
                :headers="headers"
                :items="search.results"
                :loading="search.isLoading"
                :no-data-text="noDataSentence"
                :options.sync="listing.pagination"
                :footer-props="{'items-per-page-options':listing.rowsPerPageItems, 'items-per-page-text':'Lignes par page'}"
                :server-items-length="search.pagination.total"
                @update:page="paginate"
                @update:items-per-page="paginate"
                style="width: 810px"
            >

              <template #item.action="{ item }">

                <v-icon @click="linkCustomer(item)">add</v-icon>

              </template>

            </v-data-table>

          </v-col>
        </v-row>
      </v-card-text>
    </v-tab-item>

    <v-tab-item v-if="isAgency">
      <v-card-text class>
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-model="searchAgency.val"
                clearable
                label="Nom agence."
                append-icon="mdi-magnify"
                @keypress.enter="searchagency"
                @click:append="searchagency"
                :loading="searchAgency.loading"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-list subheader dense v-if="searchAgency.results.length > 0">
              <v-subheader
              >{{ searchAgency.results.length }} Agences
              </v-subheader
              >
              <template v-for="(item, index) in searchAgency.results">
                <v-list-item :key="'customer-' + item.id" :active="item.active">
                  <v-list-item-content>
                    <v-list-item-title
                    >{{ item.name }} - {{ item.address.postalcode }}-
                      {{ item.address.city }}
                    </v-list-item-title
                    >
                    <v-list-item-subtitle v-show="item.open">
                      <span class="text-xs grey--text">
                        ({{ item.address.street }}
                        {{ item.address.postalcode }} -
                        {{ item.address.city }} )
                      </span>
                      <div v-if="item.agents.length == 0">
                        Aucun agents dans cette agence
                      </div>
                      <div
                          class="mt-2"
                          v-else
                          v-for="(agent, agtIdx) in item.agents"
                          :key="'agents' + item.id + '-' + agtIdx"
                      >
                        • {{ agent.firstname }} {{ agent.lastname }} -
                        <v-chip
                            class="caption"
                            color="primary"
                            x-small
                            @click="linkAgency(item, agent)"
                        >Choisir
                        </v-chip
                        >
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-icon @click="searchagent(item)">{{
                        item.open ? "mdi-chevron-up" : "mdi-chevron-down"
                      }}
                    </v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider
                    v-if="index + 1 < searchAgency.results.length"
                    :key="'divider-' + index"
                ></v-divider>
              </template>
            </v-list>
          </v-col>
        </v-row>
      </v-card-text>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import {get, post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";
import CustomerAddress from "@/components/commons/CustomerAddress";
import CustomerInfos from "@/components/commons/CustomerInfos";
import FormMixin from "@/components/mixins/FormMixin";
import _ from "lodash";

export default {
  name: "BookingCustomerCreate",
  mixins: [FormMixin],
  props: {
    isAgency: {
      type: Boolean,
      default: false,
    },
    maybeCustomer: {
      type: Object,
      required: false,
    },
    bookingid: {
      type: Number,
      required: false,
    },
    saveSnapshotUrl: {
      type: String,
      required: false,
      default: "/api/booking",
    }
  },
  created() {
  },
  components: {
    CustomerInfos,
    CustomerAddress,
  },
  data() {
    return {
      tab: null,
      customerFormValid: false,
      customer: {
        form: {
          infos: {
            civility: "Mister",
            lastname: null,
            firstname: null,
            email: null,
            otherPhones: [],
            fixe: null,
            selectedCountriesForNumber: "FR",
          },
          billingAddressIsSame: true,
          address: {
            city: null,
            country: null,
            id: null,
            postalCode: null,
            street: null,
            streetcplmt: null,
          },
          billingAddress: {},
          isAgency: false,
        },
        civilities: [
          {value: "Mister", text: "M."},
          {value: "Madam", text: "Mme"},
          {value: "Miss", text: "Mlle"},
        ],
        formloading: false,
      },
      noDataSentence: "Veuillez effectuer une recherche",
      headers: [
        {sortable: true, text: "Nom", align: "left", value: "lastname"},
        {sortable: false, text: "Prénom", align: "left", value: "firstname"},
        {sortable: false, text: "Email", align: "left", value: "email"},
        {sortable: false, text: "Devis", align: "left", value: "quotations"},
        {sortable: false, text: "Dossiers", align: "left", value: "bookings"},

        {sortable: false, text: "Action", value: "action"}
      ],
      listing: {
        loading: false,
        rowsPerPageItems: [10, 50, 200],
      },
      search: {
        loading: false,
        val: null,
        results: [],
        pagination: {}
      },
      searchAgency: {
        loading: false,
        val: null,
        results: [],
      },
      searchCities: null,
      searchCountries: null,
      snapshot: {
        saveCustomer: false,
        bookingId: null,
      },
      errorUpdateClient: null,
    };
  },
  watch: {
    maybeCustomer: {
      immediate: true,
      handler(newVal) {
        if (newVal && newVal.id) {

          const isAgency = newVal.hasOwnProperty('agent')

          const lastname = isAgency && newVal.agent ? newVal.agent.lastname : newVal.lastname
          const firstname = isAgency && newVal.agent ? newVal.agent.firstname : newVal.firstname
          const name = isAgency && newVal.name ? newVal.name : null

          const infos = {
            id: newVal.id,
            civility: newVal.civility,
            name: name,
            lastname: lastname,
            firstname: firstname,
            email: newVal.email,
            fixe: newVal.phone,
            otherPhones:
                newVal.otherPhones && newVal.otherPhones.filter((p) => p != "").length > 0
                    ? newVal.otherPhones
                    : [],
            selectedCountriesForNumber: newVal.address.country.substring(0, 2),
          };

          const address = {
            city: newVal.address.city,
            country: newVal.address.country,
            id: newVal.address.id,
            postalCode: newVal.address.postalcode,
            street: newVal.address.street,
            streetcplmt: newVal.address.streetComplement,
          };
          this.customer.form = {
            infos: infos,
            address: address,
            billingAddress: newVal.billingAddress,
            billingAddressIsSame: newVal.sameBillingAddress || true,
            isAgency: isAgency,
          };
        }
      },
    },
    bookingid: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          if (this.customer.form.infos.id) {
            this.snapshot = {
              saveCustomer: this.customer.form.infos.id,
              bookingId: newVal,
            };
          } else {
            this.snapshot = null;
          }
        }
      },
    },
    "searchAgency.val": _.debounce(function (newVal) {
      if (newVal && typeof newVal !== "object") {
        this.searchagency(newVal);
      }
    }, 300),
  },
  methods: {
    toClient(idClient) {
      const url = `/customer/${idClient}`
      window.open(url, "_blank");
    },
    save() {
      const valid = this.$refs.customerform.validate();
      if (this.customerFormValid && valid) {
        let promises = [];
        this.customer.formloading = true;
        const form = {
          ...this.customer.form.infos,
          ...{billingAddressIsSame: this.customer.form.billingAddressIsSame},
          ...{address: this.customer.form.address},
          ...{
            billingAddress: this.customer.form.billingAddressIsSame
                ? null
                : this.customer.form.billingAddress,
          },
        };

        if (!this.customer.form.isAgency) {
          if (this.snapshot && this.snapshot.bookingId) {
            promises.push(this.saveSnapshot(form));
            this.errorUpdateClient = null
            if (this.snapshot.saveCustomer) {
              promises.push(this.editcustomer(form));
            }
          } else {
            this.savecustomer(form);
          }
        } else {
          //on autorise pas l'édition/création de l'agence depuis cet interface
          //uniquement la maj du snapshot pour le dossier
          promises.push(this.saveSnapshotAgency(form))
        }

        if (promises.length > 0) {
          Promise.all(promises).then(() => {
            EventBus.$emit("reloadbooking", {});
            if (this.errorUpdateClient == null) {
              this.$emit("done");
              console.log(this.errorUpdateClient)
            } else {
              this.customer.formloading = false;
            }
          });
        }
      }
    },
    saveSnapshot(form) {
      const url = this.saveSnapshotUrl + `/${this.bookingid}/customer/update`;
      return post(url, form)
          .badRequest((err) => {
            EventBus.$emit(
                "toaster-msg",
                JSON.parse(err.message).errors.join(", ")
            );
            this.customer.formloading = false;
          })
          .res((r) => {
            return r;
          })
          .then((data) => {
            return;
          });
    },
    savecustomer(form) {
      const url = `/api/customer/create`;

      return post(url, form)
          .badRequest((err) => {
            EventBus.$emit(
                "toaster-msg",
                JSON.parse(err.message).errors.join(", ")
            );
            this.customer.formloading = false;
          })
          .json((r) => {
            return r;
          })
          .then((data) => {
            this.customer.formloading = false;

            if (data && data.customer) {
              this.linkCustomer(data.customer);
              EventBus.$emit("reloadbooking", {});
              this.$emit("done");
            }
          });
    },
    editcustomer(form) {

      const url = `/api/customer/update`;

      return post(url, form)
          .badRequest((err) => {
            const error = JSON.parse(err.message)
            if (error.clientId != null) {
              EventBus.$emit(
                  "toaster-error",
                  "un client déja existant a deja cet email"
              );
              this.errorUpdateClient = error.clientId
            } else {
              EventBus.$emit(
                  "toaster-msg",
                  JSON.parse(err.message).errors.join(", ")
              );
            }
          })
          .json((r) => {
            return r;
          });
    },
    saveSnapshotAgency(form) {
      const url = `/api/booking/${this.bookingid}/agency/update`;
      return post(url, form)
          .badRequest((err) => {
            EventBus.$emit(
                "toaster-msg",
                JSON.parse(err.message).errors.join(", ")
            );
            this.customer.formloading = false;
          })
          .res((r) => {
            return r;
          })
    },
    linkCustomer(customer) {
      this.$emit("linkCustomer", customer);
    },
    linkAgency(agency, agent) {
      this.$emit("linkAgency", {
        agency: agency,
        agent: agent,
      });
    },
    paginate(page) {
      this.searchcustomer({
        offset: this.listing.pagination.itemsPerPage * (page - 1),
        limit: this.listing.pagination.itemsPerPage
      })
    },
    searchcustomer(pagination) {
      const params = {...{search: this.search.val, limit: this.listing.pagination.itemsPerPage}, ...pagination}

      const qs = Object.keys(params).map(key => {
        return `${key}=${params[key]}`
      }).join("&")

      this.search.loading = true;

      const url = "/api/customer/search";

      get(url + "?" + qs).json((r) => {
        this.search.results = r.customers;
        this.search.pagination = r.pagination;
        this.search.loading = false;
      });
    },
    searchagency(item) {
      const search = typeof item == "string" ? item.trim() : item;
      const url = `/api/agency/search?search=${search}`;

      this.searchAgency.loading = true;
      get(url)
          .json((r) => {
            this.searchAgency.results = r.map((d) => {
              return {...d, ...{agents: []}, ...{open: false}};
            });
            this.searchAgency.loading = false;
          })
          .then(() => {
            this.editLoading = false;
            this.editButtonLoading = false;
          });
    },
    searchagent(item) {
      if (item.agents != null && item.agents.length > 0) {
        item.open = !item.open;
      } else {
        const url = `/api/agency/${item.id}/agents`;
        this.searchAgency.loading = true;
        get(url)
            .json((r) => {
              item.agents = r;
              this.searchAgency.loading = false;
            })
            .then(() => {
              this.editLoading = false;
              this.editButtonLoading = false;
              item.open = !item.open;
            });
      }
    },
  },
};
</script>

<style scoped>
.card-customer-dialog {
  min-height: 680px;
}

.alert {
  margin-top: 10px;
  margin-left: 40px;
  margin-right: 40px;
  padding: 8px;
  border-radius: 12px;
  background-color: #FF5252;
  color: white;
  border: 2px solid #D50000;
}

.underline{
  text-decoration: underline;
}

.btn {
  cursor: pointer;

}
</style>
