<template>
  <div class=" d-flex flex-row space-justify-between mb-4">
    <div  v-for="(fee, index) in localFee" :key="index" >

      <div class="d-flex flex-column mr-3" :style="secondElement(index)">
        <div class="float-right">
        <v-icon class="float-right" @click="removePeriod(fee, index)">mdi-close-circle-outline</v-icon>
          </div>
        <div class="d-flex align-baseline">
          <span class="caption font-weight-medium mr-2">{{ $t('from') }}</span>

          <div :class="fee.invalid == 'start' ? 'red lighten-4' :''">
            <smart-picker v-model="fee.start" label="Date de début" :max="fee.stop"
                          @input="change(fee, index)"></smart-picker>
          </div>
        </div>
        <br/>
        <div class="d-flex align-baseline">
          <span class="caption font-weight-medium mr-2">{{ $t('to') }}</span>
          <div :class="fee.invalid == 'stop' ? 'red lighten-4' :''">
            <smart-picker v-model="fee.stop" label="Date de fin" :min="fee.start"
                          @input="change(fee, index)"></smart-picker>
          </div>
        </div>
        <br/>
        <div :class="fee.invalid == 'price' ? 'red lighten-4' :''" style="width:75%">
          <v-text-field class="ml-6" append-icon="euro_symbol" v-model.number="fee.price" :label="$t('price')"
                        @change="change(fee, index)"></v-text-field>
        </div>
      </div>


    </div>
  </div>
</template>
<script>

export default {
  name: "CancelFeeEdit",
  props: {
    prestationId:{type:Number,required:true},
    fees: {type: Array, required: true},
    divider: {type: Boolean, default: true},
    startPrestaDate: {type: String, required: true},
  },
  data() {
    return {
      localFee: []
    }
  },
  watch: {
    fees: {
      handler(newVal) {
        this.localFee = JSON.parse(JSON.stringify(newVal))
      },
      deep: true,
      immediate: true,
    }
  },
  computed:{

  },
  methods: {
    secondElement(index){
      return index == this.localFee.length-1  ? '' : "border-right:1px solid lightgrey; padding-right:5px;"
    },
    change(fee, index) {
      this.fees[index] = fee
      this.$emit('change', {id:this.prestationId, fees:this.fees})
    },
    removePeriod(fee, index) {
      this.$emit('remove', {fee:this.fees,index:index},)
    },
  },
}
</script>