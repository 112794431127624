<template>
  <smart-search
      :is-visible="showResults"
      :loading="loading"
      v-model="searchString"
  >
    <v-tabs light v-if="result" v-model="tabModel" class="resultsTabs">
      <v-tab
          class=""
          :disabled="result[key].length===0"
          :key="key"
          v-for="(key) in Object.keys(result)"
      >
        {{ key == "bookings" ? "Dossiers" : "Lead" }} ({{ result[key].length }})
      </v-tab>

      <v-tab-item key="bookings">
        <v-row v-if="result.bookings?.length===0??false" class=" grey--text text--darken-1 px-2 py-2">
          <v-col cols="12" class="">
            Aucun resultats
          </v-col>
        </v-row>
        <v-row
            dense
            @click.stop="browse(booking)"
            class="searchresults align-center py-1 px-2"
            v-bind:style="{ cursor: 'pointer' }"
            v-for="booking in result.bookings"
            :key="booking.id"
        >
          <v-col cols="1">
            <span class="caption grey--text text--darken-1">{{ booking.bookingId }}</span>
            <site-label :sitename="booking.siteName"/>
          </v-col>

          <v-col class="text-left" cols="2">
            <div
                class="font-weight-regular"
            >Le {{ _parseAndFormatDate(booking.bookingDate, datePatternConfig.serverLocalDateTime, "dd/MM/yyyy") }}
            </div>
            <div
                class="caption grey--text text--darken-1"
            >à {{ _parseAndFormatDate(booking.bookingDate, datePatternConfig.serverLocalDateTime, "HH:mm") }}
            </div>
          </v-col>

          <v-col class="text-left" cols="2">
            <div
                class="font-weight-regular"
            >Du {{ _parseAndFormatDate(booking.startDate, datePatternConfig.serverLocalDateTime, "dd/MM/yyyy") }}
            </div>

            <div class="caption grey--text text--darken-1">
              Au {{ _parseAndFormatDate(booking.stopDate, datePatternConfig.serverLocalDateTime, "HH:mm") }} •
              {{ booking.duration }}n
            </div>
          </v-col>

          <v-col class="text-left" cols="2">
            <div
                class="font-weight-regular"
                v-if="booking.customer && booking.customer != ''"
            >{{ booking.customer }}
            </div>
            <span
                :key="i + '-guest'"
                class="caption grey--text text--darken-1"
                v-for="(guest, i) in booking.guests"
            >{{ $tc(guest.ageClass + '-count-small', guest.size, {size: guest.size}) }}</span>
          </v-col>

          <v-col
              class="text-left font-weight-regular"
              cols="2"
          >{{ booking.hotelWithDesti ? booking.hotelWithDesti.map(x => x[0]).join(', ') : '' }}
          </v-col>

          <v-col class="text-right body-2 font-weight-medium" cols="1">
            <div class="black--text">{{ booking.ca | currency('EUR') }}</div>
            <div class="body-2 grey--text">
              {{ booking.purchase | currency('EUR') }}
              <!--<span
                class="caption ml-1 grey--text text--lighten-1"
              >{{ booking.markup | percent }}</span>-->
            </div>
          </v-col>

          <v-col class="text-right" cols="2">
            <v-row class="pl-2">
              <availability
                  kind="BookingStatus"
                  v-if="booking.bookingStatus"
                  v-model="booking.bookingStatus"
              />
              <availability
                  kind="SupplierStatus"
                  v-if="booking.supplierStatus"
                  v-model="booking.supplierStatus"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-tab-item>

      <v-tab-item key="lead">
        <v-row v-if="result.leads?.length===0??false" class=" grey--text text--darken-1 px-2 py-2">
          <v-col cols="12" class="">
            Aucun resultats
          </v-col>
        </v-row>
        <v-row
            dense
            @click.stop="browse(lead)"
            class="searchresults align-center py-1 px-2"
            v-bind:style="{ cursor: 'pointer' }"
            v-for="lead in result.leads"
            :key="lead.id"
        >
          <v-col cols="1">
            <span class="caption grey--text text--darken-1">#{{ lead.id }}</span>
            <site-label :sitename="lead.virtualSite"/>
          </v-col>

          <v-col class="text-left" cols="1">
            <div class="d-flex flex-column">
              <score-lead :score="lead.score"/>
            </div>
          </v-col>

          <v-col class="text-left" cols="2">
            <div
                class="font-weight-regular"
            >{{ _parseAndFormatDate(lead.creationDate, datePatternConfig.serverLocalDateTime, "dd/MM/yyyy") }}
            </div>
            <div
                class="caption grey--text text--darken-1"
            >à {{ _parseAndFormatDate(lead.creationDate, datePatternConfig.serverLocalDateTime, "HH:mm") }}
            </div>
          </v-col>


          <v-col class="text-left" cols="2">
            <div
                class="font-weight-regular"
                v-if="lead.customer?.name && lead.customer.name != ''"
            >{{ lead.customer.name }}
            </div>
          </v-col>

          <v-col
              class="text-left font-weight-regular"
              cols="2"
          ><span v-html="lead.destinations.join(',<br/>')"></span>
          </v-col>

          <v-col class="text-left font-weight-regular" cols="1">
            {{ lead.quotes }} devis
          </v-col>


          <v-col class="text-right font-weight-regular" cols="3">
            <div class="d-flex justify-end">
              <v-chip small label>{{ $t('lead.source.' + lead.source) }}</v-chip>
              <v-chip class="ml-1" text-color="white" small label
                      :color="colorTag(lead)">{{
                  $t('lead.status.' + lead.status)
                }}
              </v-chip>
            </div>
          </v-col>
        </v-row>
      </v-tab-item>


    </v-tabs>
  </smart-search>
</template>

<script>
import {get} from "@/utils/api";
import SiteLabel from "./SiteLabel";
import Availability from "./Availability";
import SmartSearch from "./SmartSearch";
import {EventBus} from "@/components/commons/event-bus";
import ScoreLead from "@/components/booking/ScoreLead.vue";

export default {
  name: "SmartBookingFinder",
  components: {ScoreLead, SmartSearch, Availability, SiteLabel},
  mixins: [],
  data() {
    return {
      searchString: "",
      loading: false,
      v1: "booked",
      v2: "confirmed",
      tabModel: 0,
      result: null,
      showResults: false,
      requestsQueue: [],
      bookingdrawer: false
    };
  },
  created() {
    let self = this;

    window.addEventListener("click", function (e) {

      if (!self.$el.contains(e.target)) {
        self.showResults = false;
      } else if (self.result && (/*self.result.bookingProjects.length > 0 || */self.result.bookings.length > 0)) {
        self.showResults = true;
      }
    });
  },
  watch: {
    searchString: function (newVal) {


      return this.getBookingsAndProjects(newVal);
    },
  },
  methods: {
    getBookingsAndProjects(search) {
      const self = this;

      this.loading = true;
      const url = `/api/widget/multiSearchBookings?search=${search}`;

      const [controller, wretchInst] = get(url)
      .onAbort((_) => {
        self.requestsQueue.splice(0, self.requestsQueue.length - 1);
      })
      .controller();

      wretchInst
      .json((res) => {
        if (this.isCommercial || this.isCommercialAdmin) {
          self.result = {
            bookings: res.bookings.filter(b => b.siteName == 'HLagons'),
            //leads:[]
          }
        } else {

          self.result = {
            bookings: res.bookings,
            leads: res.leads
          }
        }
      })
      .then(() => {
        const tabs = document.querySelectorAll('#searchResultsContainer .v-tabs-items')
        if (tabs[0]) {
          tabs[0].scrollTop = 0
        }

        self.loading = false;
        self.showResults = true;

      });

      self.requestsQueue.push({c: controller, w: wretchInst});

      if (self.requestsQueue.length > 1) {
        self.requestsQueue.map((item, idx) => {
          if (idx < self.requestsQueue.length - 1) {
            item.c.abort();
          }
        });
      }
    },
    browse(obj) {
      if (this.isCommercial || this.isCommercialAdmin) {
        EventBus.$emit('browse', obj)
      } else {
        if (obj.bookingId) {
          this.$router.push({name: "Booking", params: {id: obj.bookingId}});
        } else if (obj.id) {
          this.$router.push({name: "lead", params: {id: obj.id}});
        }
      }
      this.showResults = false;
      //this.result = null;

    },
    colorTag(item) {
      return item.status == 'open' || (item.status == 'close' && item.converted)
          ? 'green'
          :
          item.status == 'close'
              ? 'red'
              : 'orange'
    },
  },
};
</script>

<style scoped>
.searchresults {
  font-size: 13px;
}

.searchresults:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.v-autocomplete__content .v-select-list {
  height: auto;
}

.v-tabs {
  width: 936px;
}

.font-weight-regular {
  color: #1a1a1a;
}

>>> .v-window {
  overflow: scroll;
  max-height: 350px;
}

résultsTabs {
}
</style>
