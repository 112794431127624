<template>
  <div>
    <v-navigation-drawer
        v-model="drawer"
        fixed
        hide-overlay
        class="app-menu elevation-5"
        style="z-index: 5"
        :mini-variant-width="80"
        :mini-variant="!hover"
        :clipped="clipped"
        :permanent="_isDesktop()"
        @mouseover.native="openDrawer"
        @mouseleave.native="closeDrawer"
    >
      <v-list class="body-2">
        <template v-for="(item, itemIndex) in itemsByRoles">
          <v-list-item
              :key="itemIndex"
              v-if="!item.children || item.children.length === 0"
              :to="item.route"
              :exact="item.route.exact"
          >
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
              :key="itemIndex"
              v-else
              :prepend-icon="item.icon"
              v-model="item.model"
          >
            <template v-slot:activator>
              <v-list-item @click="routeTo(item)" :exact="item.route.exact">
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item
                v-for="(child, i) in item.children"
                :key="i"
                :to="child.route"
                :exact="child.route.exact"
                class="pl-12"
            >
              <v-list-item-action>
                <span v-if="child.text == 'Visites' && countEvent > 0"
                      class="caption red"
                      :class="countEvent>9 ? 'countEventMore' : 'countEventLess'"
                      :style="hover ? 'left: 33px !important' : ''"
                >
                  {{ countEvent }}
                </span>
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
        app
        dark
        dense
        fixed
        :color="color"
        :clipped-left="clipped"
        style="z-index: 6"
    >
      <v-app-bar-nav-icon
          v-show="_isDesktop"
          @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title
          v-text="title"
          class="d-none d-sm-flex"
      ></v-toolbar-title>
      <v-spacer></v-spacer>

      <v-col
          cols="6"
          v-if="isAdmin || isExternalLanguageAdmin || isCommercial || isCommercialAdmin"
          class="d-none d-sm-flex"
      >
        <smart-booking-finder v-if="clicked"></smart-booking-finder>
      </v-col>

      <v-col
          cols="6"
          v-if="isAdmin || isExternalLanguageAdmin "
          class="d-flex d-sm-none"
      >
        <v-row class="justify-end mr-2">
          <v-icon
              class="d-flex d-sm-none"
              :color="colorLabel"
              v-if="!clicked"
              @click="openSearch()"
          >search
          </v-icon
          >
          <smart-booking-finder
              v-if="clicked"
              v-click-outside="closeSearch"
          ></smart-booking-finder>
        </v-row>
      </v-col>
      <v-spacer></v-spacer>

      <!-- user's todo task -->
      <v-badge v-if="isAdmin" color="red" right overlap :to="{}">
        <span slot="badge" small v-if="tasksSize > 0">{{ tasksSize }}</span>
        <v-btn icon text :to="{ name: 'TasksList' }" active-class="empty">
          <v-icon>mdi-format-list-checks</v-icon>
        </v-btn>
      </v-badge>

      <!-- user menu -->
      <v-menu bottom left>
        <template v-slot:activator="{ on }">
          <v-btn text class="white--text" v-on="on">
            <span class="d-none d-sm-flex">{{ username }}</span>
            <v-icon>mdi-account-box</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="logout">
            <v-list-item-title>Deconnexion</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <smart-dialog
        v-model="bookingDialog.dialog"
        :title="'Dossier ' + bookingDialog.bookingId"
    >

      <booking-drawer-content
          :showprices="false"
          :booking="bookingDialog.selectedBooking"
          btnToHL
      ></booking-drawer-content>
    </smart-dialog>
  </div>
</template>

<script>
import {get} from "@/utils/api";
import SmartBookingFinder from "./SmartBookingFinder";
import {EventBus} from "./event-bus";
import BookingDrawerContent from "@/components/commons/BookingDrawerContent.vue";
import {Role} from "@/utils/user";

export default {
  name: "AppMenu",
  components: {BookingDrawerContent, SmartBookingFinder},
  data() {
    return {
      colorLabel: "#fff",
      tasksSize: 0,
      //
      clicked: window.innerWidth < 600 ? false : true,
      clipped: true,
      drawer: this._isDesktop(),
      fixed: true,
      miniVariant: true,
      hover: false,
      title: "SmartRésa",
      searchModel: null,
      color:
          process.env.NODE_ENV == "production"
              ? "light-blue darken-3"
              : "orange accent-2",
      countEvent: null,
      bookingDialog: {
        bookingId: null,
        dialog: false,
        selectedBooking: null,
      },

    };
  },
  watch: {
    $route() {
      this.loadTaskSize();
    },
  },
  created() {
    this.getCountEvent()
    EventBus.$on("updateVisite", () => {
      this.getCountEvent();
    });
    EventBus.$on('browse', (booking) => {
      const url = `/api/booking/${booking.bookingId}`;
      get(url)
          .json((r) => {
            this.bookingDialog.bookingId = booking.bookingId;
            this.bookingDialog.selectedBooking = r.data;
            this.bookingDialog.selectedBooking.prestations = this.bookingDialog.selectedBooking.prestations.filter(p => p.status == 'booked' && p.supplierStatus == 'confirmed');
          })
          .then(() => {
            this.bookingDialog.dialog = true;
          });
    })
  },
  destroyed() {
    EventBus.$off("updateVisite")
  },
  computed: {
    menu() {
      return [
        {
          icon: "mdi-view-dashboard",
          text: "Accueil",
          route: {name: "Home", exact: true}
        },
        {
          model: false,
          icon: "mdi-folder",
          text: "Vente",
          route: {name: this.defaultViewPerRole()},

          children: [
            {
              icon: "mdi-folder",
              text: "Dossiers",
              route: {name: "BookingList"},
            },
            {
              icon: "mdi-folder-clock",
              text: "Options",
              route: {name: "Options"},
            },
            {
              icon: "mdi-folder-cancel",
              text: "Dossiers annulés",
              route: {name: "CancelledBookings"},
            },
            {
              icon: "mdi-calendar-search",
              text: "Calendrier de prix",
              route: {name: "CalendarSearch", exact: true},
            },
            {
              icon: "mdi-card-account-mail",
              text: "Fournisseurs",
              route: {name: "SupplierPrestationEdit", exact: true},
            },
            {
              icon: "mdi-message-draw",
              text: "Avis",
              route: {name: "avis-moderation", exact: true},
            },
            {
              icon: "mdi-folder",
              text: "Dossiers Réceptif",
              route: {name: "BookingListReceptif"},
            },
            {
              icon: "mdi-folder",
              text: "Arrivées / Départs",
              route: {name: "ArrivalDeparture", exact: true},
            },
            {
              icon: "mdi-layers",
              text: "Leads",
              route: {name: "listleads", exact: true},
            },
          ],
        },
        {
          model: false,
          icon: "mdi-account-group",
          text: "Clients",
          route: {name: "customer-search", exact: true},
        },
        {
          model: false,
          icon: "mdi-finance",
          text: "Stats",
          route: {name: "BookingStats", exact: true},
          children: [
            {
              icon: "mdi-gauge",
              text: "Dossiers",
              route: {name: "BookingStats"},
            },
            {
              icon: "mdi-home-analytics",
              text: "Resas",
              route: {name: "ResasStats"},
            },
            {
              icon: "mdi-home-analytics",
              text: "Hotels",
              route: {name: "HotelStats"},
            },
            {
              icon: "mdi-chart-areaspline-variant",
              text: "Activité",
              route: {name: "ActivityStats"},
            },
            {
              icon: "mdi-airplane",
              text: "Vol",
              route: {name: "FlightStats"},
            },
            {
              icon: "mdi-lan-connect",
              text: "Réseaux",
              route: {name: "NetworkStats"},
            },
            {
              icon: "mdi-home",
              text: "Agences",
              route: {name: "AgenciesStats"},
            },
            {
              icon: "mdi-cash",
              text: "Trésorerie",
              route: {name: "PurchaseStats"},
            },
            {
              icon: "mdi-bus",
              text: "Transfert",
              route: {name: "TransferStats"},
            },
            {
              icon: "mdi-card-account-mail",
              text: "Fournisseurs",
              route: {name: "SupplierAccountingStats"},
            },
            {
              icon: "mdi-book-multiple",
              text: "Réservations / Départs ",
              route: {name: "TravelBookingStats"},
            },
            {
              icon: "insights",
              text: "Activité Agc / segment ",
              route: {name: "AgencyActivitySegment"},
            },
            {
              icon: "insights",
              text: "Roi",
              route: {name: "RoiStats"},
            },
            {
              icon: "mdi-format-list-checks",
              text: "Répartition régions",
              route: {name: "HlStats", exact: true},
            },
          ],
        },
        {
          model: false,
          icon: "mdi-alpha-h-box",
          text: "Hotels & Lagons",
          route: {name: "HLagons"},

          children: [
            {
              icon: "dashboard",
              text: "Tableau de bord",
              route: {name: "HLagons", exact: true},
            },
            {
              icon: "mdi-folder",
              text: "Dossiers",
              route: {name: "CommercialListingBookingHL"},
            },
            {
              icon: "mdi-format-list-checks",
              text: "Recap des incentives",
              route: {name: "IncentiveRecap", exact: true},
            },
            {
              icon: "mdi-format-list-checkbox",
              text: "Chèques-cadeaux",
              route: {name: "GiftCards", exact: true},
            },
            {
              icon: "mdi-id-card",
              text: "CRM",
              route: {name: "Crm", exact: true},
            },
            {
              icon: "mdi-chart-multiline",
              text: "Activités agent connectés",
              route: {name: "AgentActivity", exact: true},
            },
            {
              icon: "tour",
              text: "Visites",
              route: {name: "CommercialLog", exact: true},
            },
            {
              icon: "mdi-call-merge",
              text: "Fusion Agent",
              route: {name: "Deduplicates", exact: true},
            },
            {
              icon: "data_exploration",
              text: "Suivi Agence",
              route: {name: "Suivi Agence", exact: true},
            },
            {
              icon: "mdi-web",
              text: "Réseaux",
              route: {name: "networks", exact: true},
            },
            {
              icon: "mdi-view-list",
              text: "Conditions de Réglement",
              route: {name: "PaymentTerms", exact: true},
            },
          ],
        },
        {
          model: false,
          icon: "mdi-format-list-checks",
          text: "Actions",
          route: {name: "TasksList"},
          children: [
            {
              icon: "mdi-format-list-checkbox",
              text: "Actions à traiter",
              route: {name: "TasksList", exact: true},
            },
            {
              icon: "mdi-calendar-export",
              text: "Emissions",
              route: {name: "ticketting-list", exact: true},
            },
            {
              icon: "mdi-exit-run",
              text: "Pax sur place",
              route: {name: "pax-on-place", exact: true},
            },
            {
              icon: "mdi-tag-multiple",
              text: "Etiquettes",
              route: {name: "tags-list", exact: true},
            },
            {
              icon: "mdi-file-lock",
              text: "Dossiers vérouillés",
              route: {name: "booking-locked", exact: true},
            },
          ],
        },
        {
          model: false,
          icon: "mdi-calculator-variant",
          text: "Comptabilité",
          route: {name: "supplier-invoice-list", exact: true},
          children: [
            {
              icon: "mdi-file-document",
              text: "Factures Fournisseurs",
              route: {name: "supplier-invoice-list", exact: true},
            },
            {
              icon: "mdi-folder",
              text: "Listing Prestations",
              route: {name: "prestations-list", exact: true},
            },
            {
              icon: "mdi-card-account-mail",
              text: "Fournisseurs",
              route: {name: "SupplierAccountingEdit", exact: true},
            },
            {
              icon: "mdi-file-export",
              text: "Exports",
              route: {name: "export-ventes", exact: true},
            },
            {
              icon: "mdi-file-check",
              text: "Rapport Paiement Prestation",
              route: {name: "prestation-invoice-report", exact: true},
            },
            {
              icon: "mdi-file-check",
              text: "TVA",
              route: {name: "tva", exact: true},
              allowedRoles: [this.roles.admin],
            },
            {
              icon: "mdi-currency-eur-off",
              text: "Dossier impayés",
              route: {name: "unpaid-bookings", exact: true},
            },
            {
              icon: "mdi-currency-usd",
              text: "Devises",
              route: {name: "currencies-payments", exact: true},
            },
            {
              icon: "mdi-credit-card-settings-outline",
              text: "Centrale de Paiement",
              route: {name: "central-payments", exact: true},
            },
            {
              icon: "mdi-credit-card-fast-outline",
              text: "Pledg",
              route: {name: "pledg", exact: true},
            },
            {
              icon: "mdi-credit-card-settings-outline",
              text: "Conditions de Réglement",
              route: {name: "payment-term", exact: true},
            },
              {
              icon: "mdi-wallet-bifold",
              text: "Portefeuilles",
              route: {name: "WalletList", exact: true},
            },
          ],
        },
        {
          model: false,
          icon: "mdi-package",
          text: "Packages",
          route: {name: "Packages", exact: false},
        },
        {
          model: false,
          icon: "mdi-wrench",
          text: "Admin",
          route: {name: "AdminUsers", exact: false},
          children: [
            {
              icon: "mdi-account-supervisor",
              text: "Utilisateurs",
              route: {name: "AdminUsers"},
            },
            {
              icon: "mdi-account-supervisor",
              text: "BigQuery",
              route: {name: "BigQueryAnalytics"},
            },
            {
              icon: "mdi-phone-log",
              text: "Appels",
              route: {name: "PhoneCalls"},
            },
          ],
        },
      ]
    },

    itemsByRoles() {
      const routes = this.$router.getRoutes()

      const reduceMenu = (list) => {
        return list.reduce((prev, item) => {

          const children = item.children ? reduceMenu(item.children) : []

          const route = routes.find((route) => route.name === item.route.name)
          if (this.userRolesIds.includes(Role.admin) || (route && route.meta && route.meta.roles && route.meta.roles.some(role => this.userRolesIds.includes(role))) || children.length > 0) {
            return [...prev, {...item, children: children}]
          }
          return prev

        }, [])
      };

      return reduceMenu(this.menu);
    },
  },
  methods: {
    loadTaskSize() {
      setTimeout(() => {
        get("/api/task/count/foruser").json((d) => {
          this.tasksSize = d;
        });
      }, 1500);
    },
    openDrawer() {
      this.hover = true;
    },
    closeDrawer() {
      this.menu.map((i) => {
        i.model = false;
      });
      this.hover = false;
    },
    openSearch() {
      this.clicked = true;
    },
    closeSearch() {
      this.clicked = window.innerWidth < 600 ? false : true;
    },
    routeTo(item) {
      const firstChildRoute =
          item.children.length > 0 && item.children[0].route;

      const goTo = item.route == firstChildRoute ? item.route : firstChildRoute;
      this.$router.push(goTo);
    },
    async getCountEvent() {
      await get(`/api/agency/crm/event/count/${this.currentUser.id}`)
          .badRequest(() => {
            EventBus.$emit('toaster-error', "erreur")
          })
          .json((data) => {
            this.countEvent = data[0]
          })
    }
  },
};
</script>

<style scoped>
.v-toolbar {
  z-index: 4;
}

.v-list-item__title {
  font-size: 14px;
  font-weight: 500 !important;
}

.search >>> .v-input__control {
  min-height: auto !important;
}

.v-navigation-drawer.app-menu {
  margin-top: 48px !important;
  -webkit-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
  0 5px 8px 0 rgba(0, 0, 0, 0.14), 0 1px 14px 0 rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 5px 8px 0 rgba(0, 0, 0, 0.14),
  0 1px 14px 0 rgba(0, 0, 0, 0.12) !important;
}

>>> .v-badge__badge {
  width: 17px;
  height: 17px;
}

>>> .v-badge__badge span {
  font-size: 12px;
}

>>> .v-badge__badge {
  top: 0 !important;
  right: 0 !important;
}

>>> .v-navigation-drawer--mini-variant .v-list__group__header__prepend-icon {
  margin-left: 0 !important;
}

.v-list .v-list-item--active {
  color: #1976d2;
}

>>> .v-list-item__action,
>>> .v-list-item__avatar,
>>> .v-list-item__icon {
  margin-right: 10px !important;
}

>>> .v-list-group__header a {
  padding: 0;
}

>>> .v-list-group__header a:before {
  opacity: 0;
}

>>> .v-list-group__header a:hover:before {
  opacity: 0;
}

>>> .v-list__group__header__prepend-icon {
  margin-left: 12px;
}

.countEventMore {
  padding: 0 3px;
  position: absolute;
  top: 3px;
  left: 25px;
  color: white !important;
  border-radius: 9999px;
}

.countEventLess {
  padding: 0 7px;
  position: absolute;
  top: 3px;
  left: 25px;
  color: white !important;
  border-radius: 50%;
}
</style>
