<template>
  <layout :drawerShow="true" :drawerWidth="350">
    <template slot="nav">
      <v-tabs>
        <v-tab>Appels</v-tab>
      </v-tabs>
    </template>
    <template slot="nav-tools">

    </template>
    <template slot="drawer">
      <div>
        <v-form ref="searchform" v-if="searchform" v-on:submit.prevent="valid">
          <v-row dense>
            <v-col cols="12">
              <v-subheader light class="pa-0 ml-1">Dates d'appel</v-subheader>
            </v-col>
            <v-col class="pr-1" cols="6">
              <smart-picker
                  v-model="searchform.startDate"
                  :stopDate="searchform.stopDate"
                  :inputOpts="{ label: $t('begin') }"
              />
            </v-col>
            <v-col cols="6">
              <smart-picker
                  v-model="searchform.stopDate"
                  :startDate="searchform.startDate"
                  :inputOpts="{ label: $t('end') }"
              />
            </v-col>
            <v-col cols="12" class="mt-3">
              <v-select v-if="users"
                        v-model="searchform.userId"
                        label="Utilisateurs"
                        item-text="name"
                        item-value="id"
                        :items="users"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="searchform.phoneNumber"
                  label="Numéro de Tel."
                  type="number"
              />
            </v-col>
          </v-row>
          <smart-btn type="submit" block primary class="mt-2" :loading="loading"
          >Rechercher
          </smart-btn
          >
          <smart-btn
              block
              tertiary
              @click.native="
            clearSearchform();
          "
          >Réinitialiser la recherche
          </smart-btn
          >
        </v-form>
      </div>
    </template>
    <template slot="content">
      <v-container fluid class="m-4 ">
        <v-data-table
            style="word-break: break-word;"
            v-model="datatable.selectedCalls"
            item-key="id"
            show-expand
            :expanded.sync="datatable.expanded"
            dense
            :loading="loading"
            :headers="datatable.headers"
            :items="datatable.listCalls"
            :items-per-page="-1"
            no-data-text="Il n'y a pas d'appel disponible"
            class="elevation-1 tbl pr-1"
            hide-default-footer
        >
          <template #item.data-table-expand="{ item, internalItem, expand, isExpanded }">
            <td class="text-start">
              <div class="d-flex">
                <div class="d-flex">
                  <v-menu v-bind="menuOpts" rounded>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
                    </template>

                    <v-list dense>
                      <v-list-item :disabled="item.transcription != null" class="body-2"
                                   @click="regenTranscription(item.id)">
                        <v-icon small class="mr-2">mdi-script-text-play-outline</v-icon>
                        Générer transcription
                      </v-list-item>
                      <v-list-item class="body-2" :disabled="!item.leadId" @click="toLead(item.leadId)">
                        <v-icon small class="mr-2">mdi-open-in-new</v-icon>
                        Voir le lead
                      </v-list-item>
                      <v-list-item class="body-2" :disabled="item.leadId != null"
                                   @click="createLead(item)">
                        <v-icon small class="mr-2">mdi-layers</v-icon>
                        Créer un lead
                      </v-list-item>
                      <v-list-item class="body-2" @click="openDialogCategory(item)">
                        <v-icon small class="mr-2">mdi-update</v-icon>
                        Changer de catégorie
                      </v-list-item>

                    </v-list>
                  </v-menu>
                </div>
                <div v-if="item.FILES.length > 0">
                  <v-btn fab small
                         raised elevation="0"
                         style="background-color: white"
                         @click="expand(!isExpanded), getTranscription(isExpanded,item)"
                         class="v-data-table__expand-icon"
                         :class="{ 'v-data-table__expand-icon--active': isExpanded }"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </div>
                </div>
            </td>
          </template>

          <template v-slot:item.user="{ value }">
                <span>{{ value && value.name ? value.name : '-' }}
                </span>
          </template>

          <template v-slot:item.createdDate="{ value }">
            <div class="d-flex flex-column">
              <span>{{ value.day }}</span>
              <span class="grey--text  lighten-2 caption">{{ value.hour }}</span>
            </div>
          </template>
          <template v-slot:item.virtualSite="{value,item}">
            <div class="d-flex flex-column my-1">
              <span class="caption grey--text lighten-1">#{{ item.id }}</span>
              <site-label :sitename="value" v-if="value"></site-label>
              <span v-else> - </span>

            </div>
          </template>
          <template v-slot:item.FILES="{value}">
            <!--            <v-tooltip top v-if="value.length > 0">
                          <template v-slot:activator="{ on }">
                            <a :href="value" v-on="on">-->
            <v-icon v-if="value.length > 0" color="teal lighten-2" @click="openAudioDialog(value)">
              mdi-file-cloud-outline
            </v-icon>
            <v-tooltip top v-else>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="grey lighten-1">mdi-file-cancel-outline</v-icon>
              </template>
              Aucun fichier audio
            </v-tooltip>

          </template>
          <template v-slot:item.category="{value, item}">
            <div>
              <v-icon small class="mr-1">{{ getCategory(value).icon }}</v-icon>
              {{ getCategory(value).value ?? "non enregistré" }}
            </div>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length-1">
              <v-row>
                <v-col cols="9">
                  <span v-if="transcriptEmpty != null && item.transcription == null">
                    {{ transcriptEmpty }}
                  </span>
                  <Chatting :transcription="item.transcription" inline/>
                </v-col>
                <v-col cols="3" class="pt-7">
                  <div class="d-flex flex-column">
                    <smart-btn block class="" v-if="item.transcription != null && item.FILES.length > 0" elevation="0"
                               small
                               @click.native="regenTranscription(item.id)">
                      <v-icon class="mr-1">mdi-update</v-icon>
                      Regénérer transcription
                    </smart-btn>
                    <smart-btn block class="my-2"
                               v-if="item.transcription != null && item.FILES.length > 0 && item.leadId != null"
                               elevation="0" small
                               @click.native="resumeTranscription(item.id, item.leadId)">
                      <v-icon class="mr-1">mdi-script-text-key-outline</v-icon>
                      Générer résumé
                    </smart-btn>
                  </div>
                </v-col>
              </v-row>
            </td>
          </template>

        </v-data-table>


      </v-container>
      <smart-dialog v-model="callCategoryDialog" title="Catégorisation de l'appel">
        <EndedCallDialog v-if="callCategoryDialog" v-model="callCategoryDialog" :call="itemToCategories"
                         @selected="closeAndReload()"/>
      </smart-dialog>
      <smart-dialog v-model="creationLeadDialog" title="Créer un lead">
        <LeadCreationDialog :userList="users" :lead-info="leadInfos" @lead-created="()=>closeCreationLeadDialog()"/>
        <template title="actions">
          <v-card-actions class="actions-btn mt-4">
            <v-spacer/>
            <smart-btn type="submit" form="creation-lead">
              <v-icon small class="mr-1">mdi-content-save</v-icon>
              Créer
            </smart-btn>
          </v-card-actions>
        </template>
      </smart-dialog>

      <smart-dialog v-model="audio.dialog" title="Fichier audio">
        <div class="d-flex pa-4 justify-space-around">
          <audio controls>
            <source :src="audio.file" type="audio/mpeg">
            <div class="d-flex flex-column">
              <span>Votre navigateur n'est pas compatible</span>
              <a :href="audio.file">
                <v-icon small>mdi-download-outline</v-icon>
                Télécharger le fichier</a>
            </div>
          </audio>

        </div>
      </smart-dialog>
    </template>
  </layout>
</template>
<script>
import FormMixin from "@/components/mixins/FormMixin";
import Layout from "@/views/Layout";
import {get, post} from "@/utils/api";
import SiteLabel from "@/components/commons/SiteLabel.vue";
import Chatting from "@/components/commons/Chatting.vue";
import {EventBus} from "@/components/commons/event-bus";
import EndedCallDialog from "@/components/commons/EndedCallDialog.vue";
import LeadCreationDialog from "@/views/lead/LeadCreationDialog.vue";
import jwtdecode from "jwt-decode";
import User from "@/utils/user";

export default {
  name: "PhoneCalls",
  components: {
    LeadCreationDialog,
    EndedCallDialog,
    Chatting,
    SiteLabel,
    Layout
  },
  mixins: [FormMixin],
  data() {
    return {
      menuOpts: {
        "close-on-content-click": true,
        transition: "scale-transition",
        "offset-y": true,
      },
      defaultSearchform: {
        startDate: this._formatDate(new Date(), "dd/MM/yyyy"),
        stopDate: this._formatDate(new Date(), "dd/MM/yyyy"),
        sites: [],
        category: null,
        phoneNumber: null
      },
      searchform: {},
      users: [],
      datatable: {
        headers: [
          {
            text: "Site",
            value: "virtualSite",
            align: "left",
            sortable: true
          },
          {
            text: "Tel.",
            value: "phoneNumber",
            align: "left",
            sortable: true
          },
          {
            text: "Client",
            value: "client",
            align: "left",
            sortable: true
          },
          {
            text: "User",
            value: "user",
            align: "left",
            sortable: true
          },
          {
            text: "Date",
            value: "createdDate",
            align: "left",
            sortable: true
          },
          {
            text: "Categorie",
            value: "category",
            align: "left",
            sortable: true
          },
          {
            text: "Fichiers",
            value: "FILES",
            align: "left",
            sortable: true,
            width: 100,
          },
          {text: "Actions", value: "data-table-expand", align: "right", width:75},

        ],
        selectedCalls: [],
        listCalls: [],
        expanded: [],
      },
      loading: false,
      audio: {
        dialog: false,
        file: ""
      },
      sites: [],
      categories: [
        {
          key: "Payment",
          value: "Paiement",
          icon: "mdi-cash-multiple"
        },
        {
          key: 'Reinsurance',
          value: "Réassurance",
          icon: "mdi-shield-home-outline"
        },
        {
          key: "Formalities",
          value: "Formalités",
          icon: "mdi-list-box-outline"
        },
        {
          key: "InfoBooking",
          value: "Informations réservation",
          icon: "mdi-cart-check"
        },
        {
          key: "InfoQuote",
          value: "Information devis",
          icon: "mdi-cart-arrow-down"
        },
        {
          key: "Other",
          value: "Autre",
          icon: "mdi-chat-question-outline"
        },
        {
          key: "Advice",
          value: "Conseil",
          icon: "mdi-cash-multiple"
        },
        {
          key: "Technical",
          value: "Question technique",
          icon: "mdi-shield-home-outline"
        },
        {
          key: -1,
          value: "Non enregistré",
          icon: "mdi-content-save-off-outline"
        },
      ],
      callCategoryDialog: false,
      itemToCategories: null,
      creationLeadDialog: false,
      leadInfos: null,
      connectedUser: jwtdecode(localStorage.getItem("auth")),
      transcriptEmpty: null,
    }
  },
  mixin: [User],
  created() {
    this.init()
  },
  methods: {
    init() {
      const loadUsers = get("/api/search/users").json(rs => {
        const res = rs.filter(u => u.groups.some(g => g.id == 3))

        if (this.isAdmin) {
          this.users = [{name: "Tous", id: -1}, ...res]
        } else {
          this.users = [this.currentUser]
        }
      })

      Promise.all([
        loadUsers,
      ]).then(() => {
        this.initSearchform();
        this.loadData()
      });
    },
    initSearchform() {
      const qs = new URLSearchParams(window.location.search);
      const search = (() => {
        try {
          let searchCtx = JSON.parse(qs.get("search"));
          return {
            ...searchCtx,
            startDate: this._formatDate(new Date(searchCtx.startDate), "dd/MM/yyyy"),
            stopDate: this._formatDate(new Date(searchCtx.stopDate), "dd/MM/yyyy"),
            userId: this.isAdmin ? (searchCtx.userId ? searchCtx.userId : -1) : this.currentUser.id
          }
        } catch (e) {
          return null;
        }
      })();
      if (search) {
        this.searchform = search
      } else this.clearSearchform();
    },
    getCalls(searchForm) {
      post("/api/phonecall/search", {...searchForm}).json((res) => {
        this.datatable.listCalls = res.map(rs => {
          return {
            ...rs,
            client: rs.client.trim() == "" ? '-' : rs.client,
            createdDate: {
              day: this._formatDate(new Date(rs.createdDate), "dd/MM/yyyy"),
              hour: this._formatDate(new Date(rs.createdDate), "HH:mm")

            }
          }
        })

        this.loading = false
      })
    },
    valid() {
      if (this.$refs.searchform.validate()) {
        this.loadData()
      }
    },
    loadData() {
      this.datatable.listCalls = [];
      this.loading = true
      const body = {
        ...this.searchform,
        startDate: this._parseAndFormatDate(this.searchform.startDate, "dd/MM/yyyy", "yyyy-MM-dd"),
        stopDate: this._parseAndFormatDate(this.searchform.stopDate, "dd/MM/yyyy", "yyyy-MM-dd"),
      }

      if (this.isAdmin && this.searchform.userId === -1) {
        body.userId = null
      }

      window.history.pushState(
          "",
          "",
          "?search=" + JSON.stringify(body)
      );

      this.getCalls(body);
    },
    clearSearchform() {
      this.searchform = {...this.defaultSearchform};
    },
    openAudioDialog(item) {
      this.audio = {
        file: item[0],
        dialog: true
      }
    },
    regenTranscription(id) {
      get(`/api/phonecall/regen/transcription/${id}`)
    },

    openDialogCategory(item) {
      this.itemToCategories = item
      this.callCategoryDialog = true
    },
    getCategory(value) {
      return this.categories.find(cat => cat.key == value) ?? this.categories.find(cat => cat.key == -1)
    },
    toLead(id) {
      let route = this.$router.resolve({
        name: "lead",
        params: {id: id},
      });
      window.open(route.href, "_blank");
    },
    createLead(phoneCall) {
      this.leadInfos = {
        ...phoneCall,
        source: 'phone',
        status: 'open',
        score: 2,
        site: phoneCall.virtualSite,
        phonecalls: 1,
        destinations: [],
        id: null,
        phoneCallId: phoneCall.id,
        client_id: phoneCall.clientId,
      }

      this.creationLeadDialog = true
    },
    resumeTranscription(transcriptionId, leadId) {
      EventBus.$emit('snackbarTranscription', {
        show: true,
        done: false,
        leadId: leadId
      })

      post(
          `/api/phonecall/${transcriptionId}/summarize-transcription/lead/${leadId}`
      ).res(() => {
        EventBus.$emit('snackbarTranscription', {
          show: true,
          done: true,
          leadId: leadId
        })
      })
    },
    closeAndReload() {
      this.callCategoryDialog = false
      this.itemToCategories = null
      this.init()
    },
    getTranscription(alreadyExpanded, item) {
      this.transcriptEmpty = null
      if (item.transcription != null || alreadyExpanded) {
        return null
      } else {
        get(`/api/phonecall/${item.id}/get-transcription`).json((rs) => {
          item.transcription = rs
          return item

        }).catch((err) => {
          if (err.toString().includes('Transcription is empty')) {
            this.transcriptEmpty = "Cet appel n'a pas encore été transcrit"
            return null
          } else {
            console.error(err)
          }
        })

      }
    },
    closeCreationLeadDialog() {
      this.leadInfos = null
      this.creationLeadDialog = false
      this.init()
    }
  }
}
</script>