<template>
  <layout drawerShow :drawerWidth="400">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{ name: 'supplier-invoice-list' }"
        >Liste des Factures
        </v-tab
        >
        <v-tab :to="{ name: 'supplier-invoice-imports' }">Imports</v-tab>
        <v-tab :to="{ name: 'supplier-invoice-payment' }">Liste des paiements</v-tab>
      </v-tabs>
    </template>

    <template slot="nav-tools">

      <smart-btn
          class="mx-2 d-none d-sm-flex"
          primary
          :disabled="items.length === 0"
          @click.native="exportCsv"
      >
        <v-icon>mdi-file-check-outline</v-icon>
        <span class="ml-2">Exports</span>
      </smart-btn>
      <smart-btn
          class="mx-2 d-none d-sm-flex red"
          primary
          :disabled="selected.length === 0"
          @click.native="dialogs.deleteInvoice = true"
      >
        <v-icon>mdi-delete</v-icon>
        <span class="ml-2">Supprimer</span>
      </smart-btn>
      <smart-btn
          class="mx-2 d-none d-sm-flex"
          primary
          :disabled="selected.length === 0"
          @click.native="dialogs.validate = true"
      >
        <v-icon>mdi-file-export</v-icon>
        <span class="ml-2">Valider les factures ({{ selected.length }})</span>
      </smart-btn>
      <smart-btn
          class="mx-2 d-none d-sm-flex"
          primary
          :disabled="selected.length === 0"
          @click.native="dialogs.pay = true"
      >
        <v-icon>mdi-file-check-outline</v-icon>
        <span class="ml-2">Payer les factures ({{ selected.length }})</span>
      </smart-btn>
      <smart-btn
          class="mx-2 d-none d-sm-flex"
          secondary
          :to="{ name: 'supplier-invoice', params: { id: 'create' } }"
      >
        <v-icon>mdi-file-plus</v-icon>
        <span class="ml-2">Nouvelle Facture</span>
      </smart-btn>
    </template>

    <template slot="drawer">
      <v-container fluid class="pa-0">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
                v-model.number="searchCtx.supplierAccountingId"
                :items="listSuppliers"
                item-value="id"
                item-text="name"
                label="Fournisseur"
                clearable
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="searchCtx.ref"
                label="Réf. Fournisseur"
                placeholder=" "
                hide-details
            />
          </v-col>
          <v-col cols="6">
            <v-select
                v-model="searchCtx.status"
                :items="listStatus"
                label="Statuts"
                hide-details
            >
              <template #item="{ item }">{{
                  $t("supplierinvoicestatus." + item)
                }}
              </template>
              <template #selection="{ item }">{{
                  $t("supplierinvoicestatus." + item)
                }}
              </template>
            </v-select>
          </v-col>

          <v-col cols="12">
            <v-divider/>
            <div class="caption mt-2">Date de facture</div>
          </v-col>
          <v-col cols="6">
            <smart-picker
                v-model="searchCtx.creationDateStart"
                label="Début"
                placeholder=" "
                :inputOpts="pickerOpts"
            />
          </v-col>
          <v-col cols="6">
            <smart-picker
                v-model="searchCtx.creationDateStop"
                label="Fin"
                placeholder=" "
                :inputOpts="pickerOpts"
            />
          </v-col>

          <v-col cols="12">
            <v-divider/>
            <div class="caption mt-2">Date de paiement</div>
          </v-col>
          <v-col cols="6">
            <smart-picker
                v-model="searchCtx.paymentDateStart"
                label="Début"
                placeholder=" "
                :inputOpts="pickerOpts"
            />
          </v-col>
          <v-col cols="6">
            <smart-picker
                v-model="searchCtx.paymentDateStop"
                label="Fin"
                placeholder=" "
                :inputOpts="pickerOpts"
            />
          </v-col>

          <v-col cols="12">
            <v-divider/>
            <div class="caption mt-2">Date limite de paiement</div>
          </v-col>
          <v-col cols="6">
            <smart-picker
                v-model="searchCtx.maxPaymentDateStart"
                label="Début"
                placeholder=" "
                :inputOpts="pickerOpts"
            />
          </v-col>
          <v-col cols="6">
            <smart-picker
                v-model="searchCtx.maxPaymentDateStop"
                label="Fin"
                placeholder=" "
                :inputOpts="pickerOpts"
            />
          </v-col>
          <v-col cols="12" class="text-right">
            <v-divider class="mb-3"/>
            <v-btn class="primary" :loading="loading" @click="search">
              <v-icon>search</v-icon>
              Rechercher
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template slot="content">
      <v-container>
        <v-card>
          <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :footer-props="{
              'items-per-page-options': rowsPerPageItems,
              'items-per-page-text': 'Lignes par page',
            }"
              no-data-text="-"
              class="tbl"
              show-select
              v-model="selected"
              @click:row="redirect"
          >
            <template #item.creationDate="{ value }">
              <div class="font-weight-regular">
                {{
                  _parseAndFormatDate(
                      value,
                      datePatternConfig.serverLocalDateTime,
                      _datePattern.localDate
                  )
                }}
              </div>
              <div class="caption grey--text text--darken-1">
                {{
                  _parseAndFormatDate(
                      value,
                      datePatternConfig.serverLocalDateTime,
                      _datePattern.time
                  )
                }}
              </div>
            </template>
            <template #item.maxPaymentDate="{ value }">
              <div class="font-weight-regular">
                {{
                  _parseAndFormatDate(
                      value,
                      datePatternConfig.serverLocalDateTime,
                      _datePattern.localDate
                  )
                }}
              </div>
            </template>
            <template #item.amount="{ item, value }">{{
                value | currency(item.currencyCode)
              }}
            </template>
            <template #item.status="{ item, value }">
              {{ $t("supplierinvoicestatus." + value) }}
              <div>
                <v-chip
                    v-if="item.hasPrestationCancelled"
                    color="red"
                    x-small
                    class="white--text"
                >
                  <v-icon class="mr-2" x-small>mdi-alert</v-icon>
                  Prestation
                  annulé
                </v-chip>
              </div>
            </template>
            <template #item.discord="{ value }">
              <template v-if="value">
                <v-icon small color="red">mdi-alert-octagram-outline</v-icon>
              </template>
            </template>
          </v-data-table>
        </v-card>
      </v-container>
      <smart-dialog v-model="multiple" width="auto" :max-width="1000">
        <template slot="title">
          <v-toolbar-title class="white--text">Factures</v-toolbar-title>
        </template>

        <v-card class="ma-3">
          <v-data-table
              :headers="headers"
              :items="selected"
              class="tbl"
              hide-default-footer
              :footer-props="{
                          'items-per-page-options': rowsPerPageItems,
                          'items-per-page-text': 'Lignes par page',
                        }"
              @click:row="redirect"
          >
            <template #item.creationDate="{ value }">
              <div class="font-weight-regular">
                {{
                  _parseAndFormatDate(
                      value,
                      datePatternConfig.serverLocalDateTime,
                      _datePattern.localDate
                  )
                }}
              </div>
              <div class="caption grey--text text--darken-1">
                {{
                  _parseAndFormatDate(
                      value,
                      datePatternConfig.serverLocalDateTime,
                      _datePattern.time
                  )
                }}
              </div>
            </template>
            <template #item.maxPaymentDate="{ value }">
              <div class="font-weight-regular">
                {{
                  _parseAndFormatDate(
                      value,
                      datePatternConfig.serverLocalDateTime,
                      _datePattern.localDate
                  )
                }}
              </div>
            </template>
            <template #item.amount="{ item, value }">{{
                value | currency(item.currencyCode)
              }}
            </template>

            <template #item.status="{ value }">{{
                $t("supplierinvoicestatus." + value)
              }}
            </template>

            <template #item.discord="{ value }">
              <template v-if="value">
                <v-icon small color="red">mdi-alert-octagram-outline</v-icon>
              </template>
            </template>
          </v-data-table>
        </v-card>

        <v-row class="px-3 pb-2" justify="end">
          <v-col cols="8">
            <v-alert v-if="insufficientAmount" dense outlined type="error">
              Un des portefeuille à des fonds insuffisant
            </v-alert>
            <v-alert v-if="!isSameBank" dense outlined type="error">
              Les factures doivent avoir le même compte bancaire.
            </v-alert>
            <v-alert v-if="!isSameCurrency" dense outlined type="error">
              Les factures doivent avoir les même devise
            </v-alert>
          </v-col>
          <v-col col="4" class="text-right text-h6">
            <span>Total: {{ getSelectedInvoicesTotal() }} €</span>
          </v-col>
        </v-row>

        <template slot="actions">
          <smart-btn
              :disabled="disabled"
              primary
              @click.native="validate"
              v-if="dialogs && dialogs.validate"
          >
            <v-icon>mdi-file-check-outline</v-icon>
            <span class="ml-2">Valider</span>
          </smart-btn>
          <smart-btn primary @click.native="pay" v-if="dialogs && dialogs.pay" :disabled="disabled">
            <v-icon>mdi-file-check-outline</v-icon>
            <span class="ml-2">Payer</span>
          </smart-btn>
          <span></span>
        </template>
      </smart-dialog>

      <smart-dialog v-model="dialogs.mails" width="800px" :max-width="1000" title="Envoi des notifications"
                    @close="closeMailDialog">
        <v-tabs v-model="tabToMail">
          <v-tab v-for="(supplier,i) in Object.keys(selectionGroupedBySupplier)" :key="i"> {{ supplier }}</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabToMail">
          <v-tab-item v-for="(supplier,i) in Object.keys(selectionGroupedBySupplier)" :key="i+'_item'">
            <div class="pa-3">
              <v-checkbox
                  v-model="mails[i]"
                  label="Envoyé un mail à ce fournisseur"
                  hide-details
                  class="mb-3"
              ></v-checkbox>
              <mail-composer ref="mailSender"
                             v-if="mails[i]"
                             :subject="mailComposerData[supplier]?.subject"
                             :tos="mailComposerData[supplier]?.suppliers[0]?.emails ?? ['']"
                             :loading="false"
                             @send="(obj) => send(obj)"
                             hide-send-btn
                             :message="mailComposerData[supplier].message"/>
            </div>
          </v-tab-item>
        </v-tabs-items>
        <template #actions="">
          <smart-btn @click.native="sendMails">valider</smart-btn>
        </template>
      </smart-dialog>

      <smart-dialog v-model="dialogs.deleteInvoice" width="500px" :max-width="1000">
        <template slot="title">
          <v-toolbar-title class="white--text">Suppression factures</v-toolbar-title>
        </template>
        <div class="pa-3">
          <p v-if="!SelectedIsPending" class="mb-0">Voulez vous supprimer ces factures ?</p>
          <p v-else class="mb-0">Vous ne pouvez pas supprimer ces factures car certaines ne sont pas en attentes</p>
          <div class="d-flex justify-end">
            <smart-btn
                @click.native="deleteInvoices()"
                :disabled="SelectedIsPending"
            >
              Supprimer
            </smart-btn>
          </div>
        </div>
      </smart-dialog>
    </template>
  </layout>
</template>



<script>
import Layout from "@/views/Layout";
import AccountingNav from "@/views/accounting/AccountingNav";
import {get, post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";
import _ from "lodash";
import MailComposer from "@/components/commons/MailComposer.vue";

export default {
  name: "SupplierInvoiceList",

  props: [],
  components: {
    MailComposer,
    Layout,
    AccountingNav,
    EventBus,
  },
  mixins: [],
  data() {
    return {
      rowsPerPageItems: [{text: "Tous", value: -1}, 50, 200],
      selected: [],
      headers: [
        {text: "#", value: "id", sortable: false},
        {text: "Fournisseur", value: "supplierAccountingName"},
        {text: "Ref", value: "ref"},
        {
          text: "Date création",
          value: "creationDate",
          sort: this.sortByDateTime,
        },
        {text: "Montant", value: "amount"},
        {text: "Statut", value: "status"},
        {text: "BANK", value: "bankAccount"},
        {
          text: "Date max paiement",
          value: "maxPaymentDate",
          sort: this.sortByDateTime,
        },
        {text: "", value: "discord", width: 20},
      ],
      searchCtx: {
        supplierAccountingId: null,
        ref: null,
        status: "waiting",
        creationDateStart: null,
        creationDateStop: null,
        maxPaymentDateStart: null,
        maxPaymentDateStop: null,
        paymentDateStart: null,
        paymentDateStop: null,
      },
      loading: false,
      items: [],
      listSuppliers: [],
      listStatus: [],
      pickerOpts: {
        class: "pt-0",
        clearable: true,
      },
      dialogs: {
        deleteInvoice: false,
        validate: false,
        pay: false,
        mails: false
      },
      selectionGroupedBySupplier: [],
      mailComposerData: {},
      tabToMail: null,
      mails: [],
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    multiple: {
      get() {
        return this.dialogs.validate || this.dialogs.pay;
      },
      set(newVal) {
        if (!newVal) {
          this.dialogs.validate = newVal;
          this.dialogs.pay = newVal;
        }
      },
    },
    disabled() {
      return !this.isSameBank || !this.isSameCurrency || this.insufficientAmount;
    },
    SelectedIsPending() {
      return !this.selected.every(x => x.status == "waiting")
    },
    insufficientAmount() {
      const grouped = Object.groupBy(this.selected, ({wallet}) => wallet?.id ?? null);
      delete grouped['null'];

      return Object.keys(grouped).some(key => {
        let total = grouped[key].reduce((sum, invoice) => sum + invoice.amount, 0);
        let walletAmount = grouped[key][0].wallet.amount;
        return total > walletAmount;
      });
    },
    isSameBank() {
      return _.uniqBy(this.selected, "bankAccount").length == 1
    },
    isSameCurrency() {
      const currencyTab = this.selected.map(item => item.currencyCode)
      return currencyTab.every(str => str === currencyTab[0])
    }
  },
  methods: {
    init() {
      get("/api/accounting/list-supplier").json(
          (data) => (this.listSuppliers = data)
      );

      get("/api/accounting/list-status").json(
          (data) => (this.listStatus = data)
      );

      this.search();
    },
    async deleteInvoices() {
      const listId = this.selected.map(x => x.id)
      await post("/api/accounting/supplier-invoice/multiple-delete", listId)
          .badRequest((err) => {
            EventBus.$emit("toaster-msg", err.toString());
          })
          .res(() => {
            this.init();
            EventBus.$emit("toaster-msg", "Suppression enregistrée");
          });
      this.dialogs.deleteInvoice = false
    },
    search() {
      this.loading = true;
      this.items = [];
      this.selected = [];

      post("/api/accounting/supplier-invoice/list", this.searchCtx)
          .json((data) => {
            this.items = data;
          })
          .catch((e) => {
            EventBus.$emit("toast", {
              color: "red",
              text: "Une erreur est survenue",
              exception: e,
            });
          })
          .then(() => {
            this.loading = false;
          });
    },
    redirect(item) {
      this.$router.push({name: "supplier-invoice", params: {id: item.id}});
    },
    getMailSubject(suppliers, lang) {
      return `Hotelissima: ${lang == "FR" ? 'Règlement de facture' : 'Invoices payment'}`
    },
    getMessages(suppliers, lang) {
      const amount = suppliers.reduce((sum, invoice) => sum + invoice.amount, 0);

      const currency = suppliers[0].currencyCode
      const refs = suppliers.map(item => {
        let ids =[]
        item.prestations.map(p => {
          if(ids.indexOf(p.bookingId) == -1 ){
            ids.push(p.bookingId)
          }
        })
        return `<tr>
                    <td>${item.ref}</td>
                    <td>${item.amount}</td>
                    <td>${currency}</td>
                    <td>${ids.join(", ")}</td>
                </tr>`
      })
      let msg = ""

      const table = `
        <table style="border-collapse: collapse; width: 100%;" border="1">
            <tr>
              <th>Ref</th>
              <th>Amount</th>
              <th>Bank</th>
              <th>Booking</th>
            </tr>
          <tbody>
            ${refs.join("")}
            <tr>
                <td>Total</td>
                <td>${amount}</td>
                <td>${currency}</td>
                <td></td>
            </tr>
          </tbody>
        </table>
        `

      if (lang == "FR") {

        msg = `Bonjour,<br/><br/>
                    Un paiement de ${amount} ${currency} vous à été envoyé pour les factures suivantes:<br/>
                    <br/>
                    ${table}
                    <br/>
                    <br/>
                    Cordialement,
                    <br/><br/>
                    ${currency == "USD" ? '<em>Un délais de 7 jours est nécessaire pour que le virement soit visible sur votre compte.</em>' : ''}
`

      } else {
        msg = `Hello,<br/>
                  A payment of ${amount} ${currency} has been sent to you for the following invoices:<br/>
                  <br/>
                  ${table}
                  <br/>
                  <br/>
                  Sincerely,
                  <br/>
                  <br/>
                  ${currency == "USD" ? '<em>A period of 7 days is required for the transfer to be visible on your account.</em>' : ''}`
      }

      return msg
    },
    getSupplierAccounting(id) {
      return get(`/api/supplier-accounting/${id}`).json((rs) => {
        return rs
      })
    },
    prepareMails() {
      this.selectionGroupedBySupplier = Object.groupBy(this.selected, ({supplierAccountingName}) => supplierAccountingName)
      let tmp;
      this.mails = Object.keys(this.selectionGroupedBySupplier).map(() => true)
      Object.keys(this.selectionGroupedBySupplier).map(key => {
        tmp = {
          ...tmp,
          [key]: {
            suppliers: [],
            message: '',
            send: true
          }
        }
        this.getSupplierAccounting(this.selectionGroupedBySupplier[key][0].supplierAccountingId)
            .then((supplierAccounting) => {
              console.log(this.selectionGroupedBySupplier)
              this.selectionGroupedBySupplier[key].map(sup => {

                sup.emails = supplierAccounting.emails
                tmp[key] = {
                  suppliers: [...tmp[key].suppliers, sup],
                  message: this.getMessages([...tmp[key].suppliers, sup], supplierAccounting.lang),
                  subject: this.getMailSubject([...tmp[key].suppliers, sup], supplierAccounting.lang),
                }
              })
              this.mailComposerData[key] = tmp[key]
              this.$nextTick(() => {
                if (Object.keys(this.mailComposerData).length == Object.keys(this.selectionGroupedBySupplier).length) {
                  this.dialogs.mails = true
                }
              })
            })
      })
    },
    send(email) {
      const url = `/api/mail/send`;
      post(url, email)
          .json((r) => {
            if (r) {
              EventBus.$emit("toaster-msg", "Emails envoyé•s");
              this.dialogs.mails = false;
              this.mailComposerData = {};
              this.mails = {}
            } else {
              EventBus.$emit("toaster-error", "Echec de l'envoi");
            }
          })
    },
    sendMails() {
      this.$refs.mailSender.map(composer => composer.send())
    },
    pay() {
      post("/api/accounting/supplier-invoice/pay-multiple", {
        ids: this.selected.map((s) => s.id),
      }).res(() => {
        this.dialogs.pay = false;
        this.prepareMails()
      });
    },
    closeMailDialog() {
      this.selectionGroupedBySupplier = []
      this.mailComposerData = {};
      this.mails = {}
    },
    validate() {
      post("/api/accounting/supplier-invoice/validate-multiple", {
        ids: this.selected.map((s) => s.id),
      }).res(() => {
        this.dialogs.validate = false;
        this.search();
      });
    },
    getSelectedInvoicesTotal() {
      let total = 0;

      this.selected.map((item) => {
        total += item.amount;
      });

      return total;
    },
    sortByDateTime(a, b) {
      const start = a != null ? a : this._formatDate(new Date(null), this.datePatternConfig.serverLocalDateTime)
      const stop = b != null ? b : this._formatDate(new Date(null), this.datePatternConfig.serverLocalDateTime)
      return this._sortDates(start, stop, this.datePatternConfig.serverLocalDateTime);
    },
    exportCsv() {

      let date;

      if (this.searchCtx.creationDateStart) {
        date = (
            this.searchCtx.creationDateStart +
            "_" +
            this.searchCtx.creationDateStop
        ).replace(/\//g, "-");
      } else {
        date = this._formatDate(
            this._now(),
            this.datePatternConfig.serverLocalDate
        ).replace(/\//g, "-");
      }

      let headers = this.headers.map((header) => header.text).join(";") + 'discord;dossiers';

      let data = this.items.map((invoice, index) => {

        const bookings = _.uniq(invoice.prestations.map(p => {
          return p.bookingId
        }))

        return [
          invoice.id,
          invoice.supplierAccountingName,
          invoice.ref,
          invoice.creationDate,
          invoice.amount,
          invoice.currencyCode,
          invoice.status,
          invoice.maxPaymentDate,
          invoice.discord ? "désaccord" : "",
          bookings.join(", ")
        ].join(";")

      }).join('\n');

      let csvContent = headers + '\n' + data;
      const blob = new Blob([csvContent], {type: "text/csv;charset=utf-8;"});
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = "export_" + date + ".csv";

      link.click();
      link.remove();
    },
  },
};
</script>

<style scoped>
</style>
