import wretch from "wretch"
import {EventBus} from "@/components/commons/event-bus";
import router from '@/router'
import { authMethods } from '@/utils/user'

const watchToken = () => next => (url, opts) => {
	return next(url, opts).then(response => {
		const token = response.headers.get('TOKEN')
		if (token != null){
			EventBus.$emit('token', token)
		}
		return response
	});
}

const buildInstance = (url, headers, options) => {
	const httpHeaders = {
		"Authorization": authMethods._getToken(),
		...headers
	}

	const opts = {
		...options
	}

	return wretch(url)
		.options(opts)
		.headers(httpHeaders)
		.middlewares([watchToken()])
}

const call = (wretchInstance) => {
	return wretchInstance
		.unauthorized(err => {
			console.error(err)
			EventBus.$emit('toaster-error', router.app.$t('error-401'))
			EventBus.$emit('unauthorized-route', {})
			//router.push({name:'Login', query: {redirect: router.history.current.fullPath}})
		})
		.forbidden(err => {
			console.error(err)
			EventBus.$emit('toaster-error', router.app.$t('error-403'))
			EventBus.$emit('unauthorized-route', {})
			//router.push({name:'Login'})
		})
		.notFound(err => {
			console.error(err)
			EventBus.$emit('toaster-error', router.app.$t('error-404'))
		})
		.timeout(err => {
			console.error(err)
			EventBus.$emit('toaster-error', router.app.$t('error-408'))
		})
		.internalError(err => {
			console.error(err);
			EventBus.$emit('toaster-error', router.app.$t('error-500'))
		})
};
export const getAuthHeader = () => {
	return {"Authorization":authMethods._getToken()}
}
export const get = (url, headers, options) => {
	return call(
		buildInstance(url, headers, options).get()
	)
};

export const post = (url, payload, headers, options) => {
	return call(
		buildInstance(url, headers, options).post(payload)
	)
};
