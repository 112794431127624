<template>
  <layout :drawer="false">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{ name: 'supplier-invoice-list' }"
        >Liste des Factures
        </v-tab
        >
        <v-tab :to="{ name: 'supplier-invoice-imports' }">Imports</v-tab>
        <v-tab :to="{ name: 'supplier-invoice-payment' }">Liste des paiements</v-tab>
      </v-tabs>

    </template>
    <template slot="content">
      <v-container>
        <v-card class="white mt-2 mb-4 px-3 py-1 pt-2">
          <v-row dense class="">
            <v-col cols="2" class="d-flex align-center  pb-3">
              <v-autocomplete
                  class="mt-3"
                  v-model.number="searchCtx.supplierAccountingId"
                  :items="listSuppliers"
                  item-value="id"
                  item-text="name"
                  label="Fournisseur"
                  clearable
                  dense
              />
            </v-col>
            <v-col cols="2" class="d-flex align-center  pb-3">
              <smart-picker
                  v-model="searchCtx.paymentDateStart"
                  label="Début paiement"
                  placeholder=" "
                  :inputOpts="pickerOpts"
              />
            </v-col>
            <v-col cols="2" class="d-flex align-center pb-3">
              <smart-picker
                  v-model="searchCtx.paymentDateStop"
                  label="Fin paiement"
                  placeholder=" "
                  :inputOpts="pickerOpts"
              />
            </v-col>
            <v-col cols="2" class="d-flex align-center">
              <v-text-field
                  class="mr-1 mt-2.5"
                  v-model="searchCtx.minAmount"
                  label="Montant min"
                  hide-details
                  single-line
                  dense
                  type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="d-flex align-center">
              <v-text-field
                  class="mr-1 mt-2.5"
                  v-model="searchCtx.maxAmount"
                  label="Montant max"
                  hide-details
                  single-line
                  dense
                  type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="d-flex align-center pl-4">
              <smart-btn :loading="loading" @click.native="search">
                <v-icon>search</v-icon>
                Rechercher
              </smart-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-card>
          <v-data-table
              :headers="headers"
              :items="items"
              :loading="loading"
              :footer-props="{
              'items-per-page-options': rowsPerPageItems,
              'items-per-page-text': 'Lignes par page',
            }"
              no-data-text="-"
              class="tbl"

          >
            <template #item.actions="{item}">
              <smart-btn secondary :loading="loadingInvoices" @click.native="openInvoices(item)" small> détails
              </smart-btn>
            </template>
          </v-data-table>
        </v-card>

        <v-navigation-drawer
            v-model="invoices"
            width="600"
            absolute
            right
            temporary
        >
          <div style="overflow:scroll; position:relative">
          <v-list-item>
            <v-list-item-title>Paiement du {{ payment.date }} <span class="caption grey--text">#{{ payment.ref }}</span>
            </v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list dense>
            <v-data-table v-if="invoicesInfo.length > 0"
                          :headers="invoiceHeaders"
                          :items="invoicesInfo"
                          hide-default-footer
                          no-data-text="-"
                          class="tbl pa-2"
            >
              <template #item.bookingId="{value}">
                <smart-btn x-small secondary class="mx-1" @click.native="toBooking(value)" v-if="value">
                  #{{ value }}
                </smart-btn>
              </template>
              <template #item.ref="{item}">
                <smart-btn x-small secondary class="mx-1" @click.native="toInvoice(item.id)">
                  #{{ item.id }}
                </smart-btn>
              </template>
              <template #footer>
                <div class="d-flex justify-end mr-4" v-if="invoicesInfo.length >0">
                  Montant total: {{ _formatDecimal(totalAmount) }} {{ invoicesInfo[0].currency }}
                </div>
              </template>
            </v-data-table>

          </v-list>
            <v-row>
          <v-col cols="4" v-for="(pdf,i) in pdfList" :key="i" class="d-flex justify-space-between my-2 px-4">
            <file-item :download-url="pdf.url" >
              {{pdf.name}}
            </file-item>
          </v-col>
              </v-row>
          <v-divider class="my-3"/>
          <document-uploader class=" pa-3" v-if="payment.ref != null"
                             :url="getUrlUpload()"
                             acceptedFiles=".pdf,.jpg,.jpeg,.csv,.xlsx,.bmp, .png, .docx"
                             @uploaded="uploaded"
                             @error="onError"
          />
            </div>
        </v-navigation-drawer>
      </v-container>
    </template>
  </layout>
</template>
<script>
import Layout from "@/views/Layout";
import AccountingNav from "@/views/accounting/AccountingNav";
import {get, post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";
import {format, parseISO} from "date-fns";
import DocumentUploader from "@/components/commons/DocumentUploader.vue";
import FileItem from "@/components/commons/FileItem.vue";

export default {
  name: "SupplierInvoicePayment",

  props: [],
  components: {
    FileItem,
    DocumentUploader,
    Layout,
    AccountingNav,
    EventBus,
  },
  mixins: [],
  data() {
    return {
      drawerOpen: true,
      rowsPerPageItems: [50, 200, {text: "Tous", value: -1}],
      selected: [],
      invoiceHeaders: [
        {text: "Ref.", value: "ref"},
        {text: "Fournisseurs", value: "name"},
        {text: "Montant", value: "amount"},
        {text: "Devise", value: "currency"},
      ],
      headers: [
        {text: "#", value: "id", sortable: false},
        {
          text: "Date création",
          value: "creation_date",
          sort: this.sortByDateTime,
        },
        {text: "Fournisseurs", value: "supplier_id"},
        {text: "Montant", value: "price"},
        {text: "Devise", value: "currency"},
        {text: "", value: "actions", align: "right"},

      ],
      searchCtx: {
        supplierAccountingId: null,
        maxAmount: null,
        minAmount: null,
        paymentDateStart: null,
        paymentDateStop: null,
      },
      loading: false,
      items: [],
      listSuppliers: [],
      pickerOpts: {
        class: "pt-0",
        clearable: true,
      },
      invoices: false,
      loadingInvoices: false,
      invoicesInfo: [],
      payment: {},
      pdfList: [],
    };
  },
  watch: {
    invoices: {
      immediate: true, handler(newVal, oldV) {
        if (oldV && !newVal) {
          this.payment.ref = null
        }
      },
    },
  },
  mounted() {
    this.init()
  },
  computed: {
    totalAmount() {
      let sum = 0
      const arr = this.invoicesInfo.map(i => i.amount)
      for (let i = 0; i < arr.length; i++) {
        sum += arr[i];
      }
      return sum
    }
  },
  methods: {
    async init() {
      await get("/api/accounting/list-supplier").json(
          (data) => (this.listSuppliers = data)
      );
    },
    uploaded() {
      this.listingFiles()
    },
    onError() {
    },
    closedDrawer() {
      console.log("closed")
      this.payment = null
    },
    async listingFiles() {
      if (this.payment.ref != null) {
        this.pdfList = await get(`/api/accounting/supplier-invoice-payment/${this.payment.ref}/files`).json((rs) => {
          return rs.map(r => {
            const arr = r.split("/")
            return {
              path: r,
              name: arr[arr.length - 1],
              url: `/api/file/download/supplier_invoice/payments/${this.payment.ref}/${arr[arr.length - 1]}`
            }
          })
        })
      }
    },
    getUrlUpload() {
      return `/api/accounting/supplier-invoice-payment/${this.payment.ref}/files`
    },
    search() {
      this.loading = true;
      this.items = [];
      this.selected = [];

      this.searchCtx.minAmount = this.searchCtx.minAmount || null;
      this.searchCtx.maxAmount = this.searchCtx.maxAmount || null;

      post("/api/accounting/supplier-invoice-payment/list", this.searchCtx)
          .json((data) => {
            this.items = data.map(x => {
              return {
                id: x.id,
                price: x.price,
                currency: x.currency,
                creation_date: format(parseISO(x.creation_date), "dd/MM/yyyy"),
                supplier_id: this.listSuppliers.find(supplier => supplier.id == x.supplier_id).name
              }
            });
          })
          .catch((e) => {
            EventBus.$emit("toast", {
              color: "red",
              text: "Une erreur est survenue",
              exception: e,
            });
          })
          .then(() => {
            this.loading = false;
          });
    },
    sortByDateTime(a, b) {
      const start = a != null ? a : this._formatDate(new Date(null), this.datePatternConfig.serverLocalDateTime)
      const stop = b != null ? b : this._formatDate(new Date(null), this.datePatternConfig.serverLocalDateTime)
      return this._sortDates(start, stop, this.datePatternConfig.serverLocalDateTime);
    },
    openInvoices(item) {

      this.payment = {
        date: item.creation_date,
        ref: item.id
      }
      this.loadingInvoices = true
      get(`/api/accounting/supplier-invoice-payment/${item.id}`).json((rs) => {
        this.invoicesInfo = rs
        this.listingFiles();
        this.invoices = true
        this.loadingInvoices = false
      })
    },
    toBooking(idBooking) {
      const url = `/vente/dossier/${idBooking}`
      window.open(url, "_blank");
    },
    toInvoice(id) {
      const url = `/accounting/supplier-invoice/${id}`
      window.open(url, "_blank");
    },
  },
};
</script>
