<template>
  <v-card :elevation="1"
          rounded="lg"
          class="pa-4"
          style="height:100%; width:100%">
    <span class="font-weight-bold text-lg">{{ conf.title }}</span>
    <div class="" style="height:90%;overflow-scrolling: auto; overflow:scroll">
      <v-data-table class="mt-3"
                    :items-per-page="1000"
                    :loading="items.length < 1 && loading"
                    :headers="headers"
                    :items="items"
                    search-field="destination"
                    item-key="id" hide-default-footer/>
    </div>

  </v-card>
</template>
<script>
import {EventBus} from "@/components/commons/event-bus";
import {get, post} from "@/utils/api";
//Marge moyenne par site et par destination
export default {
  name: "MarginBySiteByDestiTable",
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  data() {
    return {
      loading: false,
      items: [],
      destinations: [],
      headers: [
        {
          text: "Destination",
          value: "destination",
          align: "left",
          sortable: true,
        },
      ],
      url: `/api/widget/dashboard/avgMargin/details`,
      totaux: {}
    }
  },
  created() {
    this.init()
    EventBus.$on('searchDashboard', () => this.init());
  },
  computed: {},
  methods: {
    init() {
      this.items = []
      const searchForm = this._formatPayloadWidgetDashboard(this.conf,this.form)
      get(`/api/search/destinations`, {"Content-Type": "application/json"}).json(rs => this.destinations.push(...rs))
      post(this.url, searchForm, {"Content-Type": "application/json"})
          .json(r => {
            r.forEach((d) => {
              if (this.headers.findIndex((o) => o.text === d.name) === -1) {
                this.headers.push(
                    {
                      text: d.name,
                      value: d.name,
                      align: "left",
                      sortable: true,
                    }
                )
              }
              d.destination = this.destinations.find((desti) => desti.id === d.id).name
              if (this.items.findIndex(item => item.id === d.id) != -1) {
                this.items.find(item => item.id === d.id)[d.name] = this._formatDecimal(d.value) + " %"
              } else {
                d[d.name] = this._formatDecimal(d.value) + " %"
                this.items.push(d)
              }
            })
            let sites = this.headers.map(head => head.text)
            this.items.forEach(item => {
              sites.forEach(site => {
                if( item[site] == null){
                  item[site] = "-"
                }
              })
            })
          })
    },

  },

}
</script>