<template>
  <layout :drawer="false">
    <template slot="nav">
      <v-tabs>
        <v-tab :to="{ name: 'BookingList' }">Dossiers</v-tab>
      </v-tabs>
    </template>
    <template slot="nav-tools">
      <span v-if="booking">
        <v-toolbar
            class="pa-0 ma-0"
            height="48"
            flat
            style="background: transparent"
        >
          <div
              v-if="booking && booking.agencyId != null"
              style="width: 150px"
              class="mt-6"
          >
            <v-switch
                v-model="commissionPriceToggle"
                label="Aff. prix com."
            ></v-switch>
          </div>
          <v-btn
              class="mr-1"
              small
              outlined
              rounded
              :color="prestationColor('RoomPrestation') + ' lighten-2'"
              @click.native="openPrestationDialog('RoomPrestation', true)"
              :disabled="booking.status == 'cancelled' || blockedPrice"
          >
            <v-icon small>hotel</v-icon>
            <span class="pl-1">Hotel</span>
          </v-btn>
          <v-btn
              class="mr-1"
              small
              outlined
              rounded
              :disabled="booking.status == 'cancelled' || blockedPrice"
              :color="prestationColor('TransferPrestation') + ' lighten-2'"
              @click.native="openPrestationDialog('TransferPrestation', true)"
          >
            <v-icon small>directions_bus</v-icon>
            <span class="pl-1">{{ $t("transfer") }}</span>
          </v-btn>
          <v-btn
              class="mr-1"
              small
              outlined
              rounded
              :disabled="booking.status == 'cancelled' || blockedPrice"
              :color="prestationColor('CarrentalPrestation') + ' lighten-2'"
              @click.native="openPrestationDialog('CarrentalPrestation', true)"
          >
            <v-icon small>directions_car</v-icon>
            <span class="pl-1">{{ $t("carrental") }}</span>
          </v-btn>
          <v-btn
              class="mr-1"
              small
              outlined
              rounded
              :disabled="booking.status == 'cancelled' || blockedPrice"
              :color="prestationColor('FlightPrestation') + ' lighten-2'"
              @click.native="openPrestationDialog('FlightPrestation', true)"
          >
            <v-icon small>airplanemode_active</v-icon>
            <span class="pl-1">{{ $t("plane") }}</span>
          </v-btn>
          <v-btn
              class="mr-1"
              :color="prestationColor('Pnr') + ' lighten-2'"
              outlined
              @click.native="openPrestationDialog('Pnr', false)"
              small
              rounded
              :disabled="booking.status == 'cancelled' || blockedPrice"
          >
            <v-icon>mdi-barcode-scan</v-icon>
            <span class="pl-1">{{ $t("pnr") }}</span>
          </v-btn>
          <v-btn
              class="mr-1"
              :color="prestationColor('InsurancePrestation') + ' lighten-2'"
              outlined
              @click.native="openPrestationDialog('Insurance', false)"
              small
              rounded
              :disabled="booking.status == 'cancelled' || blockedPrice"
          >
            <v-icon>mdi-shield-account</v-icon>
            <span class="pl-1">{{ $t("insurance") }}</span>
          </v-btn>
          <v-btn
              class="mr-1"
              :color="prestationColor('UntypedPrestation') + ' lighten-2'"
              outlined
              @click.native="openPrestationDialog('Untyped', false)"
              small
              rounded
              :disabled="booking.status == 'cancelled' || blockedPrice"
          >
            <v-icon>mdi-cube-outline</v-icon>
            <span class="pl-1">{{ $t("untyped") }}</span>
          </v-btn>

          <v-divider inset vertical></v-divider>

          <v-btn
              dark
              small
              outlined
              rounded
              color="blue-grey darken-1"
              class="pl-2 pr-2 mr-1 ml-2"
              @click.native="flightInfos.dialog = true"
              v-if="!haveFlights"
          >
            <v-icon small>mdi-information</v-icon>
            <span class="pl-1">Infos Vols</span>
          </v-btn>

          <v-btn
              dark
              small
              outlined
              rounded
              color="blue-grey darken-1"
              class="pl-2 pr-2 mr-1 ml-2"
              @click.native="comment.dialog = true"
          >
            <v-icon small>mdi-comment-text</v-icon>
            <span class="pl-1">Commentaire</span>
          </v-btn>
        </v-toolbar>
      </span>
    </template>

    <template slot="content">
      <template v-if="!booking">
        <v-container fluid class="pa-3">
          <v-row class="fill-height">
            <v-col cols="12">
              <v-skeleton-loader
                  class
                  height="100%"
                  type="list-item-avatar-two-line, list-item-three-line@7"
              >
              </v-skeleton-loader>
            </v-col>
          </v-row>
        </v-container>
      </template>

      <template v-else>
        <v-toolbar
            v-if="booking.locked"
            dark
            color="red lighten-1"
            fixed
            style="padding-left: 80px"
        >
          <v-icon class="mr-2">mdi-lock-alert</v-icon>
          <span class="font-weight-bold subheading">DOSSIER VERROUILLE</span>
          <span class="ml-4">Aucun message ne part</span>
          <v-spacer></v-spacer>
          <v-btn
              color="red accent-1"
              class="elevation-0"
              @click="unlock"
              :loading="loadingUnlock"
          >
            <v-icon class="mr-2" small>mdi-lock-open-outline
            </v-icon
            >
            DEVERROUILLER
          </v-btn>
        </v-toolbar>

        <v-container fluid class="pa-3">

          <!-- <BookingLogTree :booking-id="booking.id" /> -->

          <v-row>
            <!-- ------------------- left bloc -------------------  -->
            <v-col md="9" cols="12">
              <v-row dense>
                <v-col class="col-md-6 col-sm-12">
                  <div style="margin-bottom: 8px; padding: 0" class="col-sm-12">
                    <booking-details
                        :booking="booking"
                        @regenMails="regenMails"
                        @priceWarn="showPriceWarn"
                    >
                    </booking-details>
                  </div>
                  <v-row>
                    <v-col cols="6" style="display: flex; flex-direction: column;  padding-right: 4px">
                      <collapse-panel :hidemenu="isAgency" :collapsable="false" v-if="booking" fillHeight style="gap: 8px; flex-direction: column;">
                        <div slot="title" class="d-flex" style="gap: 4px">
      <span class="caption grey--text lighten-1 font-weight-medium">
        {{ $t(booking.customerType.toLowerCase()) }}
      </span>
                          <span class="caption ml-3 grey--text lighten-1" v-if="booking?.customer?.id">
        #{{ booking.customer.id }}
      </span>
                        </div>
                        <div slot="content">
                          <div class="d-flex font-weight-medium" style="align-items: center; gap: 2px;font-size: 12px">
                            <p class="mb-0">{{ customerInfo?.firstname }}</p>
                            <p class="mb-0">{{ customerInfo?.lastname }}</p>
                          </div>
                          <div class="d-flex" style="align-items: center; gap: 4px">
                            <v-icon small>mdi-email</v-icon>
                            <p class="mb-0">{{ customerInfo?.email }}</p>
                          </div>
                          <div class="d-flex" style="align-items: center; gap: 4px">
                            <v-icon small>mdi-phone</v-icon>
                            <p class="mb-0">{{ isAgency ? customerInfo?.phone : customerInfo?.fixe }}</p>
                          </div>
                          <v-col cols="12" style="overflow-x: auto; white-space: nowrap; width: 100%; display: flex;">
                            <div style="display: flex; gap: 4px;">
                              <v-chip
                                  label
                                  flat
                                  small
                                  outlined
                                  class="blue lighten-2 blue--text text--lighten-2 mr-1"
                              >
                                {{ quotationSize }} {{ $t("quotation") }}
                              </v-chip>
                              <v-chip
                                  label
                                  flat
                                  small
                                  outlined
                                  class="blue lighten-2 blue--text text--lighten-2 mr-1"
                              >
                                {{ bookingsSize }} {{ $tc("booking", bookingsSize) }}
                              </v-chip>
                              <v-chip
                                  label
                                  flat
                                  small
                                  outlined
                                  class="blue lighten-2 blue--text text--lighten-2 mr-1"
                                  v-if="kagnots && kagnots.total != null"
                              >
                                Kagnott: {{ kagnots.total | currency("EUR") }}
                              </v-chip>
                              <v-chip
                                  label
                                  flat
                                  small
                                  outlined
                                  class="blue lighten-2 blue--text text--lighten-2 mr-1"
                                  v-if="kagnotToValidateAmount > 0"
                              >
                                Kagnott à valider: {{ kagnotToValidateAmount | currency("EUR") }}
                              </v-chip>
                              <v-chip
                                  label
                                  flat
                                  small
                                  outlined
                                  class="blue lighten-2 blue--text text--lighten-2 mr-1"
                              >
                                {{ $t("paymentterm." + booking.customer.paymentTermType) }}
                              </v-chip>
                            </div>
                          </v-col>


                          <template
                              v-if="
        booking.customer &&
        booking.customer.id &&
        booking.customer.agent &&
        isAgency
      "
                          >
                            <v-chip
                                label
                                color="grey lighten-4"
                                flat
                                small
                                class="elevation-0 mr-1"
                                v-if="regionname"
                            >{{ regionname }}
                            </v-chip
                            >
                            <v-chip
                                label
                                color="grey lighten-4"
                                flat
                                small
                                class="elevation-0 mr-1"
                                v-if="networksname && networksname.length > 0"
                                v-for="(network, index) in networksname"
                                :key="index"
                            >{{ network }}
                            </v-chip
                            >
                            <v-chip
                                label
                                color="grey lighten-4"
                                flat
                                small
                                class="elevation-0 mr-1"
                                v-if="commercialname"
                            >
                              {{ commercialname }}
                            </v-chip>
                            <v-chip
                                label
                                flat
                                small
                                outlined
                                class="blue lighten-2 blue--text text--lighten-2 mr-1"
                            >
                              Source: {{ $t(booking.context.source) }}
                            </v-chip>
                          </template>
                        </div>
                        <div slot="menu">
                          <v-list-item
                              :to="{ name: 'customer-edition', params: { id: booking.customer.id } }"
                          >
                            <v-list-item-title>
                              <v-icon small>mdi-account-search</v-icon>
                              Voir le compte client
                            </v-list-item-title>
                          </v-list-item>
                        </div>
                      </collapse-panel>
                    </v-col>

                    <v-col cols="6" style="display: flex; flex-direction: column; padding-left: 4px">
                      <div style="padding: 0; flex: 1;">
                        <booking-customer-details
                            :booking="booking"
                            :kagnots="kagnots"
                        ></booking-customer-details>
                      </div>
                    </v-col>
                  </v-row>

                </v-col>
                <v-col class="col-md-6 col-sm-12">
                  <booking-payments
                      :booking="booking"
                      :kagnots="kagnots"
                  ></booking-payments>
                </v-col>
              </v-row>
              <v-row v-if="booking.externalRef">
                <v-col cols="12" class="text-center">
                  <v-alert color="red" outlined text type="warning">
                    <div>Ce dossier a été reservé par un service externe.</div>
                    <div>
                      Pour toute modification de prestation, il faut que le
                      client passe par ce service.
                    </div>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row dense class="mt-3 mb-2" v-if="needInsurrance">
                <v-col class="mb-0" cols="12">
                  <v-alert text type="error" class="mb-0">
                    Une assistance rapatriement est obligatoire
                  </v-alert>
                </v-col>
              </v-row>

              <v-row dense v-if="blockedPrice" class="mt-3 mb-2">
                <v-col class="mb-0" cols="12">
                  <v-alert text class="mb-0 orange darken-1 white--text " icon="$warning">
                    <div class="d-flex justify-space-between">
                      <div class="d-flex flex-column">
                      <span>Les prix des prestations sont bloqués, ils ne seront pas mis à jour jusqu'au {{
                          booking.blockPriceDate.substring(0, 16)
                        }}</span>
                        <span
                            class="caption">Pour modifier ou ajouter une prestation, merci de débloquer les prix</span>
                      </div>
                      <div class="d-flex">
                        <smart-btn small color="white" class="mr-1"
                            @click.native="blockPriceDialog.show = true">
                          <v-icon small>mdi-lock-open</v-icon>
                          Modifier la date limite
                        </smart-btn>
                        <smart-btn small
                                   color="white"
                            @click.native="unblockPrices()">
                          <v-icon small>mdi-lock-open</v-icon>
                          Débloquer les prix
                        </smart-btn>
                      </div>
                    </div>
                  </v-alert>
                </v-col>
              </v-row>

              <v-row dense v-if="priceWarn.show" class="mt-3 mb-2">
                <v-col class="mb-0" cols="12">
                  <v-alert text type="info" class="mb-0">
                    Les prestations suivantes ont changé:
                    <ul>
                      <li v-for="p in priceWarn.badPrestations" :key="p.id">
                        [{{ p.type }}] {{ p.name }}:
                        <span>
                          {{ p.newPrice | currency(booking.currency) }} ({{
                            (p.newPrice - p.oldPrice)
                                | currency(booking.currency, {vary: true})
                          }})
                        </span>
                      </li>
                    </ul>
                    Veuillez vérifier les prix.
                  </v-alert>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="prestations">
                  <div
                      v-for="(prestation, index) in prestations"
                      :key="prestation.kind + prestation.id + '#' + index"
                  >
                    <template v-if="prestation.kind === 'RoomPrestation'">
                      <prestation-hotel
                          :booking="booking"
                          :prestation="prestation"
                          :index="index"
                      ></prestation-hotel>
                    </template>
                    <template
                        v-else-if="prestation.kind === 'TransferPrestation'"
                    >
                      <prestation-transfer
                          :booking="booking"
                          :prestation="prestation"
                          :index="index"
                          :prestationDialogCtx="addPrestationDialog"
                      ></prestation-transfer>
                    </template>
                    <template
                        v-else-if="prestation.kind === 'FlightPrestation'"
                    >
                      <prestation-flight
                          :booking="booking"
                          :prestation="prestation"
                          :index="index"
                      ></prestation-flight>
                    </template>
                    <template
                        v-else-if="prestation.kind === 'CarrentalPrestation'"
                    >
                      <prestation-carrental
                          :booking="booking"
                          :prestation="prestation"
                          :index="index"
                      >
                      </prestation-carrental>
                    </template>
                    <template
                        v-else-if="prestation.kind === 'InsurancePrestation'"
                    >
                      <prestation-insurance
                          :booking="booking"
                          :prestation="prestation"
                          :index="index"
                      >
                      </prestation-insurance>
                    </template>
                    <template
                        v-else-if="prestation.kind === 'UntypedPrestation'"
                    >
                      <prestation-untyped
                          :booking="booking"
                          :prestation="prestation"
                          :index="index"
                      >
                      </prestation-untyped>
                    </template>
                  </div>
                </v-col>
                <v-col cols="12">
                  <customer-flights-infos
                      :additional-infos="booking.additionalInfos"
                  ></customer-flights-infos>
                </v-col>

                <!-- ------------------- dialogs -------------------  -->
                <smart-dialog
                    v-model="addPrestationDialog.dialog"
                    :title="addPrestationDialog.title"
                    :fullscreen="addPrestationDialog.fullscreen"
                    :hide-overlay="false"
                    :persistent="true"
                    transition="dialog-bottom-transition"
                    card-class="grey lighten-5"
                    style="position: relative"
                >
                  <template
                      v-if="
                      addPrestationDialog.prestationEdited == 'RoomPrestation'
                    "
                  >
                    <add-hotel
                        :booking="booking"
                        :lastPrestationDate="addPrestationDialog.dateStart"
                        :sumPrestation="addPrestationRecap"
                    ></add-hotel>
                  </template>
                  <template
                      v-else-if="
                      addPrestationDialog.prestationEdited ==
                      'TransferPrestation'
                    "
                  >
                    <add-transfer
                        :booking="booking"
                        :lastPrestationDate="addPrestationDialog.dateStart"
                        :sumPrestation="addPrestationRecap"
                    ></add-transfer>
                  </template>
                  <template
                      v-else-if="
                      addPrestationDialog.prestationEdited ==
                      'CarrentalPrestation'
                    "
                  >
                    <add-carrental
                        :booking="booking"
                        :lastPrestationDate="addPrestationDialog.dateStart"
                        :sumPrestation="addPrestationRecap"
                    ></add-carrental>
                  </template>
                  <template
                      v-else-if="
                      addPrestationDialog.prestationEdited == 'FlightPrestation'
                    "
                  >
                    <add-flight-v2
                        :booking="booking"
                        :lastPrestationDate="addPrestationDialog.dateStart"
                        :sumPrestation="addPrestationRecap"
                    ></add-flight-v2>
                  </template>
                  <template
                      v-else-if="addPrestationDialog.prestationEdited == 'Pnr'"
                  >
                    <add-pnr :booking="booking"></add-pnr>
                  </template>
                  <template
                      v-else-if="
                      addPrestationDialog.prestationEdited == 'Insurance'
                    "
                  >
                    <add-insurance
                        v-if="addPrestationDialog.dialog"
                        :booking="booking"
                    ></add-insurance>
                  </template>

                  <template
                      v-else-if="
                      addPrestationDialog.prestationEdited == 'Untyped'
                    "
                  >
                    <add-untyped :booking="booking"></add-untyped>
                  </template>
                  <template v-else>
                    <div class="text-center">not yet implemented</div>
                  </template>

                  <v-btn
                      v-if="addPrestationDialog.prestationEdited != 'Pnr'"
                      dark
                      icon
                      absolute
                      top
                      right
                      style="top: 6px; right: 70px"
                      @click="addPrestationRecap = !addPrestationRecap"
                  >
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </smart-dialog>
                <!-- ------------------- dialogs -------------------  -->
              </v-row>
            </v-col>

            <!-- ------------------- right bloc -------------------  -->
            <v-col md="3" cols="12">

              <booking-comment
                  class="mb-2"
                  :booking-id="booking.id"
                  :agencyComment="
                  booking.additionalInfos.filter((i) => i.item == 'comments')
                "
                  :comments="booking.comments"
              ></booking-comment>

              <v-card
                  flat
                  class="pa-0 pt-1 ma-0"
                  style="background: transparent"
              >
                <v-row>
                  <v-col class="communicationTitle">
                    <v-row
                        dense
                        class="mb-3"
                        align="center"
                        @click="
                        nbActions > 0
                          ? (actionCollapse = !actionCollapse)
                          : null
                      "
                    >
                      <v-col>
                        <b>Actions</b>
                      </v-col>
                      <v-col cols="1">
                        <v-avatar
                            class="white--text"
                            size="25"
                            color="primary"
                        >
                          {{ nbActions ? nbActions : 0 }}
                        </v-avatar>
                      </v-col>
                      <v-col cols="1" class="text-center">
                        <v-icon v-show="nbActions > 0">{{
                            actionCollapse ? "mdi-chevron-down" : "mdi-chevron-up"
                          }}
                        </v-icon>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>

                    <v-card-text
                        class="communicationContent"
                        v-show="actionCollapse"
                    >
                      <v-text-field
                          v-model="emailDialog.search"
                          label="Destinataire"
                      ></v-text-field>
                      <booking-tasks
                          :booking="booking"
                          v-show="!loading && actionCollapse"
                          @actionsloaded="actionCount"
                      />
                    </v-card-text>
                    <v-divider v-if="actionCollapse"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="communicationTitle">
                    <v-row
                        dense
                        class="mb-3"
                        align="center"
                        @click="
                        nbMails > 0 ? (mailCollapse = !mailCollapse) : null
                      "
                    >
                      <v-col>
                        <b>Emails</b>
                      </v-col>
                      <v-col cols="1">
                        <v-avatar
                            class="white--text"
                            size="25"
                            color="primary"
                        >{{ nbMails ? nbMails : 0 }}
                        </v-avatar>
                      </v-col>
                      <v-col cols="1" class="text-center">
                        <v-icon v-show="nbMails > 0">{{
                            mailCollapse ? "mdi-chevron-down" : "mdi-chevron-up"
                          }}
                        </v-icon>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>

                    <v-card-text
                        class="communicationContent"
                        v-show="mailCollapse"
                    >
                      <v-text-field
                          class="ma-1"
                          v-model="emailDialog.search"
                          label="Destinataire"
                      ></v-text-field>
                      <emails-resume-listing
                          :searchQuery="emailDialog.search"
                          :booking="booking"
                          v-if="!loading"
                          @mailsloaded="mailCount"
                      />
                    </v-card-text>
                    <v-divider v-if="mailCollapse"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="communicationTitle">
                    <v-row
                        dense
                        class="mb-3"
                        align="center"
                        @click="
                        showLogsCollapseIcon
                          ? (logsCollapse = !logsCollapse)
                          : (logsCollapse = false)
                      "
                    >
                      <v-col>
                        <b>Logs</b>
                      </v-col>
                      <v-col cols="1" class="text-center">
                        <v-icon v-show="nbMails > 0">{{
                            logsCollapse ? "mdi-chevron-down" : "mdi-chevron-up"
                          }}
                        </v-icon>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>

                    <v-card-text
                        class="communicationContent"
                        v-show="logsCollapse"
                    >
                      <booking-logs
                          :bookingid="booking.id"
                          v-if="!loading"
                          @dialog="openLog"
                          @logsloaded="logCount"
                      />
                    </v-card-text>
                    <v-divider v-if="logsCollapse"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="communicationTitle">
                    <v-row
                        dense
                        class="mb-3"
                        align="center"
                        @click="recapCollapse = !recapCollapse"
                    >
                      <v-col>
                        <b>Récap</b>
                      </v-col>
                      <v-col cols="1" class="text-center">
                        <v-icon v-show="nbMails > 0">{{
                            recapCollapse ? "mdi-chevron-down" : "mdi-chevron-up"
                          }}
                        </v-icon>
                      </v-col>
                    </v-row>
                    <v-divider/>

                    <v-card-text
                        class="communicationContent"
                        v-show="recapCollapse"
                    >
                      <v-text-field
                          v-model="emailDialog.search"
                          label="Destinataire"
                      ></v-text-field>
                      <booking-drawer-content
                          :booking="booking"
                          v-if="!loading"
                      />
                    </v-card-text>
                    <v-divider v-if="recapCollapse"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="communicationTitle">
                    <v-row
                        dense
                        class="mb-3"
                        align="center"
                        @click="invoiceCollapse = !invoiceCollapse"
                    >
                      <v-col>
                        <b>Commentaires factures</b>
                      </v-col>
                      <v-col cols="1">
                        <v-avatar
                            class="white--text"
                            size="25"
                            color="primary"
                        >{{ invoicesComment.length }}
                        </v-avatar>
                      </v-col>
                      <v-col cols="1" class="text-center">
                        <v-icon v-show="invoicesComment.length > 0">{{
                            invoiceCollapse ? "mdi-chevron-down" : "mdi-chevron-up"
                          }}
                        </v-icon>
                      </v-col>
                    </v-row>
                    <v-divider/>
                    <v-card-text
                        class="communicationContent"
                        v-show="invoiceCollapse"
                    >

                      <v-col cols="12" v-for="ic in invoicesComment" :key="ic.id">
                        <div class="text-caption">
                          <v-chip :color="ic.status == 'paid' ? 'green':''"
                                  :text-color="ic.status == 'paid' ? 'white':''" x-small>{{ ic.status }}
                          </v-chip>
                          {{ ic.paymentDate != null ? `Le ${ic.paymentDate}` : '' }}
                        </div>

                        {{ ic.comment }}
                      </v-col>
                      <v-divider v-if="invoiceCollapse"></v-divider>
                    </v-card-text>
                  </v-col>
                </v-row>

              </v-card>

              <!-- Fin de refonte -->
            </v-col>

            <span v-if="booking" id="loaded"></span>
            <confirm ref="priceClassAlert" :lazy="false"></confirm>
            <smart-dialog
                title="Ajouter un commentaire"
                v-model="comment.dialog"
                :width="800"
            >
              <smart-editor v-model="comment.content"/>
              <template slot="actions">
                <smart-btn primary @click.native="createComment"
                >Enregistrer
                </smart-btn
                >
              </template>
            </smart-dialog>

            <smart-dialog
                title="Informations Vols"
                v-model="flightInfos.dialog"
            >
              <v-row dense>
                <v-col cols="6" class="pl-2">
                  <h3 class="ml-2">Vol aller</h3>
                  <v-row dense>
                    <v-col cols="6" class="px-1">
                      <v-text-field
                          v-model="flightInfos.arrival.name"
                          label="Arrivée à"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="px-1">
                      <v-text-field
                          v-model="flightInfos.arrival.flightNumber"
                          label="Numéro de vol"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="px-1">
                      <smart-picker
                          v-model="flightInfos.arrival.date"
                          format="yyyy-MM-dd"
                          :menuOpts="{}"
                          :inputOpts="{ label: 'Date' }"
                      ></smart-picker>
                    </v-col>
                    <v-col cols="2" class="px-1">
                      <v-text-field
                          v-model="flightInfos.arrival.hour"
                          type="number"
                          max="2"
                          label="Heure"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="2" class="px-1">
                      <v-text-field
                          v-model="flightInfos.arrival.minute"
                          type="number"
                          max="2"
                          label="Minute"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="6" class="pl-2">
                  <h3 class="ml-2">Vol retour</h3>
                  <v-row dense>
                    <v-col cols="6" class="px-1">
                      <v-text-field
                          v-model="flightInfos.departure.name"
                          label="Départ de"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="px-1">
                      <v-text-field
                          v-model="flightInfos.departure.flightNumber"
                          label="Numéro de vol"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="px-1">
                      <smart-picker
                          v-model="flightInfos.departure.date"
                          format="yyyy-MM-dd"
                          :menuOpts="{}"
                          :inputOpts="{ label: 'Date' }"
                      ></smart-picker>
                    </v-col>
                    <v-col cols="2" class="px-1">
                      <v-text-field
                          v-model="flightInfos.departure.hour"
                          type="number"
                          max="2"
                          label="Heure"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="2" class="px-1">
                      <v-text-field
                          v-model="flightInfos.departure.minute"
                          type="number"
                          max="2"
                          label="Minute"
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <template slot="actions">
                <smart-btn primary @click.native="saveFlightInfos"
                >Enregistrer
                </smart-btn
                >
              </template>
            </smart-dialog>
            <smart-dialog title="Informations Log" v-model="logDialog.open">
              <v-row class="ma-3">
                <v-col cols="12">
                  <v-row>
                    <span class="subheading">{{ logDialog.log.message }}</span>
                  </v-row>
                  <v-row class="mb-2">
                    <span class="body-2 grey--text"
                    >{{ logDialog.log.userName }} à
                      {{ logDialog.log.creationDate }}</span
                    >
                  </v-row>
                  <v-row>
                    <p>
                      <b class="logInfoTitle body-2">Détails:</b>
                      <span class="grey--text">{{
                          logDialog.log.eventType
                        }}</span>
                    </p>
                  </v-row>
                  <v-card>
                    <v-card-text>
                      <vue-json-pretty
                          :path="JSON.stringify(logDialog.log.details)"
                          :data="logDialog.log.details"
                      >
                      </vue-json-pretty>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </smart-dialog>
            <smart-dialog title="PNR Mis à jour" v-model="updatePnrDialog.open" v-if="updatePnrDialog.open">
              <div>
                <div class="mt-3 ml-3">Nouveaux horaires de vol !</div>
                <v-row dense class="ml-4 my-3">
                  <v-col cols="2" class="d-flex flex-column ml-auto"></v-col>
                  <v-col cols="4" class="d-flex flex-column ml-auto font-weight-medium">Horaires d'origine</v-col>
                  <v-col cols="1" class="d-flex flex-column"></v-col>
                  <v-col cols="5" class="d-flex flex-column font-weight-medium">Horaires mis à jour</v-col>
                </v-row>
                <v-row dense class="ml-4 my-3" v-for="(updatedPnr, index) in updatePnrDialog.changed" :key="index">
                  <v-col cols="2" class="d-flex flex-column ml-auto">
                    <span>Départ :</span>
                    <span>Arrivée:  </span>
                  </v-col>
                  <v-col cols="4" class="d-flex flex-column ml-auto font-weight-medium">
                    <span>{{ _formatDate(new Date(updatedPnr.old_departure), "'Le 'EEE dd MMM yyyy 'à' HH:mm") }}</span>
                    <span>{{ _formatDate(new Date(updatedPnr.old_arrival), "'Le 'EEE dd MMM yyyy 'à' HH:mm") }}</span>
                  </v-col>
                  <v-col cols="1" class="d-flex flex-column">
                    <v-icon>arrow_right</v-icon>
                    <v-icon>arrow_right</v-icon>

                  </v-col>
                  <v-col cols="5" class="d-flex flex-column font-weight-medium">
                    <span>{{ _formatDate(new Date(updatedPnr.new_departure), "'Le 'EEE dd MMM yyyy 'à' HH:mm") }}</span>
                    <span>{{ _formatDate(new Date(updatedPnr.new_arrival), "'Le 'EEE dd MMM yyyy 'à' HH:mm") }}</span>
                  </v-col>
                </v-row>
              </div>
              <v-divider></v-divider>
              <div class="pa-2 d-flex flex-row justify-space-between align-center">
                Qui est à l'origine de cette mise à jour ?
                <smart-btn @click.native="saveLog(true)">Compagnie aérienne</smart-btn>
                <smart-btn @click.native="saveLog(false)">Agent Hotelissima</smart-btn>
              </div>
            </smart-dialog>
            <smart-dialog
                :title="booking.blockPriceDate ? 'Modifier le blocage des prix' : 'Blocage des prix'"
                v-model="blockPriceDialog.show"
                :width="500"
                @close="loading = false"
            >
              <v-form v-model="blockPriceDialog.form.valid" ref="blockPrice" v-on:submit.prevent="blockPrices">
                <div class="pa-3">
                  <smart-picker
                      class="mx-2 mt-2"
                      label="Date d'expiration"
                      v-model="blockPriceDialog.form.date"
                      :inputOpts="{
                    rules: rules.nonEmpty('Date de blocage obligatoire'),
                  }"
                  >
                  </smart-picker>
                  <div class="d-flex mt-4">
                    <v-text-field dense type="number" class="mr-3"
                                  v-model="blockPriceDialog.form.hours"
                                  label="Heure"/>
                    <v-text-field dense type="number"
                                  v-model="blockPriceDialog.form.minutes"
                                  label="Minute"/>
                  </div>
                </div>

                <v-card-actions class="actions-btn">
                  <v-spacer/>
                  <smart-btn primary type="submit" :loading="blockPriceDialog.loading">{{
                      $t("save")
                    }}
                  </smart-btn>
                </v-card-actions>
              </v-form>
            </smart-dialog>
            <lock-mails
                :bookingid="booking.id"
                v-model="emailDialog.open"
                @regenMails="regenMails"
                @loadmails="setMails"
            ></lock-mails>
          </v-row>
        </v-container>
      </template>
    </template>
  </layout>
</template>

<script>
import PrestationHotel from "@/components/booking/PrestationHotel";
import PrestationFlight from "@/components/booking/PrestationFlight";
import PrestationTransfer from "@/components/booking/PrestationTransfer";
import PrestationCarrental from "@/components/booking/PrestationCarrental";
import PrestationUntyped from "@/components/booking/PrestationUntyped";

import BookingDetails from "@/components/booking/BookingDetails";
import BookingPayments from "@/components/booking/BookingPayments";

import AddHotel from "@/components/booking/hotel/AddHotel";
import AddTransfer from "@/components/booking/transfer/AddTransfer";
import AddCarrental from "@/components/booking/carrental/AddCarrental";
import AddFlight from "@/components/booking/flight/AddFlight";
import AddFlightV2 from "@/components/booking/flight/AddFlightV2";
import AddPnr from "@/components/booking/flight/AddPnr";
import AddUntyped from "@/components/booking/untyped/AddUntyped";

import {EventBus} from "@/components/commons/event-bus";
import EmailsResumeListing from "../../components/commons/EmailsResumeListing";
import BookingDrawerContent from "@/components/commons/BookingDrawerContent";
import Confirm from "@/components/commons/Confirm";
import {get, post} from "@/utils/api";

import Layout from "@/views/Layout";
import ColorMixin from "@/components/mixins/ColorMixin";
import FlightsMixin from "@/components/mixins/FlightsMixin";
import BookingLogs from "@/components/booking/BookingLogs2";
import BookingCustomerDetails from "../../components/booking/BookingCustomerDetails";
import BookingTasks from "@/components/booking/BookingTasks";
import CustomerFlightsInfos from "../../components/booking/CustomerFlightsInfos";
import BookingComment from "../../components/booking/BookingComment";
import LockMails from "../../components/commons/LockMails";
import AddInsurance from "../../components/booking/insurance/AddInsurance";
import PrestationInsurance from "../../components/booking/PrestationInsurance";
import VueJsonPretty from "vue-json-pretty";
import SmartEditor from "../../components/commons/SmartEditor";

import _ from "lodash";
import 'vue-json-pretty/lib/styles.css';
import FormMixin from "@/components/mixins/FormMixin";
import CollapsePanel from "@/components/commons/CollapsePanel.vue";

export default {
  props: ["id"],
  components: {
    CollapsePanel,
    SmartEditor,
    VueJsonPretty,
    PrestationInsurance,
    AddInsurance,
    LockMails,
    BookingComment,
    CustomerFlightsInfos,
    BookingCustomerDetails,
    BookingLogs,
    Layout,
    EmailsResumeListing,
    BookingDrawerContent,
    AddFlight,
    AddFlightV2,
    AddPnr,
    AddCarrental,
    AddTransfer,
    AddHotel,
    AddUntyped,
    BookingPayments,
    BookingDetails,
    PrestationHotel,
    PrestationFlight,
    PrestationCarrental,
    PrestationTransfer,
    PrestationUntyped,
    BookingTasks,
    Confirm,
  },
  mixins: [ColorMixin, FlightsMixin, FormMixin],
  data() {
    return {
      updatePnrDialog: {open: false},
      reportdialog: false,
      activeTab: null,
      loading: false,
      addPrestationDialog: {
        title: "",
        dialog: false,
        fullscreen: true,
        prestationEdited: null,
        dateStart: "",
      },
      kagnots: {
        total: 0,
      },
      comment: {
        dialog: false,
        content: null,
      },
      flightInfos: {
        dialog: false,
        arrival: {
          hour: null,
          minute: null,
          flightNumber: null,
          date: null,
          name: null,
        },
        departure: {
          hour: null,
          minute: null,
          flightNumber: null,
          date: null,
          name: null,
        },
      },
      emailDialog: {
        open: false,
        emails: [],
        search: "",
      },
      blockPriceDialog: {
        loading:false,
        show: false,
        form: {
          hours: 0,
          minutes: 0,
          date: null,
          valid: true
        }
      },
      logDialog: {
        open: false,
        log: {},
      },
      showLogsCollapseIcon: false,
      addPrestationRecap: true,
      booking: null,
      kagnot: 0,
      prestations: [],
      oldPrestationsGroup: [],
      oldPrestations: [],
      tabHeaderColor: "blue-grey lighten-4",
      tabColor: "blue-grey lighten-5",
      actionCollapse: false,
      mailCollapse: false,
      logsCollapse: false,
      recapCollapse: false,
      invoiceCollapse: false,
      nbActions: 0,
      nbMails: 0,
      loadingUnlock: false,

      commissionPriceToggle: false,

      priceWarn: {
        show: false,
        badPrestations: [],
      },
      invoicesComment: [],
      customerInfo:null,
      quotationSize: null,
      bookingsSize: null,
      kagnotToValidateAmount: 0,
      regionname: null,
      networksname: null,
      commercialname: null,
      showTooltip:false
    };
  },
  created() {
    this.loadBooking();
    this.loadCustomerBookingInfo();

    this.$nextTick(() => {
      EventBus.$on("reloadbooking", () => {
        this.loadBooking();
      });

      EventBus.$on("prestation-added", () => {
        this.addPrestationDialog.dialog = false;
      });

      EventBus.$on("create-presta", (msg) => {
        this.openPrestationDialog(msg.kind, true, msg.date);
      });

      EventBus.$on("open-guest-prestation", (msg) => {
        this.$refs.bookingGuestAttribution.open(
            msg.prestation,
            msg.isPrestationGroup
        );
      });

      EventBus.$on("open-lock-mails", () => {
        this.emailDialog.open = true;
      });

      EventBus.$on("pnr-updated", (data) => {
        this.updatePnrDialog = {...data, open: true}

      })

      EventBus.$on("flightadded", () => {
        //price class control
        //Missing checkPriceClass()
        //if (this.checkPriceClass().hasFlight && !this.checkPriceClass().flightPriceClass) {
        //	let title = "Changement de classe de Prix"
        //	let msg = `Vous utilisez la classe de prix ${this.booking.context.priceClassType.name} alors que vous venez d'ajouter des vols au dossier.`
        //	let option = {width: 650}
        //	this.$root.$confirm(title, msg, option)
        //}
      });
      EventBus.$on("invoices-ready", (payload) => {
        this.updateCommentInvoices(payload)
      })
    });
  },
  destroyed: function () {
    EventBus.$off("reloadbooking");
    EventBus.$off("prestation-added");
    EventBus.$off("transfer-added");
    EventBus.$off("toggle-booking-summary");
    EventBus.$off("invoices-ready");
  },
  watch: {
    commissionPriceToggle: {
      handler(newVal) {
        EventBus.$emit("toggleCommissionPrice", newVal);
      },
    },
    "booking.customer.id"(newVal) {
      this.loadCustomerBookingInfo();
    },
  },
  computed: {
    haveFlights() {
      return (
          this.booking.prestations.filter((p) => p.kind == "FlightPrestation")
              .length > 0
      );
    },
    needInsurrance() {
      return this.booking?.context?.priceClassType != null && this.isPriceClassWithFlight(this.booking.context.priceClassType) &&
          this.prestations.filter((p) => p.formulaId === 40738 || p.insurranceId === 79921).length === 0
    },
    blockedPrice() {
      return this.booking?.blockPriceDate != null && this._isBefore(new Date(), this._parseDate(this.booking.blockPriceDate, "dd/MM/yyyy HH:mm:ss"))
    },
    isAgency() {
      return this.booking.customerType === "agency";
    },
  },
  methods: {

    setDialogTitle(prestaKind) {
      let title = "";
      switch (prestaKind) {
        case "RoomPrestation":
          title = "Ajout Hotel";
          break;
        case "TransferPrestation":
          title = this.$t("add-transfer");
          break;
        case "CarrentalPrestation":
          title = this.$t("add-carrental");
          break;
        case "FlightPrestation":
          title = this.$t("add-flight");
          break;
        case "Pnr":
          title = this.$t("add-flight");
          break;
        case "Insurance":
          title = this.$t("add-insurance");
          break;
        case "Untyped":
          title = this.$t("add-untyped");
          break;
      }

      this.addPrestationDialog.title = title;
    },
    loadBooking() {
      this.loading = !this.loading;
      const url = `/api/booking/${this.id}`;
      get(url).json((r) => {
        this.loading = !this.loading;
        this.booking = r.data;

        if (this.booking.prestations) {
          var prestations = this.booking.prestations.map((prestation) => {
            if (prestation.oldPrestationGroupId !== void 0) {
              prestation.isReplaced =
                  this.booking.prestations.find((p) => {
                    return (
                        p.oldPrestationGroupId === prestation.prestationGroupId
                    );
                  }) !== void 0;
            } else {
              prestation.isReplaced =
                  this.booking.prestations.find((p) => {
                    return p.oldPrestationId === prestation.id;
                  }) !== void 0;
            }
            return prestation;
          });

          this.prestations = prestations
              .filter((p) => !p.isReplaced)
              .map((p) => {
                return this.remapPrestation(p, this.booking.prestations);
              });
        }

        if (this.booking.customerId) {
          get(`/api/customer/${this.booking.customerId}/kagnot`).json(
              (data) => (this.kagnots = data)
          );
        }
        this.loadBlockPriceDate()
        this.loadAdditionalInfos();
        this.loadInfoAccount()
      });
    },
    loadInfoAccount(){
      if(this.booking.customer != null && (this.booking.customer.id != null ||(this.booking.customer.agent != null && this.booking.customer.agent.id != null))){
      const url = !this.isAgency ? `/api/customer/${this.booking.customer.id}` : `/api/agency/agent/${this.booking.customer.agent.id}`
      get(url).json((result) =>{
        this.customerInfo = result
      })
      } else return null;
    },
    loadCustomerBookingInfo() {
      if (this.booking && this.booking.customer.id) {
        const url = this.isAgency
            ? `/api/agency/${this.booking.customer.id}/bookinginfos`
            : `/api/customer/bookinginfos/${this.booking.customer.id}`;

        get(url).json((r) => {
          this.quotationSize = r.quotationsize;
          this.bookingsSize = r.bookingssize;
          this.regionname = r.regionname;
          this.networksname = r.networksname;
          this.commercialname = r.commercialname;
        });
        if (this.booking.customerId) {

          get(`/api/customer/${this.booking.customerId}/kagnottovalidate`).json(
              (data) => (this.kagnotToValidateAmount = data.kagnot)
          );
        }

      }
    },
    loadBlockPriceDate() {
      const formDateTime = this.booking.paymentOrders.length > 0 ? this.booking.paymentOrders[0].expirationDate : null
      let limitationBlockDate = formDateTime ? formDateTime : this._formatDate(this._addDays(new Date(), 1), "dd/MM/yyyy")
      this.blockPriceDialog.form.date = this.booking.blockPriceDate && this.booking.paymentOrders.length == 0 ? this.booking.blockPriceDate.substring(0, 10) : limitationBlockDate.split(" ")[0]
      this.blockPriceDialog.form.hours = this.booking.blockPriceDate && this.booking.paymentOrders.length == 0 ? parseInt(this.booking.blockPriceDate.substring(11, 13)) : parseInt(limitationBlockDate.substring(11, 13))
      this.blockPriceDialog.form.minutes = this.booking.blockPriceDate && this.booking.paymentOrders.length == 0 ? parseInt(this.booking.blockPriceDate.substring(14, 16)) : parseInt(limitationBlockDate.substring(14, 16))
    },
    remapPrestation(prestation, prestations) {
      if (prestation.oldPrestationGroupId !== void 0) {
        const old = prestations.find(
            (p) => p.prestationGroupId === prestation.oldPrestationGroupId
        );
        if (old) {
          prestation.oldPrestationGroup = this.remapPrestation(
              old,
              prestations
          );
        }
      } else if (prestation.oldPrestationId !== void 0) {
        const old = prestations.find(
            (p) => p.id === prestation.oldPrestationId
        );
        if (old) {
          prestation.oldPrestation = this.remapPrestation(old, prestations);
        }
      }
      return prestation;
    },
    unlock() {
      this.loadingUnlock = true;
      post(`/api/booking/${this.booking.id}/unlock`)
          .res(() => {
            return;
          })
          .then(() => {
            EventBus.$emit("reloadbooking", {});
            this.loadingUnlock = false;
            //suite à la demande de lucile
            this.emailDialog.open = true;
          });
    },
    openPrestationDialog(prestaKind, fullscreen, date) {
      this.setDialogTitle(prestaKind);
      this.addPrestationDialog.prestationEdited = prestaKind;
      this.addPrestationDialog.dialog = true;
      this.addPrestationDialog.fullscreen = fullscreen;
      this.addPrestationDialog.dateStart = date;
    },

    createComment() {
      const url = `/api/booking/${this.booking.id}/comment/create`;
      const comment = {
        userId: null,
        creationDate: null,
        bookingId: this.booking.id,
        message: this.comment.content,
        star: false,
      };
      post(url, comment)
          .res((r) => {
            EventBus.$emit("reloadbooking", {});
            return r;
          })
          .then(() => {
            this.comment.dialog = false;
            this.comment.content = "";
          });
    },

    loadAdditionalInfos() {
      if (this.booking && this.booking.additionalInfos) {
        const info = this.booking.additionalInfos.find(
            (i) => i.item === "flightinfos"
        );

        if (info) {
          this.flightInfos = {
            ...this.flightInfos,
            ...JSON.parse(info.value),
            ...{id: info.id},
          };
        }
      }
    },
    saveFlightInfos() {
      const url = this.flightInfos.id
          ? `/api/booking/${this.booking.id}/editadditionalinfos/${this.flightInfos.id}`
          : `/api/booking/${this.booking.id}/addadditionalinfos`;

      const obj = JSON.stringify({
        departure: this.flightInfos.departure,
        arrival: this.flightInfos.arrival,
      });

      const infos = {
        item: "flightinfos",
        value: obj,
      };
      post(url, infos)
          .res((r) => {
            EventBus.$emit("reloadbooking", {});
            return r;
          })
          .then(() => {
            this.flightInfos.dialog = false;
          });
    },
    openLog(value) {
      this.logDialog.open = true;
      this.logDialog.log = value;
    },
    actionCount(count) {
      this.nbActions = count;

      if (this.nbActions >= 1) {
        this.actionCollapse = true;
      }
    },
    mailCount(count) {
      this.nbMails = count.count;
    },
    logCount(count) {
      if (count > 0) {
        this.showLogsCollapseIcon = true;
      } else {
        this.showLogsCollapseIcon = false;
      }
    },
    setMails(value) {
      if (value.mails) {
        return (this.emailDialog.emails = value.mails);
      } else return;
    },
    regenMails(value) {
      const {mailType, url} =
          value.mailType == "supplier"
              ? {
                mailType: "fournisseurs",
                url: `/api/booking/${this.booking.id}/resendsupplier`,
              }
              : {
                mailType: "client",
                url: `/api/booking/${this.booking.id}/resendcustomer/confirmed`,
              };

      return get(url)
          .badRequest((err) => {
            EventBus.$emit("toaster-msg", err.toString());
          })
          .res()
          .then(() => {
            if (value.reload) {
              setTimeout(() => {
                value.callback().then(() => {
                  EventBus.$emit(
                      "toaster-msg",
                      "Mails " + mailType + " renvoyés"
                  );
                });
              }, 10000);
            } else {
              EventBus.$emit("reloadbooking", {});
              EventBus.$emit("toaster-msg", "Mails " + mailType + " renvoyés");
            }
          });
    },
    showPriceWarn(badPrestations) {
      this.priceWarn.show = true;
      this.priceWarn.badPrestations = badPrestations;
    },
    updateCommentInvoices(invoices) {
      this.invoicesComment = _.uniqBy([...this.invoicesComment, ...invoices].filter(ic => ic.comment !== ''), 'id')
    },
    saveLog(isByAirline) {
      const data = this.updatePnrDialog.changed
      data.forEach(d => d.edit_by_company = isByAirline)
      const url = "/api/prestation/updated/pnr"
      post(url, data).badRequest((err) => {
        EventBus.$emit("toaster-msg", err.toString());
      })
          .res()
          .then(() => {
            EventBus.$emit("toaster-msg", "Action enregistrée");
            this.updatePnrDialog.open = false
          })
    },
    blockPrices() {
      const date = this._parseDate(this.blockPriceDialog.form.date, "dd/MM/yyyy")
      date.setHours(this.blockPriceDialog.form.hours, this.blockPriceDialog.form.minutes)
      const dateObj = this._formatDate(date, "dd/MM/yyyy HH:mm:ss")
      post(`/api/booking/${this.booking.id}/block-prices`, {expirationDate: dateObj}).res(() => {
        this.booking.blockPriceDate = dateObj
        this.blockPriceDialog.show = false
        EventBus.$emit("toaster-msg", this.booking.blockPriceDate ? "Blocaque enregistré" : "Déblocage enregistré");
        EventBus.$emit("reloadbooking", {});
      })
    },
    unblockPrices() {
      get(`/api/booking/${this.booking.id}/unblock-prices`).res(() => {
        EventBus.$emit("toaster-msg", "Déblocage enregistré");
        EventBus.$emit("reloadbooking", {});
      })
    },
    toggleTooltip(){
      this.showTooltip = !this.showTooltip
    }
  },

};
</script>

<style scoped>
.communicationTitle {
  background-color: #fafbfd;
}

.communicationHeadlineActive:hover {
  cursor: pointer;
}

.communicationContent {
  padding-left: 0;
  padding-right: 0;
  background-color: #ffffff;
}

.counter > span.v-chip__content {
  position: relative;
  right: 2px;
}

.logInfoTitle {
  font-size: 13px;
}
</style>
