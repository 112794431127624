import BookingStats from "@/views/stats/BookingStats.vue";
import HotelStats from "@/views/stats/HotelStats.vue";
import ActivityStats from "@/views/stats/ActivityStats.vue";
import FlightStats from "@/views/stats/FlightStats.vue";
import Noop from "@/components/Noop";
import PurchaseStats from "../views/stats/PurchaseStats";
import TransferStats from "../views/stats/TransferStats";
import SupplierAccountingStats from "../views/stats/SupplierAccountingStats";
import NetworkStats from "../views/stats/NetworkStats";
import AgenciesStats from "../views/stats/AgenciesStats";
import TravelBookingStats from "../views/stats/TravelBookingStats";
import AgencyActivitySegment from "../views/stats/AgencyActivitySegment";
import RoiStats from "@/views/stats/RoiStats.vue";
import ResasStats from "@/views/stats/ResasStats.vue";
import HlStats from "@/views/stats/HlStats.vue";
import {Role} from "@/utils/user";

const StatsRoutes = [
  {
    path: "/stats",
    name: "Stats",
    component: Noop,
    children: [
      {
        path: "dossiers",
        name: "BookingStats",
        component: BookingStats,
        props: true,
        meta: {
          breadcrumb: [
            {name: "Stats", route: {name: "BookingStats"}},
            {name: "Dossiers"},
          ],
        },
      },
      {
        path: "resas",
        name: "ResasStats",
        component: ResasStats,
        props: false,
        meta: {
          breadcrumb: [
            {name: "Stats", route: {name: "BookingStats"}},
            {name: "Resas"},
          ],
        },
      },
      {
        path: "hotels",
        name: "HotelStats",
        component: HotelStats,
        props: true,
        meta: {
          breadcrumb: [
            {name: "Stats", route: {name: "BookingStats"}},
            {name: "Hotels"},
          ],
        },
      },
      {
        path: "vols",
        name: "FlightStats",
        component: FlightStats,
        props: true,
        meta: {
          breadcrumb: [
            {name: "Stats", route: {name: "FlightStats"}},
            {name: "Vols"},
          ],
        },
      },
      {
        path: "reseaux",
        name: "NetworkStats",
        component: NetworkStats,
        props: true,
        meta: {
					roles: [Role.commercialAdmin],
          breadcrumb: [
            {name: "Stats", route: {name: "NetworkStats"}},
            {name: "Réseaux"},
          ],
        },
      },
      {
        path: "agences",
        name: "AgenciesStats",
        component: AgenciesStats,
        props: true,
        meta: {
          breadcrumb: [
            {name: "Stats", route: {name: "AgenciesStats"}},
            {name: "Agences"},
          ],
        },
      },
      {
        path: "activite",
        name: "ActivityStats",
        component: ActivityStats,
        props: true,
        meta: {
          breadcrumb: [
            {name: "Stats", route: {name: "BookingStats"}},
            {name: "Activité"},
          ],
        },
      },
      {
        path: "achats",
        name: "PurchaseStats",
        component: PurchaseStats,
        props: true,
        meta: {
          breadcrumb: [
            {name: "Stats", route: {name: "BookingStats"}},
            {name: "Achats"},
          ],
        },
      },
      {
        path: "transferts",
        name: "TransferStats",
        component: TransferStats,
        props: true,
        meta: {
          breadcrumb: [
            {name: "Stats", route: {name: "BookingStats"}},
            {name: "Transfert"},
          ],
        },
      },
      {
        path: "rois",
        name: "RoiStats",
        component: RoiStats,
        props: true,
        meta: {
          breadcrumb: [
            {name: "Stats", route: {name: "RoiStats"}},
            {name: "RoiStats"},
          ],
        },
      },
      {
        path: "supplier-accounting",
        name: "SupplierAccountingStats",
        component: SupplierAccountingStats,
        props: true,
        meta: {
          breadcrumb: [
            {name: "Stats", route: {name: "BookingStats"}},
            {name: "Fournisseurs"},
          ],
        },
      },
      {
        path: "travel-booking-stats",
        name: "TravelBookingStats",
        component: TravelBookingStats,
        props: true,
        meta: {
          breadcrumb: [
            {name: "Stats", route: {name: "BookingStats"}},
            {name: "TravelBookingStats"},
          ],
        },
      },
      {
        path: "agency-activity-segment",
        name: "AgencyActivitySegment",
        component: AgencyActivitySegment,
        props: true,
        meta: {
          roles: [Role.commercial],
          breadcrumb: [
            {name: "Stats", route: {name: "BookingStats"}},
            {name: "AgencyActivitySegment"},
          ],
        },
      },
      {
        path: 'repartition-regions',
        name: 'HlStats',
        component: HlStats,
        props: true,
        meta: {
          roles: [Role.commercial],
        }
      },
    ],
  },
];

export default StatsRoutes;
