<template>
  <div>
    <v-form class="pa-4">
      <v-row>

        <v-col cols="6">
          <div class="d-flex flex-column">
            <v-select
                class="mb-9"
                v-model="action.type"
                :items="types"
                item-value="id"
                item-text="label"
                label="Type"
                :error="errorType"
                dense
                hide-details
                placeholder=""
            />
            <v-select v-model="action.user"
                      :items="users"
                      item-value="id"
                      item-text="fullName"
                      label="Agent Hotelissima "
                      dense
                      hide-details
                      placeholder=""/>
          </div>

        </v-col>
        <v-col cols="6">
          <div class="d-flex">
            <smart-picker class="mr-3"
                          v-model="creationDate"
                          :inputOpts="{
                label: $t('begin'),
                clearable: false,
                placeholder: ' ',
              }" style="width:250px"></smart-picker>
          </div>
          <div class="d-flex">
            <v-select suffix="heure" :items="openedHours" full v-model="hours" width class="mr-3"></v-select>
            <v-select suffix="minutes" :items="openedMinutes" v-model="minutes"></v-select>
          </div>
        </v-col>

      </v-row>
      <v-row>
        <v-col cols="12">
          <p class="font-weight-medium">Commentaire</p>
          <v-textarea v-model="action.message"/>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions class="actions-btn">
      <v-spacer/>
      <smart-btn @click.native="save">
        <v-icon small class="mr-1">mdi-content-save</v-icon>
        Créer
      </smart-btn>
    </v-card-actions>

  </div>
</template>

<script>
import {get, post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";

export default {
  name: "LeadActionCreation",
  props: {
    lead: {type: Object, required: true},
  },
  data() {
    return {
      creationDate: this._formatDate(new Date(), "dd/MM/yyyy"),
      hours: this._formatDate(new Date(), "HH"),
      minutes: parseInt(this._formatDate(new Date(), "mm")) % 5 === 0 ? this._formatDate(new Date(), "mm") : "00",
      action: {
        creationDate: null,
        type: null,
        message: null,
        leadId: null,
        user: null,
        bookingId: null,
        phonecallId: null,
      },
      errorType: false,
      types: [],
      users: [],
      openedHours: ['9', '10', '11', '12', '13', '14', '15', '16', '17', '18'],
      openedMinutes: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
    }
  },
  created() {
    const loadActionTyppe = get("/api/lead/actions/types").json((rs) => {
      this.types = rs.map((tp) => {
        return {id: tp, label: this.$t("lead.action." + tp)}
      })
    })

    const loadUsers = get("/api/search/users").json(rs => {
      this.users = rs.map(u => u = {...u, fullName: `${u.firstname} ${u.lastname}`})
    })

    Promise.all([
      loadActionTyppe, loadUsers
    ]).then(() => {
      this.loading = false
    });
    this.action.leadId = this.lead.id;
    this.action.user = this.lead.user.id;
  },
  methods: {
    save() {
      if (this.action.type != null) {
        this.action.user = this.users.find(u => this.action.user === u.id)
        this.action.creationDate = this.creationDate + " " + this.hours + ":" + this.minutes + ":00"
        this.formatActionDate()
        post("/api/lead/add/action", this.action).json(() => {
          EventBus.$emit('toaster-msg', "Action créée");
          EventBus.$emit("reloadLead", {});
          this.$emit("done")

        })
      } else {
        this.errorType = true
      }
    },
    formatActionDate() {
      const dateTime =
          console.log(dateTime)
      /*return this._parseAndFormatLocalDateTime(dateTime, "dd/MM/yyyy HH:mm", )
      this.action.hours
      this.action.minutes*/
    }
  },

  watch:
      {
        action: {
          deep: true,
          handler(newVal) {
            if (newVal.type) {
              this.errorType = false
            }
          }
        }
      }
}
</script>