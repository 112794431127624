<template>
  <div>
    <smart-dialog v-model="open" title="Emails en attente d'envoi" width="950">
      <v-card>
        <v-card-text class="pa-0 ma-0">
          <v-row class="mb-2 pt-1" justify="end">
            <smart-btn
              class="mr-2"
              @click.native="regenAndReload('supplier')"
            >Ré-envoyer confirmation fournisseur</smart-btn>
            <smart-btn
              class="mr-2"
              @click.native="regenAndReload('customer')"
            >Ré-envoyer confirmation client</smart-btn>
            <smart-btn class="mr-4" @click.native="init()">
              <v-icon>mdi-reload</v-icon>
            </smart-btn>
          </v-row>

          <v-row class="mb-2 pl-4 pt-1">
            <span
              class="body-2 font-weight-medium"
            >Messages en attente d'envoi ({{table.items.unsent.length}})</span>
          </v-row>
          <v-data-table
            v-model="table.selected"
            :headers="table.headers"
            :items="table.items.unsent"
            :loading="table.loading"
            show-select
            item-key="id"
            no-data-text="Aucun message en attente"
          >
            <template #item.delete="{item, value}">
              <v-icon @click="setDeleteDialog(item.id)">mdi-trash-can</v-icon>
            </template>
            <template #item.see="{item, value}">
              <v-icon @click="edit(item)">mdi-eye</v-icon>
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-row class="my-2 pl-4">
          <span class="body-2 font-weight-medium">Messages envoyés ({{table.items.sent.length}})</span>
          <v-icon @click.native="expend()">mdi-{{ collapse ? 'chevron-up' : 'chevron-down'}}</v-icon>
        </v-row>
        <div v-if="collapse">
          <v-data-table
            v-model="table.selected"
            :headers="table.headers"
            :items="table.items.sent"
            show-select
            item-key="id"
            no-data-text="Aucun message envoyé"
          >
            <template #item.delete="{item, value}">
              <v-icon @click="setDeleteDialog(item.id)">mdi-trash-can</v-icon>
            </template>
            <template #item.see="{item, value}">
              <v-icon @click="edit(item)">mdi-eye</v-icon>
            </template>
          </v-data-table>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
           <smart-btn
            secondary
            :disabled="table.selected.length < 2 "
            action="delete"
            class="justify-self-end mb-0"
            :loading="dialog.loading"
            @click.native="setMultipleDelete()"
          >
             {{ labelDeletion}}</smart-btn>
          <v-spacer></v-spacer>
          <smart-btn
            primary
            class="justify-self-end mb-0"
            :loading="dialog.loading"
            @click.native="send"
            :disabled="table.selected.length==0"
          >{{$t('send')}}</smart-btn>
        </v-card-actions>
      </v-card>
    </smart-dialog>

    <smart-dialog v-model="dialogEmail.open" title="Message" width="750">
      <v-card>
        <v-card-text v-if="dialogEmail.message != null && dialogEmail.message.body != ''">
          <v-row>
            <v-col>
              <smart-editor v-model="dialogEmail.message.body" :height="600" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="actions-btn">
          <v-spacer></v-spacer>
          <smart-btn
            primary
            class="justify-self-end mb-0"
            :loading="dialog.loading"
            @click.native="save"
          >{{$t('save')}}</smart-btn>
        </v-card-actions>
      </v-card>
    </smart-dialog>

    <smart-dialog v-model="dialogDelete.open" title="Supprimer" width="750" height="900" @close="reinitDelete()">
      <v-card>
        <v-card-text >
          <v-row class="ma-4" v-if="!dialogDelete.multiple">Cette action est irréversible, souhaitez-vous réellement supprimer ce message ?</v-row>
          <v-row class="ma-4" v-else>Cette action est irréversible, souhaitez-vous réellement supprimer ces {{table.selected.length}} messages ?</v-row>
        </v-card-text>
        <v-card-actions class="actions-btn">
          <v-spacer></v-spacer>
          <smart-btn
            class="error justify-self-end mb-0"
            :loading="dialog.loading"
            @click.native="dialogDelete.multiple ? deleteMails() : deleteMail()"
          >{{$t('supprimer')}}</smart-btn>
        </v-card-actions>
      </v-card>
    </smart-dialog>
  </div>
</template>

<script>
import { get, post } from "@/utils/api";
import { EventBus } from "@/components/commons/event-bus";
import SmartEditor from "./SmartEditor";
import FormMixin from "@/components/mixins/FormMixin";

export default {
  name: "LockMails",
  components: { SmartEditor },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    bookingid: {
      type: Number,
      required: true,
    },
  },
  mixins: [FormMixin],
  data() {
    return {
      emails: [],
      dialog: {
        open: false,
        loading: false,
      },
      dialogEmail: {
        open: false,
        message: null,
      },
      dialogDelete: {
        open: false,
        messageId: null,
        multiple: false
      },
      table: {
        loading: false,
        pagination: {},
        headers: [
          { text: "Objet", value: "title" },
          { text: "Destinataires", value: "recipients" },
          { text: "Date de création", value: "creationDate" },
          { text: "Voir le contenu", value: "see" },
          { text: "Supprimer", value: "delete" },
          { text: "", value: "" },
        ],

        items: {
          unsent: [],
          sent: [],
        },
        selected: [],
      },
      collapse: false,
    };
  },
  created() {
    this.init();
  },
  watch: {
    emails: {
      immediate: false,
      handler(emails) {
        //this.table.items = emails
        this.table.items.unsent = [];
        this.table.items.sent = [];
        emails.map((email) => {
          if (email.sentDate) {
            this.table.items.sent.push(email);
          } else {
            this.table.items.unsent.push(email);
          }
        });
      },
    },
  },
  computed: {
    labelDeletion(){
      return this.table.selected.length<2 ? "supprimer la seléction" : "Supprimer les "+this.table.selected.length+" mails"
    },
    open: {
      get() {
        this.init();
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    init() {
      const url = `/api/mail/messages/locked/booking/${this.bookingid}`;

      this.table.loading = true;

      return get(url)
        .json((r) => {
          this.emails = r;
          this.$emit("loadmails", { mails: this.emails });
        })
        .then(() => {
          this.table.loading = false;
        });
    },
    toggleAll() {
      if (this.table.selected.length) this.table.selected = [];
      else this.table.selected = this.table.items.slice();
    },
    edit(email) {
      this.dialogEmail = { ...{ message: email }, ...{ open: true } };
    },
    setMultipleDelete(){
      this.dialogDelete.multiple = true
      this.dialogDelete.open = true
    },
    setDeleteDialog(id) {
      this.dialogDelete.messageId = id;
      this.dialogDelete.open = true;
    },
    deleteMail() {
      const url = `/api/mail/messages/delete/${this.dialogDelete.messageId}`;

      get(url)
        .badRequest((err) => {
          EventBus.$emit("toast", {
            color: "red",
            text: `Une erreur a eu lieu:${err}`,
            "multi-line": true,
          });
          this.dialogDelete.messageId = null;
        })
        .res((r) => {
          EventBus.$emit("toast", { color: "green", text: "Email supprimé" });
          this.dialogDelete.messageId = null;
          this.dialogDelete.open = false;
          const removeIndex = this.table.selected.indexOf(mail => mail === this.dialogDelete.messageId)
          this.table.selected.splice(removeIndex,1)
          this.init();
        });
    },
    deleteMails(){
      const ids = this.table.selected.map(msg => msg.id)
     post("/api/mail/messages/delete/multiple", ids)
      .badRequest((err) => {
          EventBus.$emit("toast", {
            color: "red",
            text: `Une erreur a eu lieu:${err}`,
            "multi-line": true,
          });
          this.dialogDelete.messageId = null;
        })
        .res((r) => {
          EventBus.$emit("toast", { color: "green", text: "Email supprimé" });
          this.dialogDelete.messageId = null;
          this.dialogDelete.open = false;
          this.table.selected = []
          this.init();
        });
    },
    send() {
      this.dialog.loading = true;
      post(`/api/mail/messages/sendtransac`, this.table.selected)
        .badRequest((err) => {
          EventBus.$emit("toast", {
            color: "red",
            text: `Une erreur a eu lieu:${err}`,
            "multi-line": true,
          });
          this.dialog.loading = false;
        })
        .res((r) => {
          EventBus.$emit("toast", { color: "green", text: "Emails envoyés" });
          this.dialog.loading = false;
          this.dialog.open = false;
          this.open = false;
        });
    },
    save() {
      post(`/api/mail/messages/update`, this.dialogEmail.message)
        .badRequest(() => {
          EventBus.$emit("toast", {
            color: "red",
            text: "Une erreur a eu lieu",
          });
        })
        .res((r) => {
          EventBus.$emit("toast", {
            color: "green",
            text: "Les informations ont bien été sauvegardées",
          });
          this.dialogEmail.open = false;
          this.table.items.unsent = this.table.items.unsent.map((item) => {
            return item.id == this.dialogEmail.message.id
              ? { ...this.dialogEmail.message }
              : { ...item };
          });
        });
    },
    expend() {
      this.collapse = !this.collapse;
    },
    regenAndReload(mailType) {
      this.$root
        .$confirm(
          this.$t("confirm"),
          "Attention, les emails vont être renvoyés !",
          { width: 350 }
        )
        .then((confirm) => {
          if (!confirm) {
            return Promise.resolve();
          } else {
            this.table.loading = true;
            this.$emit("regenMails", {
              mailType: mailType,
              reload: true,
              callback: this.init,
            });
          }
        });
    },
    reinitDelete(){
      this.dialogDelete.multiple = false
    }
  },
};
</script>

<style scoped>
</style>
