import ConfirmPresta from '@/views/extranet/ConfirmPrestas.vue'
import BookingListExtranet from '@/views/extranet/BookingListExtranet.vue'
import ArrivalsDeparture from '@/views/extranet/ArrivalsDeparture.vue'
import {Role} from "@/utils/user";

const routes = [
	{
		path: '/extranet/confirm-prestation/:bookingid/:supplierid',
		name: 'confirm-prestation',
		component: ConfirmPresta,
		props: true,
		meta: {
			plainLayout: true,
			unsecure: true,
			breadcrumb: [
				{name: 'Confirmation des prestations'},
			]
		}
	},
	{
		path: '/extranet/vente/dossier',
		name: 'BookingListReceptif',
		component: BookingListExtranet,
		props: true,
		meta: {
			roles: [Role.onplaceagent],
			plainLayout: false,
			unsecure: false,
			breadcrumb: [
				{name: 'Dossiers'},
			]
		}
	},
	{
		path: '/extranet/vente/arrivees-departs',
		name: 'ArrivalDeparture',
		component: ArrivalsDeparture,
		props: true,
		meta: {
			roles: [Role.onplaceagent],
			plainLayout: false,
			unsecure: false,
			breadcrumb: [
				{name: 'Arrivées / départs'},
			]
		}
	},
]

export default routes
