<template>
  <v-card ref="vCardRef"
          :elevation="1"
          rounded="lg"
          class="pa-4"
          style="height:100%; width:100%; overflow: hidden">
    <div class="d-flex flex-row justify-space-between mb-8">
      <span class="mr-10 font-weight-bold">
        Nombre de booking par date de départ</span>
      <div>
        <span @click="viewBy('day')"
              :class="actualView == 'day'
                ? 'font-weight-black pointer-event-none'
                : 'text-decoration-underline cursor-pointer'">Jour</span> |
        <span @click="viewBy('month')"
              :class="actualView == 'month'
              ? 'font-weight-black pointer-event-none'
              : 'text-decoration-underline cursor-pointer'">Mois</span>
      </div>
    </div>
    <apexchart
        ref="apexcharts"
        type="area"
        height="300px"
        :options="options"
        :series="series"
        v-if="series.length > 0"
    >

    </apexchart>
  </v-card>
</template>
<script>
import {defineComponent, ref} from "vue";
import {addYears, getDayOfYear, getDaysInYear, isValid} from "date-fns";
import {post} from "@/utils/api";
import _ from "lodash";
import {EventBus} from "@/components/commons/event-bus";
//Nombre de réservation par date de départ
export default defineComponent({
  name: "BookingsByDepartureDateChart",
  components: {},
  props: {},
  data() {
    return {
      vCardRef: ref(null),
      rawDataTable: null,
      url: `/api/widget/dashboard/bookings-by-departure`,
      actualView: 'week',
      importantsDate: [],
      series: [],
      options: {
        plotOptions: {
          customScale: 1.9
        },
        stroke: {
          show: true,
          curve: 'smooth',
          lineCap: 'butt',
          width: 1,
          dashArray: 0,
        },
        chart: {
          defaultLocale: 'fr',
          id: "area",
          datetimeUTC: false,
          type: "line",
          stacked: false,
          height: 350,
          zoom: {
            enabled: false,
            autoScaleYaxis: true,
          },
          locales: [{
            name: "fr",
            options: {
              months: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Aout", "Septembre", "Octobre", "Novembre", "Décembre"],
              shortMonths: ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
              days: ["Dimanche", "Lundi", "Merdi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
              shortDays: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
            }
          }],
        },
        yaxis: {
          type: 'numeric',
          decimalsInFloat: true,
        },
        xaxis: {
          floating: false,
          position: 'bottom',
          axisTicks: {
            show: true,
          },
          min: new Date(`${new Date().getFullYear()}-01-01`),
          max: new Date(`${new Date().getFullYear()}-12-31`),
          type: 'datetime',
          tickAmount: 'dataPoints',
          labels: {
            datetimeUTC: false,
            show: true,
            datetimeFormatter: {

              month: 'MMMM ',

            },
            rotate: -45,
            rotateAlways: true,
            hideOverlappingLabels: true,
            showDuplicates: false,
            trim: false,
            minHeight: undefined,
            maxHeight: null,
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            },
          }
        },
        dataLabels: {
          enabled: true
        },
        tooltip: {

          x: {
            show: true,
            format: 'MMMM',

          },
          fixed: {
            enabled: false,

          },

        },
        fill: {
          type: 'gradient',
        },
        legend: {position: 'bottom'},
        annotations: {},
      },
    }
  },
  created() {

  },
  computed: {},
  methods: {
    load(url, form) {
      if (form == null) return null
      this.series = []
      this.options.xaxis = {min: null, max: null}

      post(url ?? this.url, form, {"Content-Type": "application/json"})
          .json(rs => {
                const sorted = rs.sort((a, b) => {
                  const aParsed = this._parseDate(a.name, "yyyy-MM-dd")
                  const bParsed = this._parseDate(b.name, "yyyy-MM-dd")
                  return this._isBefore(aParsed, bParsed) ? -1 : this._isAfter(aParsed, bParsed) ? 1 : 0
                })
                this.rawDataTable = sorted.map(day => {
                  return {
                    x: new Date(day.name),
                    y: day.count
                  }
                })
                this.rawDataTable = this.rawDataTable.sort((a, b) => this._isBefore(a.x, b.x) ? -1 : this._isAfter(a.x, b.x) ? 1 : 0)

                this.options.xaxis.min = new Date(`${form.startDateAct}`).toString()
                this.options.xaxis.max = new Date(`${form.stopDateAct}`).toString()
                this.viewBy('month')
              }
          )
    },
    viewBy(timeUnit) {
      this.options.tooltip.x.format = null
      this.series = []
      this.actualView = timeUnit;
      switch (timeUnit) {
        case 'day': {
          this.options.tooltip.x.format = 'dd MMMM'
          this.chartByDay()
          break;
        }
        case 'month': {
          this.options.tooltip.x.format = 'MMMM'
          this.chartByMonth()
          break;
        }
      }
    },
    chartByDay() {
      if (this.rawDataTable == null) return []

      const row = JSON.parse(JSON.stringify(this.rawDataTable))
      const dates = this.getDates(new Date(row[0].x), new Date(row.at(-1).x))
      const serie = []
      dates.map(date => {
        const data = row.find(r => this._isSame(new Date(r.x), date, 'day'))

        if (!data) {
          serie.push({
            x: this._formatDate(new Date(date), "dd MMMM yyyy"),
            y: 0
          })
        } else {
          serie.push({
            x: this._formatDate(new Date(data.x), "dd MMMM yyyy"),
            y: data.y
          })
        }
      })

      this.series.push({
        name: "Départs ce jour",
        data: serie.sort((a, b) => this._isBefore(new Date(a.x), new Date(b.x)) ? -1 : this._isAfter(new Date(a.x), new Date(b.x)) ? 1 : 0),
        color: 'rgb(0,143,251)'
      })
    },
    chartByMonth() {
      const rawData = JSON.parse(JSON.stringify(this.rawDataTable))
      const monthsGrouped = this.groupByMonth(rawData)
      this.series.push({
        name: "Départs ce jour",
        data: monthsGrouped,
        color: 'rgb(0,143,251)'
      })
    },
    getDates(startDate, stopDate) {
      const dateArray = []
      let currentDate = startDate;
      while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate));
        currentDate = this._addDays(currentDate, 1)
      }
      return dateArray;
    },

    groupByMonth(list) {
      const yearData = _.groupBy(list, e => {
        return this._formatDate(new Date(e.x),  'MMMM yyyy')
      })

      return Object.keys(yearData).map((key) => {
        const date = this._formatDate(new Date(yearData[key][0].x), 'MMMM yyyy')

        return {x:date, y:_.sumBy(yearData[key], (v) => v.y)}
      }).sort((a, b) => {
        return a[0] - b[0]
      })
    }
  }
})
</script>
<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.pointer-event-none {
  pointer-events: none;
}
</style>