<template>
  <v-card :elevation="1" rounded="lg" style="width:100%;height:100%; overflow:scroll;" class="pa-4">
    <div class="font-weight-bold text-lg mb-4">
      {{ conf.title }}
    </div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left">
            Date
          </th>
          <th class="text-left">
            CA
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in data"
            :key="item.name">
          <td>{{ _formatDate(new Date(item.name), "dd MMMM yyyy") }}</td>
          <td>{{ _formatCurrency(item.value, "EUR") }}</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>
<script>
import {get, post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";
//Top 5 des meilleurs CA Journaliers
export default {
  name: "HotelsTable",
  components: {},
  props: {
    form: {type: Object, required: true},
    conf: {type: Object, required: false, default: null}
  },
  data() {
    return {
      url: `/api/widget/dashboard/podium`,
      data: [],
      loading: true,
    }
  },
  computed: {},
  created() {
    EventBus.$on('searchDashboard', () => this.init());
    this.init();
  },
  methods: {
    init() {
      const searchForm = {
        size: this.conf.size
      }
      let tile = post(this.url, searchForm, {"Content-Type": "application/json"})
          .json((r) => {
            this.data = r
          })
      Promise.all([tile]).then(() => {
        this.loading = false
      })
    },
  }

}
</script>