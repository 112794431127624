<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <div class="title">Prix</div>
        <v-divider />
      </v-col>
      <v-col cols="3">
        <v-text-field v-model.number="price.price" hide-details :label="$t('price')" right />
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="price.currency"
          hide-details
          :items="currencies"
          :label="$t('currency')"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field v-model.number="price.purchase" hide-details :label="$t('purchase')" />
      </v-col>
      <v-col cols="2">
        <v-select
          v-model="price.purchaseCurrency"
          hide-details
          :items="currencies"
          :label="$t('currency')"
          disabled
        />
      </v-col>
      <v-col cols="2">
        <v-text-field v-model.number="price.tva" hide-details :label="$t('tva')" disabled/>
      </v-col>
    </v-row>

    <v-row class="mt-3">
      <v-col cols="3">
        <v-text-field
          v-model.number="price.euroRate"
          hide-details
          :label="$t('rate') + ' ' +  price.currency + '/EUR'"
          right
          disabled
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model.number="price.euroPrice"
          hide-details
          :label="$t('euro_price')"
          right
          disabled
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model.number="price.euroPurchaseRate"
          hide-details
          :label="$t('rate') + ' ' + price.purchaseCurrency + '/EUR'"
          right
          disabled
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          v-model.number="price.euroPurchase"
          hide-details
          :label="$t('euro_purchase')"
          disabled
        />
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12">
        <div class="title">Prix réels</div>
        <v-divider />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model.number="price.realPurchasePrice"
          hide-details
          :label="$t('purchase')"
          right
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model.number="price.realEuroPurchasePrice"
          hide-details
          :label="$t('euro_purchase')"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: ["value"],
  data: function () {
    return {
      price: this.value,
      currencies: ["EUR", "USD"],
    };
  },
  watch: {
    price: {
      deep: true,
      handler() {
        this.$emit("input", this.price);
      },
    },
    "price.price": {
      deep: false,
      handler() {
        this.refreshEuroPrice();
      },
    },
    "price.purchase": {
      deep: false,
      handler() {
        this.refreshEuroPurchase();
      },
    },
    "price.autoConvert": {
      deep: false,
      handler() {
        this.refreshEuroPrice();
        this.refreshEuroPurchase();
      },
    },
    "price.euroRate"() {
      this.refreshEuroPrice();
    },
    "price.euroPurchaseRate"() {
      this.refreshEuroPurchase();
    },
  },
  methods: {
    refreshEuroPrice() {
      if (this.price.autoConvert) {
        this.price.euroPrice = this.price.price / this.price.euroRate;
      }
    },
    refreshEuroPurchase() {
      if (this.price.autoConvert) {
        this.price.euroPurchase =
          this.price.purchase / this.price.euroPurchaseRate;
      }
    },
  },
};
</script>