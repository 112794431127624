<template>
  <div>
    <smart-btn class="float-right mb-2" @click.native="openFeeDialog()">Modifier</smart-btn>
    <smart-dialog v-model="feeDialog" eager title="Modification des frais d'annulation" :width="widthDialog">
      <template>
        <div class="px-4 ">
          <div class="pt-3" v-for="(fee, index) in cancelFees" :key="index + '-' + fee.invalid" :style="elementStyle(index)">
            <div class="d-flex justify-space-between align-center mb-2" >
              <strong>{{ fee.name}}</strong>
              <v-btn x-small rounded outlined color="blue" :text="false" @click.native="addPeriod(fee)">
                <v-icon>mdi-plus</v-icon> Ajouter période
              </v-btn>
            </div>
            <div style="width:100%; overflow-x: scroll ">
              <CancelFeeEdit :fees="fee.fees" :startPrestaDate="getFormattedDatePresta()"
                             :divider="index == (cancelFees.length - 1) || cancelFees.length == 1"
                             :prestationId="fee.id"
                             @change="(f) => updateFee(f)" @remove="(f) => removeFee(f, index)"
                             v-if="!refreshRemoved"/>

            </div>
          </div>
        </div>
      </template>
      <template #prependAction/>
      <template #actions>
        <smart-btn class="mr-3" @click.native="feeIsValid()">Vérifier les périodes</smart-btn>
        <smart-btn @click.native="saveFee()">Valider</smart-btn>
      </template>

    </smart-dialog>
  </div>
</template>
<script>
import CancelFeeEdit from "@/components/commons/CancelFeeEdit.vue";
import {EventBus} from "@/components/commons/event-bus";
import {post} from "@/utils/api";
import {nextTick} from "vue";

export default {
  name: 'CancelFeesEditDialog',
  components: {CancelFeeEdit},
  props: {
    prestation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cancelFees: [],
      feeDialog: false,
      refreshRemoved: false,
    }
  },
  watch: {},
  created() {
    if(this.prestation.segments != null) {

      this.segments = JSON.parse(JSON.stringify(this.prestation.segments));
      this.cancelFees = this.segments.map(s => {
        return {...s.cancelFees, id: s.id, name:s.data.name}
      });
      this.cancelFees.forEach(x => x.fees.forEach(f => f.invalid = null))
    }else {
      this.cancelFees = [{...this.prestation.cancelFees, id: this.prestation.id}]

    }
  },
  computed: {
    widthDialog() {
      const temp = JSON.parse(JSON.stringify(this.cancelFees))
       const sorted = temp.sort((Afee, Bfee) => Bfee.fees.length - Afee.fees.length)
      return (sorted[0].fees.length * 250) <= 600 ? 600 : sorted[0].fees.length * 250
    }
  },
  methods: {

    openFeeDialog() {
      if (this.cancelFees.length == 0) {
        this.cancelFees.push({start: null, stop: null, price: 0})
      }
      this.feeDialog = true
    },
    saveFee() {
      if (this.feeIsValid()) {
        const body = this.cancelFees.map(cancelfee => {
              return {...cancelfee, fees: {fees:cancelfee.fees}}
            }
        )

        post(`/api/prestations/update/fees`, body)
            .badRequest((err) => {
              EventBus.$emit(
                  "toaster-msg",
                  JSON.parse(err.null).errors.map((e) => JSON.parse(e))
              );
            })
            .json(() => {
              EventBus.$emit("toaster-msg", this.$t("update-cancelfees"));
              EventBus.$emit("reloadbooking", {});
              this.feeDialog = false
              this.cancelFees.forEach(x => x.fees.forEach(f => f.invalid = null))
            });

      } else {
        EventBus.$emit("toaster-error", "Les dates des périodes ne correspondent pas");
      }
    },
    addPeriod(segments) {
      const start = (segments.fees.length > 0)
          ? this._addDays(this._parseDate(segments.fees[segments.fees.length - 1].stop), 1)
          : this._parseDate(this.prestation.start.substring(0, 10), "dd/MM/yyyy")

      const stop = (segments.fees.length > 0)
          ? this._addDays(start, 1)
          : this._parseDate(this.prestation.stop.substring(0, 10), "dd/MM/yyyy")

      segments.fees.push({
        start: this._formatDate(start, "dd/MM/yyyy"),
        stop: this._formatDate(stop, "dd/MM/yyyy"),
        price: 0
      })

    },
    removeFee(fee, index) {
      this.refreshRemoved = true
      this.cancelFees[index].fees.splice(fee.index, 1)
      nextTick(() => this.refreshRemoved = false)
    },
    updateFee(fees) {
      this.cancelFees.forEach(f => {
        if (f.id == fees.id) {
          f.fees = fees.fees
        }
      })
    },
    getFormattedDatePresta() {
      return this._formatDate(this._parseDate(this.prestation.start.split(' ')[0], "dd/MM/yyyy"), "yyyy-MM-dd")
    },
    feeIsValid() {
      this.cancelFees.forEach(f => f.fees.map(f => f.invalid = null))
      this.cancelFees = this.sortCancelFees()
      this.cancelFees.forEach((cancelFee, i) => {
            cancelFee.fees.reduce((allfees, curr) => {
              const prev = allfees.length > 0 ? allfees[allfees.length - 1] : null
              if (prev != null && prev.stop != null && curr.start != null) {
                if (!this._isSame(
                    this._addDays(this._parseDate(prev.stop, 'dd/MM/yyyy'), 1),
                    this._parseDate(curr.start, "dd/MM/yyyy"), "days")) {
                  curr.invalid = 'start'
                } else if (this._isAfter(this._parseDate(curr.stop, 'dd/MM/yyyy'), this._parseDate(this.getFormattedDatePresta(), 'yyyy-MM-dd'))) {
                  curr.invalid = 'stop'
                } else if (this.prestation.price < curr.price) {
                  curr.invalid = 'price'
                }
              }
              allfees.push(curr)

              return allfees
            }, [])
          }
      )
      return !this.cancelFees.some(f => f.fees.some(f => f.invalid != null))


    },
    sortCancelFees() {
      this.cancelFees.map((cancelFees) => {
        return {
          id: cancelFees.id,
          fees: cancelFees.fees.sort((a, b) => {

            let aParse = this._parseDate(a.start, "dd/MM/yyyy")
            let bParse = this._parseDate(b.start, "dd/MM/yyyy")
            if (this._isBefore(aParse, bParse)) {
              return -1
            } else if (this._isAfter(aParse, bParse)) {
              return 1
            } else {
              return 0
            }
          })
        }
      })
      return this.cancelFees
    },
    elementStyle(index){
     return index == this.cancelFees.length-1 ? '': 'border-bottom : 1px solid lightgrey; '
    },
  }
};
</script>