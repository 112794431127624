<template>
  <div class="pa-4" v-if="transcription">
    <div class="grey--text lighten-2 caption">
      #{{ transcription.id }}
    </div>
    <v-divider class="mb-6"/>
    <div class=" messages-container" :class="inline? 'inline':'dialog'">
      <div v-for="(message, index) in transcription.utterances" :key="index" class="messages">

        <div v-if="message.speaker !== 'A'" class="pa-2 d-flex flex-column message">
          <span class="text-caption">{{ message.speaker }}: </span>
          <span class="align-self-start ">{{ message.text }}</span>
        </div>

        <div v-else class="pa-2 d-flex flex-column message reverse">
          <span class="text-caption">{{ message.speaker }}: </span>
          <span class="">{{ message.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Chatting",
  props:{
    transcription : {type:Object, default:null},
    inline: {type:Boolean, default:false}
  },
  created(){

  }

}
</script>
<style scoped>

.messages-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  min-height: 200px;
}

.messages{
  //overflow: scroll;
}

.inline{
  max-height: 425px;
}

.dialog{
  height: calc(100vh / 2);
}

.message {
  background-color: rgb(243, 244, 246);
  border: 1px solid rgb(243, 244, 246);
  border-radius: 12px 0 12px 12px;
  display: flex;
  flex-direction: column;
  width: 75%;
  justify-self: end;
  overflow: scroll;
}

.message.reverse {
  border: 1px solid rgb(243, 244, 246);
  border-radius: 0 12px 12px 12px;
  justify-self: start;
  //overflow-x: scroll;
}
</style>