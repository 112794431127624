<template>
  <layout :drawerShow="true" :drawerWidth="350">
    <template slot="nav"></template>
    <template slot="nav-tools">
      <v-btn text @click="toggleAll" class="mx-2 d-none d-sm-flex"
        >Tout sélectionner</v-btn
      >
      <v-btn
        class="primary mx-2 d-none d-sm-flex"
        :disabled="listing.selectedBookings.length == 0"
        @click="openMailcomposer"
      >
        Email sélection
      </v-btn>

      <v-btn
        class="primary d-none d-sm-flex ml-2 mr-2"
        :disabled="listing.selectedBookings.length == 0"
        @click="smsDialog.dialog = true"
        >SMS sélection
      </v-btn>

      <v-btn
        class="primary mx-2 d-none d-sm-flex"
        :disabled="listing.selectedBookings.length == 0"
        @click="cancelSelection"
        >À valoir sélection</v-btn
      >
    </template>
    <template slot="drawer">
      <v-row>
        <v-col cols="12" class="mt-5">
          <smart-multi-select
            v-model="selectedTags"
            label="Etiquettes"
            :items="tags"
            item-value="id"
            item-text="name"
            :all-is-same-as-empty="false"
            :default-select-all="true"
          ></smart-multi-select>
        </v-col>
        <v-col cols="12">
          <v-select
            v-model="site"
            :items="sites"
            item-value="id"
            item-text="name"
            label="Site"
            hide-details
            clearable
          />
        </v-col>

        <v-col cols="12">
          <v-subheader light class="pa-0 mt-5 mb-0 mx-0 form-subheader"
            >Dates de voyage</v-subheader
          >
          <v-divider></v-divider>
        </v-col>
        <v-col class="pr-1" cols="6">
          <smart-picker
            v-model="tripDateStart"
            :stopDate="tripDateStop"
            :inputOpts="{
              label: $t('begin'),
              clearable: true,
              placeholder: ' ',
            }"
          ></smart-picker>
        </v-col>
        <v-col cols="6" class="pr-1">
          <smart-picker
            v-model="tripDateStop"
            :startDate="tripDateStart"
            :inputOpts="{ label: $t('end'), clearable: true, placeholder: ' ' }"
          ></smart-picker>
        </v-col>

        <v-col cols="12">
          <smart-btn
            :loading="listing.loading"
            @click.native="loadBookings"
            block
            >Rechercher</smart-btn
          >
        </v-col>
      </v-row>
    </template>
    <template slot="content">
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-btn
              @click="copy"
              small
              depressed
              :disabled="this.listing.bookings.length == 0"
            >
              <v-icon small>mdi-clipboard-outline</v-icon>Copier
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-data-table
              v-model="listing.selectedBookings"
              :loading="listing.loading"
              :headers="listing.headers"
              :items="listing.bookings"
              :footer-props="{
                'items-per-page-options': listing.rowsPerPageItems,
                'items-per-page-text': 'Lignes par page',
              }"
              show-select
              class="tbl"
              no-data-text="0 résultats"
            >
              <template #item.siteName="{ item, value }">
                <site-label :sitename="value"></site-label>
              </template>
              <template #item.startDate="{ item, value }">
                {{
                  _parseAndFormatDate(
                    value,
                    datePatternConfig.serverLocalDateTime,
                    datePatternConfig.serverLocalDate
                  )
                }}
                <div class="caption grey--text text--darken-1">
                  {{
                    _parseAndFormatDate(
                      value,
                      datePatternConfig.serverLocalDateTime,
                      _datePattern.time
                    )
                  }}
                </div>
              </template>
              <template #item.stopDate="{ item, value }">
                <div class="font-weight-regular">
                  {{
                    _parseAndFormatDate(
                      value,
                      datePatternConfig.serverLocalDateTime,
                      "dd/MM/yy"
                    )
                  }}
                </div>
                <div class="caption grey--text text--darken-1">
                  {{
                    _parseAndFormatDate(
                      value,
                      datePatternConfig.serverLocalDateTime,
                      _datePattern.time
                    )
                  }}
                </div>
              </template>
              <template #item.tagnames="{ value }">
                <v-tooltip top v-for="(tag, tagIndex) in value" :key="tagIndex">
                  <template #activator="{ on }">
                    <v-chip v-on="on" small label class="pink white--text mx-1">
                      <v-icon left dark class="mr-0 pr-1" color="white"
                        >mdi-tag</v-icon
                      >
                      {{ tag }}
                    </v-chip>
                  </template>
                  {{ tag }}
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <smart-dialog
          v-model="editTagDialog.dialog"
          :title="`Etiquetter ${listing.selectedBookings.length} dossiers`"
        >
          <v-row class="ma-2 pa-2">
            <v-col cols="4">
              <v-subheader class>Etiquetter le dossier avec:</v-subheader>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="editTagDialog.selectedTags"
                :items="tags"
                item-value="id"
                item-text="name"
                multiple
              ></v-select>
            </v-col>
          </v-row>
          <template slot="actions">
            <smart-btn primary @click.native="tagging">Appliquer</smart-btn>
          </template>
        </smart-dialog>
        <smart-dialog
          v-model="mailDialog.dialog"
          title="Envoi d'email aux clients"
        >
          <mail-composer
            :loading="mailDialog.loading"
            :show-internal-attachment="false"
            :show-external-attachment="false"
            :tos="mailDialog.tmpTos"
            :subject="mailDialog.subject"
            :message="mailDialog.message"
            @send="sendEmails"
          ></mail-composer>
        </smart-dialog>

        <smart-dialog
          v-model="smsDialog.dialog"
          title="Envoi de SMS aux clients"
        >
          <sms-composer
              v-if="smsDialog.dialog"
            :loading="smsDialog.loading"
            :list="listing.selectedBookings"
            @send="sendSmsBulk"
          ></sms-composer>
        </smart-dialog>
      </v-container>
    </template>
  </layout>
</template>

<script>
import Layout from "../Layout";
import { EventBus } from "@/components/commons/event-bus";
import { get, post } from "@/utils/api";
import SiteLabel from "../../components/commons/SiteLabel";
import MailComposer from "../../components/commons/MailComposer";
import SmsComposer from "../../components/commons/SmsComposer";
import clipboard from "../../utils/clipboard";
import SmartMultiSelect from "../../components/commons/SmartMultiSelect";

export default {
  name: "TagsList",
  components: {
    SmartMultiSelect,
    MailComposer,
    SiteLabel,
    Layout,
    SmsComposer,
  },
  mixins: [],
  data() {
    return {
      listing: {
        bookings: [],
        selectedBookings: [],
        loading: false,
        headers: [
          { text: "", value: "selectable", align: "left" },
          { text: "Site", value: "siteName", align: "left" },
          { text: "Dossier", value: "bookingId", align: "left" },
          { text: "Passager", value: "guestName", align: "left" },
          { text: "Téléphone", value: "phone", align: "left" },
          { text: "Email", value: "email", align: "left" },
          {
            text: "Debut",
            value: "startDate",
            align: "left",
            sort: this.sortByDate,
          },
          {
            text: "Fin",
            value: "stopDate",
            align: "left",
            sort: this.sortByDate,
          },
          { text: "Hotel", value: "hotels", align: "left" },
          { text: "Vols", value: "flights", align: "left" },
          { text: "Tags", value: "tagnames", align: "left" },
          { text: "", value: "edit" },
        ],
        rowsPerPageItems: [{ text: "Tous", value: -1 }, 50, 200],
      },

      tags: [],
      selectedTags: [],
      site: null,
      sites: [],
      tripDateStart: null,
      tripDateStop: null,
      editTagDialog: {
        dialog: false,
        selectedTags: [],
      },
      mailDialog: {
        dialog: false,
        tmpTos: ["contact@hotelissima.com"],
        subject: "",
        message: "",
      },
      smsDialog: {
        dialog: false,
        loading: false,
        message: "",
      },
    };
  },
  created() {
    this.init();
  },
  watch: {},
  computed: {},
  methods: {
    init() {
      get("/api/search/sites").json((sites) => {
        this.sites = sites;
      });
      this.loadTags(); //.then(() => this.loadBookings())
    },
    loadTags() {
      return get("/api/search/tags")
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", err.toString());
        })
        .json((tags) => {
          this.tags = tags;
          this.selectedTags = this.tags.map((t) => t.id);
        });
    },
    loadBookings() {
      this.listing.loading = true;
      return post("/api/tags/bookings", {
        tags: this.selectedTags,
        site: this.site,
        tripDateStart: this.tripDateStart,
        tripDateStop: this.tripDateStop,
      })
        .badRequest((err) => {
          EventBus.$emit("toaster-msg", err.toString());
        })
        .json((bookings) => {
          this.listing.selectedBookings = [];
          this.listing.bookings = bookings.map((b) => {
            return { ...b, ...{ id: b.bookingId } };
          });
          this.listing.loading = false;
        });
    },
    openMailcomposer() {
      this.mailDialog.dialog = true;
    },
    editTags(item) {
      const tags = item.tagnames.map((i) => i.trim());
      this.editTagDialog.selectedTags = this.tags.reduce((acc, curr) => {
        return tags.indexOf(curr.name) > -1 ? [...acc, ...[curr]] : acc;
      }, []);
      this.editTagDialog.bookingId = item.bookingId;
      this.editTagDialog.dialog = true;
    },
    tagging() {
      const obj = {
        bookingIds: [this.editTagDialog.bookingId],
        tagIds: this.editTagDialog.selectedTags,
      };

      post("/api/tags/tagging", obj).res(() => {
        EventBus.$emit("toaster-msg", "Etiquettes appliquées");
        this.loadBookings().then(() => {
          this.editTagDialog.dialog = false;
        });
      });
    },
    sendEmails(email) {
      this.mailDialog.loading = true;
      const sends = this.listing.selectedBookings.map((booking) => {
        const richEmail = {
          ...email,
          ...{ to: [booking.email] },
          ...{ cc: [...["contact@hotelissima.com"], ...email.cc] },
          ...{ cci: email.cci}
        };

        if (booking.id < 300000) {
          return post(`/api/mail/sendOldCustomer/${booking.id}`, richEmail);
        } else {
          return post(`/api/mail/sendCustomer/${booking.id}`, richEmail);
        }
      });
      Promise.all(sends).then(() => {
        this.mailDialog.loading = false;
        this.mailDialog.dialog = false;
      });
    },
    sendSmsBulk(sms) {
      this.smsDialog.loading = true;
      const smsList = this.listing.selectedBookings.map((booking) => {
        return {
          bookingId: booking.id,
          clientEmail: booking.email,
          siteId: booking.siteId,
          message: sms.message,
        };
      });

      post("/api/sms/bulk", smsList).res(() => {
        EventBus.$emit("toaster-msg", "Sms envoyés");
        this.smsDialog.loading = false;
        this.smsDialog.dialog = false;
      });
    },
    cancelSelection() {
      this.$root
        .$confirm(
          this.$t("confirm"),
          `Souhaitez-vous annuler et générer des à valoir pour ${this.listing.selectedBookings.length} dossiers ?`,
          { width: 350 }
        )
        .then((confirm) => {
          if (confirm) {
            const cancels = this.listing.selectedBookings.map((b) => {
              return post(`/api/booking/cancel/${b.bookingId}/covid`).res(
                (r) => r
              );
            });
            Promise.all(cancels).then(() => {
              EventBus.$emit("toaster-msg", "Avoir/À Valoir envoyés");
              this.loadBookings();
            });
          }
        });
    },
    toggleAll() {
      if (this.listing.selectedBookings.length)
        this.listing.selectedBookings = [];
      else this.listing.selectedBookings = this.listing.bookings.slice();
    },
    copy() {
      const rows = this.listing.bookings.map((i) => {
        var pnrs = [
          ...new Set(
            [...i.flights.join("").matchAll(/\[([a-zA-Z0-9]*)\]/g)].map(
              (pnr) => pnr[1]
            )
          ),
        ];

        return [
          i.siteName,
          i.bookingId,
          i.guestName,
          i.originalPhone,
          i.email,
          this._parseAndFormatLocalDateTime(
            i.startDate,
            this.datePatternConfig.serverLocalDate
          ),
          this._parseAndFormatLocalDateTime(
            i.stopDate,
            this.datePatternConfig.serverLocalDate
          ),
          i.hotels.join(", "),
          i.flights.join(", "),
          pnrs.join(", "),
          i.tagnames.join(", "),
        ].join("\t ");
      });
      clipboard.copyTextToClipboard(rows.join("\n"));
    },
  },
  sortByDate(a, b) {
    return this._sortDates(a, b, this.datePatternConfig.serverLocalDate);
  },

};
</script>
