<template>
  <v-row dense>
    <v-col class="align-center">
      <v-card flat class="pa-4">
        <div
            :style="{margin: '4px auto' ,width: $vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'md'? '100%' : '75%'}">

          <v-row no-gutters dense>
            <v-col cols="12" class="flex">
              <v-row dense>
                <v-col class="d-flex">
                  <p class="mb-0 font-weight-bold ">{{ data.name }} {{ data.code }}</p>
                  <v-chip
                      x-small
                      label
                      flat
                      text-color="white"
                      class="ml-2 "
                      :class="classStatus + ' ' + borderStatus + '--text' ">
                    {{ statusName }}
                  </v-chip>
                </v-col>
              </v-row>
              <v-row >
                <v-col cols="6" md="4">
                  <p class="mb-0">{{ data.address }}</p>
                  <p v-if="data.address2" class="mb-0">{{ data.address2 }}</p>
                  <p class="mb-0">{{ data.postcode }} {{ data.city }} {{ data.country?.name }} </p>
                  <v-chip label outlined x-small v-for="(rc,ind) in getNameMarketingAreas(data.marketingAreaIds)" :key="ind">{{rc}}</v-chip>
                  <v-chip label outlined x-small v-for="(n,ind) in getNameNetworks(data.networks)" :key="'10'+ind">{{n}}</v-chip>
                </v-col>
                <v-col  cols="6" md="4">
                  <v-row dense>
                    <v-icon small>
                      mdi-email
                    </v-icon>
                    <p class="mb-0 ml-2">{{ data.email }}</p>
                  </v-row>
                  <v-row  dense>
                    <v-icon small>
                      mdi-phone
                    </v-icon>
                    <p class="mb-0 ml-2">{{ data.phone }}</p>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4">
                  <v-row dense>
                    <b>Licence: </b>
                    <p class="mb-0 ml-2">{{ data.licenceNumber }}</p>
                  </v-row>
                  <v-row  dense>
                    <b>RCS: </b>
                    <p class="mb-0 ml-2">{{ data.rcs }}</p>
                  </v-row>
                  <v-row  dense>
                    <b>Siren: </b>
                    <p class="mb-0 ml-2">{{ data.siren }}</p>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row dense no-gutters class="mt-4" >
            <v-col cols="12">
              <v-chip x-small class="ma-2" color="light-blue darken-2" label text-color="white">
                <v-icon left x-small>
                  mdi-currency-eur
                </v-icon>
                {{getNamePayment(data.paymentTermId)}}
              </v-chip>

              <v-chip x-small class="ma-2" color="light-blue darken-2" label text-color="white">
                <v-icon left x-small>
                  mdi-label
                </v-icon>
                {{ data.publicPriceclass?.name}}
              </v-chip>

              <v-chip x-small class="ma-2" color="light-blue darken-2" label text-color="white">
                <v-icon left x-small>
                  mdi-label
                </v-icon>
                {{ data.strokePriceclass?.name}}
              </v-chip>

              <v-chip x-small class="ma-2" color="light-blue darken-2" label text-color="white">
                <v-icon left x-small>
                  mdi-label
                </v-icon>
                {{ data.flightPriceclass?.name}}
              </v-chip>

              <v-chip x-small class="ma-2" color="light-blue darken-2" label text-color="white">
                <v-icon left x-small>
                  mdi-cart-arrow-up
                </v-icon>
                Com.:
                {{ data.commission}}
              </v-chip>

              <v-chip x-small class="ma-2" color="light-blue darken-2" label text-color="white">
                <v-icon left x-small>
                  mdi-cart-arrow-up
                </v-icon>

                Com. Vol:
                {{ data.flightCommission}}
              </v-chip>

              <v-chip x-small class="ma-2" color="light-blue darken-2" label text-color="white">
                <v-icon left x-small>
                  mdi-counter
                </v-icon>
                Options max:
                {{ data.optionMax}}
              </v-chip>

            </v-col>
          </v-row>
          <v-row dense no-gutters  class="mt-4">
            <v-col md="6" cols="12">
              <p class="mb-0 font-weight-bold">Mentions légales</p>
              <p class="mb-0">{{ data.mentions }}</p>
            </v-col>
          </v-row>

        </div>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import {get} from "@/utils/api";

export default {
  data: () => {
    return {
      marketingAreas: [],
      statusName: "",
      borderStatus: "",
      classStatus: "",
      paymentTerms: []
    }
  },
  props: {
    data: {
      type: Object,
      required: false,
    },
  },
  async created() {
    await this.getMarketingAreas()
    await this.getPaymentTerms()
    this.getStatus(this.data.status)
  },
  methods: {
    async getMarketingAreas() {
      const url = "/api/search/marketingarea";

      await get(url)
          .badRequest((br) => {
            console.log("Error", br);
          })
          .json((data) => {
            this.marketingAreas = data;
          });
    },
    getNameMarketingAreas(marketingAreaIds) {
      return marketingAreaIds ? this.marketingAreas.filter(m => marketingAreaIds == m.id).map(m => m.name) : null
    },
    getPaymentTerms() {
      return get("/api/search/paymentterms").json((paymentTerms) => {
        this.paymentTerms = paymentTerms;
      });
    },
    getNamePayment(id) {
      const payment = this.paymentTerms.find(p => p.id == id)
      return payment != undefined ? payment.name + " " + this.$t("paymentterm." + payment.type) : ""
    },
    getStatus(status) {
      switch (status) {
        case "validated":
          this.statusName = "Validée"
          this.classStatus = "green lighten-4"
          this.borderStatus = "green"
          break;
        case "pending":
          this.statusName = "En attente de validation"
          this.classStatus = "orange lighten-4"
          this.borderStatus = "orange"
          break;
        case "innactive":
          this.statusName = "Désactivée"
          this.classStatus = "red lighten-4"
          this.borderStatus = "red"
          break;
      }
    },
    getNameNetworks(networks) {
      return networks != null && networks.length > 0 ? networks.map(n => n.name) : []
    }
  },
}
</script>