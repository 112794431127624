<template>
    <v-card
      :elevation="1"
      rounded="lg"
      class="d-flex flex-row justify-center pa-4"
      style="height:100%; width:100%"
    >
      <div
        class="d-flex flex-column justify-center"
        style="width: 100%"
        v-if="item.isPrice"
      >
        <span class="text-lg font-weight-bold"> {{ title }}</span>
        <span class="title font-weight-black text-center text-white">
          {{ getValue(item) }}</span
        >
        <div class="d-flex flex-row justify-space-between text-white">
          <span>{{ getOldValue(item) }}</span>
          <v-chip
            outlined
            small
            :color="getDiff(item)[0] === '-' ? 'red' : 'green'"
          >

            {{ getDiff(item) }}
          </v-chip>
        </div>
      </div>
      <div
        class="d-flex flex-column justify-center"
        style="width: 100%"
        v-else-if="item.isPercent"
      >
        <span class="text-lg font-weight-bold"> {{ title }}</span>
        <span class="title font-weight-black text-center text-white">
          {{ formatDecimal(item.value) }}%</span
        >
        <div class="d-flex flex-row justify-space-between text-white">
          <span>{{ formatDecimal(item.old) }}%</span>
          <v-chip
            outlined
            small
            :color="lessIsBetter
                    ? getDiff(item)[0] === '-' ? 'green' : 'red'
                    : getDiff(item)[0] === '-' ? 'red' : 'green'"
          >

            {{ getDiff(item) }}
          </v-chip>
        </div>
      </div>
      <div
        class="d-flex flex-column justify-center"
        style="width: 100%"
        v-else
      >
        <span class="text-lg font-weight-bold"> {{ title }}</span>
        <span class="title font-weight-black text-center text-white">
          {{ formatDecimal(item.value) }}</span
        >
        <div class="d-flex flex-row justify-space-between text-white">
          <span>{{ formatDecimal(item.old) }}</span>
          <v-chip
            outlined
            small
            :color="getDiff(item)[0] === '-' ? 'red' : 'green'"
          >

            {{ getDiff(item) }}
          </v-chip>
        </div>
      </div>
    </v-card>
</template>
<script>

export default {
  props: {
    item: {type: Object, required:true},
    title:{type:String, required:true},
    lessIsBetter:{type:Boolean, default:false},
  },
  methods: {
     getValue(item) {
      return this._formatCurrency(Math.round(item.value), "EUR");
    },
    getOldValue(item) {
      return this._formatCurrency(Math.round(item.old), "EUR");
    },
    formatDecimal(value){
      return this._formatDecimal(value)
    },
    getDiff(item) {
      const result = this._formatEvolutionPercent(item.old, item.value)
      return result[0] == "-" ? result : "+" + result;
    },
  }
}
</script>