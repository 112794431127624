import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Booking from "./views/booking/Booking.vue";
import BookingList from "./views/booking/BookingList.vue";
import Options from "./views/booking/Options.vue";
import SupplierPrestationEdit from "./views/booking/SupplierPrestationEdit.vue";
import Reviews from "./views/booking/Reviews.vue";
import CalendarSearch from "./views/calendar/CalendarSearch.vue";
import Login from "./views/Login.vue";
import CustomerEdition from "@/views/customer/CustomerEdition.vue";
import {Role, authMethods} from "@/utils/user";
import DocumentsRoutes from "@/routes/documents-router";
import AccountingRoutes from "@/routes/accounting-router";
import ExtranetRoutes from "@/routes/extranet-router";
import StatsRoutes from "@/routes/stats-router";
import Noop from "@/components/Noop";
import ActionsRoutes from "@/routes/actions-router";
import CustomersList from "./views/customer/CustomersList";
import BookingReport from "./views/booking/BookingReport";
import Users from "./views/admin/Users";
import Live from "./views/Live";
//import SupplierAccountingStats from "./views/stats/SupplierAccountingStats";
import PackageRoutes from "@/routes/package-router";
import HLRoutes from "@/routes/hl-router";
import CanceledBooking from "@/views/booking/CanceledBooking.vue";
import BigQueryAnalytics from "@/views/admin/BigQueryAnalytics.vue";
import ExportVentes from "@/views/accounting/exports/ExportVentes.vue";
import ExportClients from "@/views/accounting/exports/ExportClients.vue";
import ExportEcritures from "@/views/accounting/exports/ExportEcritures.vue";
import ExportCheck from "@/views/accounting/exports/ExportCheck.vue";
import AverageFlight from "@/views/averageReview/AverageFlight.vue";
import AverageHotel from "@/views/averageReview/AverageHotel.vue";
import ListLead from "@/views/lead/ListLead.vue";
import Lead from "@/views/lead/Lead.vue";
import PhoneCalls from "@/views/admin/PhoneCalls.vue";
import Forbidden from "@/views/Forbidden.vue";

Vue.use(Router);

const routes = [
  {
    path: "/forbidden",
    name: "Forbidden",
    component: Forbidden,
    meta: {unsecure: true,}
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      plainLayout: true,
      unsecure: true,
    },
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {roles: [Role.resas]}
  },
  {
    path: "/live",
    name: "Live",
    component: Live,
    meta: {roles: [Role.resas]}
  },
  {
    path: "/vente",
    name: "Vente",
    component: Noop,
    children: [
      {
        path: "dossier",
        name: "BookingList",
        component: BookingList,
        props: true,
        meta: {
          roles: [Role.resas],
          breadcrumb: [{name: "Dossiers"}],
        },
      },
      {
        path: "dossier/:id",
        name: "Booking",
        component: Booking,
        props: true,
        meta: {
          roles: [Role.resas],
          breadcrumb: [
            {name: "Dossiers", route: {name: "BookingList"}},
            {name: (ids) => `Booking n°${ids[0]}`},
          ],
        },
      },
      {
        path: "options",
        name: "Options",
        component: Options,
        props: true,
        meta: {
          roles: [Role.resas],
          breadcrumb: [{name: "Options"}],
        },
      },
      {
        path: "dossier-annule",
        name: "CancelledBookings",
        component: CanceledBooking,
        props: true,
        meta: {
          roles: [Role.resas],
          breadcrumb: [{name: "CancelledBookings"}],
        },
      },
      {
        path: "/calendrier",
        name: "CalendarSearch",
        component: CalendarSearch,
        meta: {
          roles: [Role.resas],
          breadcrumb: [{name: "Calendrier de prix"}],
        },
      },
      {
        path: "/fournisseurs-prestation",
        name: "SupplierPrestationEdit",
        component: SupplierPrestationEdit,
        meta: {
          roles: [Role.resas],
          breadcrumb: [{name: "Fournisseurs prestation"}],
        },
      },
      {
        path: "/avis",
        name: "Reviews",
        component: Noop,
        meta: {
          roles: [Role.resas],
          breadcrumb: [{name: "Avis"}],
        },
        children: [
          {
            path: "moderation",
            name: "avis-moderation",
            component: Reviews,
            metas: {roles: [Role.resas]}
          },
          {
            path: "hotel",
            name: "avis-hotels",
            component: AverageHotel,
            metas: {roles: [Role.resas]}
          },
          {
            path: "vol",
            name: "avis-vols",
            component: AverageFlight,
            metas: {roles: [Role.resas]}
          },
        ],
      },
      {
        path: "/report-dossier",
        name: "booking.report",
        component: BookingReport,
        meta: {
          roles: [Role.resas],
          breadcrumb: [{name: "Dossiers"}],
        },
      },
      {
        path: "/list-leads",
        name: "listleads",
        component: ListLead,
        meta: {
          roles: [Role.resas],
          breadcrumb: [{name: "Leads"}],
        },
      },
      {
        path: "/lead/:id",
        name: "lead",
        component: Lead,
        props: true,
        meta: {
          roles: [Role.resas],
          breadcrumb: [{name: "Leads"}],
        },
      },

    ],
  },
  {
    path: "/customer",
    name: "Clients",
    component: Noop,
    children: [
      {
        path: "/customer/:id",
        name: "customer-edition",
        component: CustomerEdition,
        props: true,
        meta: {
          roles: [Role.resas],
          breadcrumb: [
            {name: "Clients"},
            {name: (ids) => `Client n°${ids[0]}`},
          ],
        },
      },
      {
        path: "/customer",
        name: "customer-search",
        component: CustomersList,
        props: true,
        meta: {
          roles: [Role.resas],
          breadcrumb: [{name: "Clients"}],
        },
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    component: Noop,
    children: [
      {
        path: "users",
        name: "AdminUsers",
        component: Users,
      },
      {
        path: "analyze",
        name: "BigQueryAnalytics",
        component: BigQueryAnalytics,
      },
      {
        path: "calls",
        name: "PhoneCalls",
        component: PhoneCalls,
        meta: {
          roles: [Role.resas]
        }
      },
    ],
  },
];

const router = new Router({
  mode: "history",
  routes: routes.concat(
    DocumentsRoutes,
    AccountingRoutes,
    ExtranetRoutes,
    StatsRoutes,
    HLRoutes,
    ActionsRoutes,
    PackageRoutes
    //, ...
  ),
});
router.beforeEach((to, from, next) => {


  if (to.meta && to.meta.unsecure) {
    next()
  } else if (authMethods._getToken() == null || authMethods._isTokenExpired(authMethods._getToken())) {
    authMethods._removeToken();
    const query = to.fullPath !== "/" ? {redirect: to.fullPath} : {}
    next({
      name: "Login",
      query: query,
    });
  } else {

    const routeRoles = (to.meta && to.meta.roles) || []

    const user = authMethods._user();
    const isAdmin = user.roles.some(r => r.id === Role.admin)
    const hasRole = routeRoles.some(role => user.roles.some(ur => ur.id === role))

    if (!isAdmin && !hasRole) {
      next({name: 'Forbidden'})
    }

    next();
  }

  /*
  if (to.name == "Booking" && !authMethods._isAdmin()) {
    return next({name: "Home"});
  }
  if (to.meta && to.meta.unsecure) return next();
  else {
    if (
      authMethods._getToken() == null ||
      authMethods._isTokenExpired(authMethods._getToken())
    ) {
      authMethods._removeToken();
      return next({
        name: "Login",
        query: {redirect: to.fullPath},
      });
    } else {
      return next();
    }
  }
   */
});

export default router;
