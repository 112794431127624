import { render, staticRenderFns } from "./WidgetContainer.vue?vue&type=template&id=c1d2fbaa&scoped=true&"
import script from "./WidgetContainer.vue?vue&type=script&lang=js&"
export * from "./WidgetContainer.vue?vue&type=script&lang=js&"
import style0 from "./WidgetContainer.vue?vue&type=style&index=0&id=c1d2fbaa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1d2fbaa",
  null
  
)

export default component.exports