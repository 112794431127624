<template>
  <layout :drawerShow="_isDesktop()" :drawerWidth="350">
    <template slot="drawer">
      <v-row dense>
        <v-col cols="12">
          <v-autocomplete
            v-model="searchform.supplierAccountings"
            :items="supplierAccounts"
            clearable
            chips
            color="blue-grey lighten-2"
            label="Fournisseurs"
            item-text="text"
            item-value="value"
            multiple
          >
            <template slot="selection" slot-scope="{ item, index }">
              <span>
                <v-chip small>{{ item.text }}</v-chip>
              </span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" class>
          <v-subheader light class="pa-0 mb-0 mx-0 mt-2 form-subheader"
            >Dates de voyage</v-subheader
          >
          <v-divider></v-divider>
        </v-col>
        <v-col cols="6">
          <smart-picker
            v-model="searchform.startDate"
            :inputOpts="{ label: $t('begin') }"
            label="Début"
            :next-picker="$refs.stopDate"
            :stopDate="searchform.stopDate"
            :min="format(new Date(),'dd/MM/yyyy')"
          />
        </v-col>
        <v-col cols="6">
          <smart-picker
            v-model="searchform.stopDate"
            :inputOpts="{ label: $t('end') }"
            label="Fin"
            :startDate="searchform.startDate"
            :min="searchform.startDate"
            ref="stopDate"
          />
        </v-col>
        <v-col cols="12" class="mt-4">
          <v-subheader light class="pa-0 mb-0 mx-0 mt-2 form-subheader"
          >Site</v-subheader
          >
          <v-divider></v-divider>
        </v-col>
        <v-col class="pr-1 pb-0" cols="12">
          <v-select
              v-model="searchform.site"
              :items="sites"
              clearable
              item-text="name"
              item-value="id"
              label="Site"
              class="pa-1"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mt-4" cols="12">
          <smart-btn block primary @click.native="search" :loading="loading"
            >Rechercher</smart-btn
          >
        </v-col>
      </v-row>
    </template>
    <template slot="content">
      <v-container fluid>
        <v-row dense justify="space-between">
          <v-col cols="12">
            <v-data-table :loading="listing.loading" :headers="listing.headers" :items="listing.items">

            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </layout>
</template>

<script>
import Layout from "../Layout";
import { get, post } from "../../utils/api";
import ColorMixin from "../../components/mixins/ColorMixin";
import FormMixin from "../../components/mixins/FormMixin";
import { EventBus } from "@/components/commons/event-bus";
import {format, parse} from "date-fns";

export default {
  name: "PurchaseStats",
  mixins: [ColorMixin, FormMixin],
  components: {
    Layout,
  },
  data() {
    return {
      valid: true,
      loading: false,
      supplierAccounts: [],
      sites:[],
      searchform: {
        supplierAccountings: [],
        startDate: format(new Date(),'dd/MM/yyyy'),
        stopDate: null,
        site:null
      },
      listing: {
        loading: false,
        rowsPerPageItems: [200, 500, { text: "Tous", value: -1 }],
        pagination: {
          rowsPerPage: 200,
        },
        headers: [],
        items: [],
      },
    };
  },
  created() {
    this.getSupplierAccounts();
    this.getSite()
    this.search()
  },
  methods: {
    format,
    async search() {
      this.listing.loading = true;

      const purchaseUrl = "/api/booking/purchasesum";
      const sellUrl = "/api/booking/sellsum";
      const form = {
        ...this.searchform,
        startDate: format(parse(this.searchform.startDate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
        stopDate: this.searchform.stopDate != null
            ? format(parse(this.searchform.stopDate, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd')
            : null
      };

      const [purchaseData, sellData] = await Promise.all([
        post(purchaseUrl, form).json(),
        post(sellUrl, form).json()
      ]);

      const allMonths = Array.from(new Set([
        ...purchaseData.map(item => item.month),
        ...sellData.map(item => item.month)
      ])).sort();

      this.listing.headers = [
        {
          text: "Site",
          value: "siteId",
          align: "start",
          sortable: true
        },
        ...allMonths.map(month => (
            {
              text: month,
              value: month,
              align: "center"
            }
          )
        )
      ];

      const groupedData = {};

      this.sites.forEach(site => {
        if (!groupedData[site.id]) {
          groupedData[site.id] = {
            purchase: { siteId: `${site.name} - Achat` },
            sell: { siteId: `${site.name} - Vente` }
          };
        }
      });

      purchaseData.forEach(dataPurchase => {
        if (!groupedData[dataPurchase.siteId]) return;

        groupedData[dataPurchase.siteId].purchase[dataPurchase.month] = dataPurchase.totalEuroAmmount || 0;
      })

      sellData.forEach(dataSell => {
        if (!groupedData[dataSell.siteId]) return;

        groupedData[dataSell.siteId].sell[dataSell.month] = dataSell.totalSell || 0;
      })

      this.listing.items = Object.values(groupedData).flatMap(({ purchase, sell }) => {
        allMonths.forEach(month => {
          if (!purchase[month]) purchase[month] = 0;
          if (!sell[month]) sell[month] = 0;
        });
        return [purchase, sell];
      });

      this.listing.loading = false;
    },
    getSupplierAccounts() {
      const url = `/api/supplier/get/foraccounting`;

      get(url).json((r) => {
        let accounts = r.map((account) => {
          return { text: account.name, value: account.id };
        });

        this.supplierAccounts = accounts;
      });
    },
    getSite(){
      get("/api/search/sites")
          .json((sites) => {
            this.sites = sites
          })
    },
  },
};
</script>

<style scoped></style>
