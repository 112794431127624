<template>
  <div class="callback-popover" v-show="popover ">
    <div class="rounded-lg" :class="connected ? 'online':'offline'"
         v-if="callState != null && connected && !panelIsClose && (callState !== 'Idle' || afterCall)">
      <v-card-text class="">
        <div class="d-flex flex-column justify-start">
          <div class="d-flex flex-row  justify-space-between align-center" :class="afterCall ? '' :'mb-2' ">
            <span class="caption" v-if="!afterCall">{{ getStatusEvent }}</span>

            <div style="cursor: pointer" v-if="!afterCall" @click="close()">
              <v-icon class="text-black">close</v-icon>
            </div>
          </div>
          <div class="d-flex flex-row justify-space-between">
            <span v-if="getCallInfo.name">{{ getCallInfo.name }}</span>
            <span v-else>Client inconnu</span>
            <div class="d-flex " style="cursor: pointer" v-if="afterCall" @click="close()">
              <site-label v-if="callSite" :sitename="callSite" class="mr-1"/>
              <v-icon class="text-black">close</v-icon>
            </div>
            <site-label v-else-if="callSite" :sitename="callSite"/>
          </div>
          <div class="d-flex justify-space-between">
            <span>Tel: {{ getCallInfo.number }}</span>
            <div v-if="callState === 'Idle' && hadCall">
              <smart-btn x-small v-if="showCreateLeadBtn && customersActiveLead == null" class="mx-1"
                         @click.native="openDialogCreationLead()">
                Créer Lead
              </smart-btn>
              <smart-btn x-small v-else-if="showCreateLeadBtn && customersActiveLead != null" class="mx-1"
                         @click.native="openDialogCreationLead()">
                Lead #{{ customersActiveLead.id }}
              </smart-btn>
            </div>
          </div>
        </div>

      </v-card-text>
      <v-divider v-if="!(callState === 'Idle' && hadCall)"></v-divider>
      <v-card-actions
          v-if="callState != 'Idle' && (showCreateLeadBtn || (infoCall && (infoCall.id || infoCall.lastBookingId)))"
          class="d-flex flex-row justify-space-around">
        <smart-btn v-if="infoCall && infoCall.id" x-small class="mx-1" @click.native="toCustomerPage()">
          Fiche Client
        </smart-btn>
        <smart-btn x-small class="mx-1" @click.native="toBooking()" v-if="infoCall && infoCall.lastBookingId">
          Ref: {{ infoCall.lastBookingId }}
        </smart-btn>
        <smart-btn x-small v-if="showCreateLeadBtn && customersActiveLead == null" class="mx-1"
                   @click.native="openDialogCreationLead()">
          Créer Lead
        </smart-btn>
        <smart-btn x-small v-else-if="showCreateLeadBtn && customersActiveLead != null" class="mx-1"
                   @click.native="openDialogCreationLead()">
          Lead #{{ customersActiveLead.id }}
        </smart-btn>
      </v-card-actions>
    </div>
    <div v-else class="white rounded-lg chipOnly" :class="connected ? '':'disconnected-status'">
      <v-chip small :color="connected ? 'green' : 'red'" outlined label class="pl-0 pr-2">
        <div :class="connected ? 'green':'red'" class="connected-status"></div>
        {{ connected ? "Connecté" : "Déconnecté" }}
      </v-chip>
    </div>
    <smart-dialog
        v-model="leadCreationShow"
        title="Nouveau lead">
      <LeadCreationDialog v-if="creationForm" @done="() => closeCreationLead()" :lead-info="buildedLead()"
                          create-action="call" :after-creation-fn="(leadId) => attachClientToLead(leadId)"
                          :userList="userList"/>
      <template title="actions">
        <v-card-actions class="actions-btn mt-4">
          <v-spacer/>
          <smart-btn type="submit" form="creation-lead">
            <v-icon small class="mr-1">mdi-content-save</v-icon>
            Créer
          </smart-btn>
        </v-card-actions>
      </template>
    </smart-dialog>
    <smart-dialog v-model="endedCallDialog" title="Catégorisation de l'appel">
      <EndedCallDialog v-if="endedCallDialog" v-model="endedCallDialog" :call="callEvent"
                       @selected="endedCallDialog = false"/>
    </smart-dialog>
  </div>
</template>
<script>
import SiteLabel from "@/components/commons/SiteLabel.vue";
import {get, post} from "@/utils/api";
import LeadCreationDialog from "@/views/lead/LeadCreationDialog.vue";
import jwtdecode from "jwt-decode";
import {EventBus} from "@/components/commons/event-bus";
import EndedCallDialog from "@/components/commons/EndedCallDialog.vue";

export default {
  name: "CallbackPopover",
  components: {EndedCallDialog, LeadCreationDialog, SiteLabel},
  component: {},
  props: {},
  data(vm) {
    return {
      customersActiveLead: null,
      userRegistered: true,
      tryConnectCount: 0,
      maxTryConnect: 10,
      showPopover: false,
      isRinging: false,
      connected: false,
      callState: null,
      callSite: null,
      callEvent: null,
      endedCallDialog: false,
      hadCall: false,
      infoCall: {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        lastBookingId: null
      },
      phone: null,
      phoneCallId: null,
      connection: null,
      intervalId: null,
      panelIsClose: false,
      errorClose: false,
      leadCreationShow: false,
      creationForm: false,
      event: null,
      userList: [],

      attachClientToLead: async (leadId) => {
        if (leadId != null && this.infoCall && this.infoCall.id != null) {
          return get(`/api/lead/customer/${leadId}/${this.infoCall.id}`).json(() => {
            this.$router.push({
              name: "lead",
              params: {id: leadId},
            });
          })
        } else {
          const action = await this.addAction(leadId).res(() => {
            this.$router.push({
              name: "lead",
              params: {id: leadId},
            })
          })
          return action
        }
      }
    }
  },
  watch: {},
  created() {
    this.init()
    get("/api/search/users").json(rs => {
      this.userList = rs.map(u => u = {...u, fullName: `${u.firstname} ${u.lastname}`})
    })

  },
  mounted() {
  },
  computed: {
    afterCall() {
      return this.callState === 'Idle' && this.hadCall
    },
    showCardActions() {
      return this.showCreateLeadBtn || (this.infoCall && (this.infoCall.lastBookingId || this.infoCall.id))
    },
    showCreateLeadBtn() {
      return this.callEvent != null && ['HLF', 'HLI', 'HOT', 'ZIL'].some(e => this.callEvent.site === e)
    },
    popover() {
      if (this.getToken() != null) {
        return this.currentUser != null && (this.isAdmin || this.isExternalLanguageAdmin);
      } else return false
    },
    getCallInfo: function () {
      return {
        name: this.infoCall && this.infoCall.firstname ? [this.infoCall.firstname, this.infoCall.lastname.toUpperCase()].join(" ") : null,
        number: this.phone ? this.phone : "Numéro inconnu",
        id: this.infoCall ? this.infoCall.id : null
      }
    },
    getStatusEvent: function () {
      switch (this.callState) {
        case 'Ringing':
          return "Appel Entrant"
        case 'Answered':
          return "Appel en cours"
        case 'Calling' :
          return "Appel sortant en cours"
        case 'Hangup' :
          return "Appel terminé"
        case 'Idle':
          return "Rien à signaler"
        default:
          return "Rien à signaler"
      }
    },
  },
  methods: {
    init: function () {
      const isDev = process.env.NODE_ENV == 'development'

      if (this.popover) {
        const self = this
        const url = isDev ? `ws://localhost:9000` : `wss://resas.smart-be.com`
        this.connection = new WebSocket(`${url}/api/phonecall/user/${this.currentUser.id}/status`)

        this.connection.onopen = function () {
          console.log("Socket Connected")
          self.connected = true
          self.subscribeWs();
          if (self.intervalId) {
            clearInterval(self.intervalId)
            self.intervalId = null
          }
        }

        this.connection.onmessage = function (event) {
          self.event = JSON.parse(event.data)
          self.callEvent = JSON.parse(event.data)
          self.connected = self.connection.readyState === 1

          self.callState = self.event.lastEvent

          self.phoneCallId = self.event.phoneCallId
          if (self.event.details) {
            self.infoCall = self.event.details
          }
          self.phone = self.event.phone

          if (self.event.lastEvent === 'userNotRegister') {
            self.userRegistered = false;
          } else {

            switch (self.event.site) {
              case "HOT" :
                self.callSite = "Hotelissima"
                self.btns = self.hotzilBtns
                break;
              case "HLF" :
                self.callSite = "HLagons"
                self.btns = self.hlBtns
                break;
              case "HLI" :
                self.callSite = "HLagonsItaly"
                self.btns = self.hlBtns
                break;
              case "ZIL" :
                self.btns = self.hotzilBtns
                self.callSite = "ZilMaurice"
                break;
            }

            if (self.callState == 'Calling' || self.callState == "Answered") {
              self.hadCall = true
              self.panelIsClose = false
            }
            if (!self.endedCallDialog) {
              if (self.callState == 'Hangup' && !self.callEvent.transfer) {
                self.endedCallDialog = true
              }
            }

            if (self.event.lastEvent !== 'Idle') {
              get(`/api/lead/active/${self.infoCall.id} `).json((rs) => {
                self.customersActiveLead = rs
              })
            }
          }
        }

        this.connection.onclose = function () {
          self.connected = false
          if (this.userRegistered) {
            console.log("Attempt to reconnect to WebSocket")
            self.intervalId = setInterval(self.tryConnect, 1000)
          }
        }
      }
    },
    tryConnect: function () {
      this.tryConnectCount++;
      if (!this.connected && this.tryConnectCount !== this.maxTryConnect && this.userRegistered) {
        this.init()
      }
    },
    subscribeWs: function () {
      this.connection.send(JSON.stringify("subscribe"));
    },
    toCustomerPage: function () {
      if (this.infoCall && this.infoCall.id) {
        this.$router.push({name: "customer-edition", params: {id: this.infoCall.id}});
      }
    },
    toBooking: function () {
      if (this.infoCall && this.infoCall.lastBookingId) {
        this.$router.push({name: 'Booking', params: {id: this.infoCall.lastBookingId}});
      }
    },
    close() {
      const url = `/api/phonecall/closepanel/${this.phoneCallId}`
      this.panelIsClose = true
      this.hadCall = false
      get(url)
    },
    buildedLead() {
      return {
        clientId: this.infoCall ? this.infoCall.id : null,
        site: 1,
        virtualSite: "Hotelissima",
        budget: null,
        status: "open",
        message: null,
        source: 'phone',
        phoneCallId: this.phoneCallId,
        score: 2,
        user: jwtdecode(localStorage.getItem("auth")),
        destinations: [],
        dataSup: {event: this.callEvent, infoCall: this.infoCall},
        phonecalls: 1
      }
    },
    openDialogCreationLead() {
      this.leadCreationShow = true
      this.creationForm = true
    },
    addAction(leadId) {
      const user = jwtdecode(localStorage.getItem("auth"))

      const action = {
        type: "call",
        message: `Création du lead suite à l'appel du ${this.getCallInfo.number}`,
        leadId: leadId,
        user: this.userList.find(u => u.id == user.id),
        phonecallId: this.phoneCallId
      }
      return post("/api/lead/add/action", action)
    },
    closeCreationLead() {
      this.leadCreationShow = false
      EventBus.$emit('reloadListLead', {})
    },

  }
}
</script>
<style scoped>

.callback-popover {
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 50;
  width: 300px;
}

.connected-status {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin: 6px;
}

.chipOnly {
  padding: 2px;
  width: 88px;
  float: right;
}

.online {
  background-color: white;
  border-left: 7px solid #67AD5B;
}

.offline {
  background-color: white;
  border-left: 7px solid red;
}
</style>