<script>
export default {
  name: "Forbidden"
}
</script>

<template>
<div style="text-align: center;margin-top: 50px">
  <h1>403 - Forbidden</h1>
  <p>Access to this resource is denied. Please contact the administrator if you think you should be allowed.</p>
</div>
</template>