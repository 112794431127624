<template>
  <collapse-panel :collapsable="false" v-if="booking" :fillHeight="fillHeight">
    <div slot="title">
      <p class="caption grey--text lighten-1 font-weight-medium mb-0">Contact dossier</p>
    </div>
    <div slot="content">
      <template v-if="booking.customer && booking.customer.id">
        <v-row>
          <v-col cols="12">
            <div v-if="booking.customer && booking.customer.id && !isAgency">
              <span class="subheading font-weight-medium"
              >{{ booking.customer.firstname }}
                {{ booking.customer.lastname.toUpperCase() }}</span
              >
              <div>
                <span>{{ booking.customer.address.postalcode }}</span>
                <span class="ml-1" v-if="booking.customer.address.city">{{
                    booking.customer.address.city
                  }}</span>
                <span class="ml-1">{{ booking.customer.address.country }}</span>
              </div>
            </div>
            <div v-else>
              <span class="subheading font-weight-medium">
                Agence: {{ booking.customer.name }}
              </span>
              <div>
                <span class="">{{ booking.customer.address.postalcode }}</span>
                <span class="ml-1">{{ booking.customer.address.city }}</span>
                <span class="ml-1">{{ booking.customer.address.country }}</span>
              </div>
              <div class="d-flex align-center">
                <v-icon small class="v-middle">mdi-email</v-icon>
                <p class="mb-0 ml-2">{{ booking.customer.email }}</p>
              </div>
              <div class="d-flex align-center">
                <v-icon small class="v-middle">mdi-phone</v-icon>
                <div v-if="booking.customer.phoneCountry" class="ml-2">
                  <img v-bind:src="'/images/svg/'+booking.customer.phoneCountry + '.svg'"/>
                </div>
                <p class="mb-0 ml-2">
                  {{ booking.customer.phone != "" ? booking.customer.phone : "-" }}
                </p>
              </div>
              <div class="mt-2">
                <v-btn
                    text
                    x-small
                    outlined
                    :to="{
                    name: 'CrmAgencyDetails',
                    params: { id: booking.agencyId },
                  }"
                >
                  Voir l'agence
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <v-row dense v-if="isAgency">
              <v-col cols="12">
                <v-row dense no-gutters>
                  <v-col cols="12">
                    {{ booking.customer.agent.firstname }}
                    {{ booking.customer.agent.lastname }}
                  </v-col>
                  <v-col dense cols="12">
                    <div class="d-flex align-center">
                      <v-icon small>mdi-email</v-icon>
                      <p class="mb-0 ml-2">{{ booking.customer.agent.email }}</p>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <div class="d-flex align-center">
                      <v-icon small>mdi-phone</v-icon>
                      <div v-if="booking.customer.phoneCountry" class="ml-2">
                        <img v-bind:src="'/images/svg/'+booking.customer.phoneCountry + '.svg'"/>
                      </div>
                      <p class="mb-0 ml-2">
                        {{ booking.customer.agent.telephone != "" ? booking.customer.agent.telephone : "-" }}</p>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" class="mt-2">
                <div v-for="email in otherEmails" :key="email" class="d-flex align-center">
                  <v-icon small class="v-middle">mdi-email</v-icon>
                  <p class="mb-0 ml-2">{{ email }}</p>
                </div>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" style="padding: 0 12px">
                <div class="d-flex align-center">
                  <v-icon small class="v-middle">mdi-email</v-icon>
                  <p class="mb-0 ml-2">{{ booking.customer.email }}</p>
                </div>
                <div v-for="email in otherEmails" :key="email" class="d-flex align-center">
                  <v-icon small class="v-middle">mdi-email</v-icon>
                  <p class="mb-0 ml-2">{{ email }}</p>
                </div>
              </v-col>
              <v-col cols="12" style="padding-top: 0px">
                <div class="d-flex align-center">
                  <v-icon small class="v-middle">mdi-phone</v-icon>
                  <div v-if="booking.customer.phoneCountry" class="ml-2">
                    <img v-bind:src="'/images/svg/'+booking.customer.phoneCountry + '.svg'"/>
                  </div>
                  <p class="mb-0 ml-2">{{ booking.customer.phone }}</p>

                </div>
                <div v-for="(phone, i) in otherPhones" :key="'phone' + i" class="d-flex align-center">
                  <v-icon size="10" class="v-middle">mdi-phone</v-icon>
                  <div v-if="booking.customer.phoneCountry" class="ml-2">
                    <img v-bind:src="'/images/svg/'+booking.customer.phoneCountry + '.svg'"/>
                  </div>
                  <p class="mb-0 ml-2">{{ phone }}</p>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row dense class>
          <v-col cols="12" class="text-center">
            Aucun client défini.
            <br/>
          </v-col>
          <v-col cols="12" class="text-center">
            <smart-btn secondary @click.native="customer.dialog = true"
            >Créer ou associer
            </smart-btn
            >
          </v-col>
        </v-row>
      </template>

      <smart-dialog
          v-model="customer.dialog"
          :width="800"
          title="Créer ou associer un client"
      >
        <booking-customer-create
            v-if="customer.dialog"
            :is-agency="isAgency"
            :saveSnapshotUrl="urlSnapchotCustomer"
            @linkCustomer="attachCustomer"
            @linkAgency="attachAgency"
            @done="creationDone()"
            :maybe-customer="booking.customer"
            :bookingid="booking.id"
        ></booking-customer-create>
      </smart-dialog>
    </div>
    <div slot="menu">
      <v-list-item @click="customer.dialog = true">
        <v-list-item-title v-if="booking.customer && booking.customer.id">
          <v-icon small>mdi-account-details</v-icon>
          Modifier client dossier
        </v-list-item-title>
        <v-list-item-title v-else>
          <v-icon small>mdi-account-plus-outline</v-icon>
          Créer ou associer
        </v-list-item-title>
      </v-list-item>
    </div>
  </collapse-panel>
</template>

<script>
import CollapsePanel from "@/components/commons/CollapsePanel";
import Availability from "@/components/commons/Availability";
import {get} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";
import CustomerAddress from "@/components/commons/CustomerAddress";
import CustomerInfos from "@/components/commons/CustomerInfos";
import FormMixin from "@/components/mixins/FormMixin";
import BookingCustomerCreate from "./BookingCustomerCreate";
import booking from "@/views/booking/Booking.vue";

export default {
  name: "BookingCustomerDetails",
  props: {
    urlOverride: {type: String, default: "/api/booking/attachcustomer", required: false},
    urlSnapchotCustomer: {type:String, default:"/api/booking", required:false},
    booking: {type: Object, required: true},
    kagnots: {type: Object, required: false},
    fillHeight: {type: Boolean, required: false, default: true}
  },
  mixins: [FormMixin],
  components: {
    BookingCustomerCreate,
    CustomerInfos,
    CustomerAddress,
    Availability,
    CollapsePanel,
  },
  data() {
    return {
      site: null,
      loadingDetail: false,

      quotationSize: null,
      bookingsSize: null,
      kagnotToValidateAmount: 0,
      regionname: null,
      networksname: null,
      commercialname: null,

      customer: {
        dialog: false,
        tab: null,
        form: {
          infos: {},
          billingAddressIsSame: true,
          address: {},
          billingAddress: {},
        },
        civilities: [
          {value: "Mister", text: "M."},
          {value: "Madam", text: "Mme"},
          {value: "Miss", text: "Mlle"},
        ],
        formloading: false,
      },
      searchCities: null,
      searchCountries: null,
    };
  },
  watch: {
    "booking.customer.id"(newVal) {
      this.loadCustomerBookingInfo();
    },
  },
  computed: {
    isAgency() {
      return this.booking.customerType === "agency";
    },
    otherEmails() {
      if (!this.customer.otherEmails) return [];
      const list = this.customer.otherEmails.join(",").split(",");
      return list.length === 1 && list[0] === "" ? [] : list;
    },
    otherPhones() {
      if (!this.customer.otherPhones) return [];
      const list = this.customer.otherPhones.join(",").split(",");
      return list.length === 1 && list[0] === "" ? [] : list;
    },
  },
  methods: {
    attachCustomer(customer) {
      return get(
          this.urlOverride + `/${this.booking.id}/${customer.id}`
      )
          .badRequest((err) => {
            EventBus.$emit(
                "toaster-msg",
                JSON.parse(err.message).errors.join(", ")
            );
          })
          .json((r) => {
            return r;
          })
          .then(() => {
            this.customer.formloading = false;
            EventBus.$emit("toaster-msg", "Client correctement rattaché");
            EventBus.$emit("reloadbooking", {});
            EventBus.$emit("reloadLead")
            this.customer.dialog = false;
          });
    },
    attachAgency({agency, agent}) {
      return get(
          `/api/booking/attachagency/${this.booking.id}/${agency.id}/${agent.id}`
      )
          .badRequest((err) => {
            EventBus.$emit(
                "toaster-msg",
                JSON.parse(err.message).errors.join(", ")
            );
          })
          .json((r) => {
            return r;
          })
          .then(() => {
            this.customer.formloading = false;
            EventBus.$emit("toaster-msg", "Agence correctement rattachée");
            EventBus.$emit("reloadbooking", {});
            EventBus.$emit("reloadLead", {});
            this.customer.dialog = false;
          });
    },
    creationDone(){
      this.customer.dialog = false;
      EventBus.$emit("reloadLead", {});
    },
    getCountrySvg() {
      return `../../assets/svg/${this.booking.customer.phoneCountry}.svg`
    }
  },
};
</script>

<style scoped>
.v-chip--label {
  border-radius: 5px;
}

>>> .v-list__tile,
.v-list__dense {
  cursor: default;
  min-height: 40px;
  height: auto !important;
}

>>> .v-list__tile__action {
  align-self: flex-start !important;
}
</style>
