//import Actions from "@/views/task/Actions";

import TickettingList from "@/views/task/TickettingList";
import TodoTask from "@/views/task/TodoTask";
import Noop from "@/components/Noop";
import PaxOnPlaceList from "@/views/task/PaxOnPlaceList";
import TagsList from "../views/task/TagsList";
import BookingLocked from "../views/task/BookingLocked";
import {Role} from "@/utils/user";

const ActionsRoutes = [
  {
    path: "/actions",
    component: Noop,
    exact: true,
    meta: {
      roles: [Role.resas],
      breadcrumb: [{ name: "Actions" }],
    },
    children: [
      {
        path: "tasks-list",
        name: "TasksList",
        component: TodoTask,
        meta: {
          roles: [Role.resas],
          breadcrumb: [
            { name: "Action", route: { name: "actions" } },
            { name: "TasksList" },
          ],
        },
      },
      {
        path: "emissions",
        name: "ticketting-list",
        component: TickettingList,
        meta: {
          roles: [Role.resas],
          breadcrumb: [
            { name: "Actions", route: { name: "actions" } },
            { name: "Emissions" },
          ],
        },
      },
      {
        path: "pax-on-place",
        name: "pax-on-place",
        component: PaxOnPlaceList,
        meta: {
          roles: [Role.resas],
          breadcrumb: [
            { name: "Action", route: { name: "actions" } },
            { name: "PaxOnPlace" },
          ],
        },
      },
      {
        path: "etiquette",
        name: "tags-list",
        component: TagsList,
        meta: {
          roles: [Role.resas],
          breadcrumb: [
            { name: "Action", route: { name: "actions" } },
            { name: "tags-list" },
          ],
        },
      },
      {
        path: "booking-locked",
        name: "booking-locked",
        component: BookingLocked,
        meta: {
          roles: [Role.resas],
          breadcrumb: [
            { name: "Action", route: { name: "actions" } },
            { name: "booking-locked" },
          ],
        },
      },
    ],
  },
];

export default ActionsRoutes;
