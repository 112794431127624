<template>
  <v-row dense>
    <v-col class="align-center">
      <v-card flat class="px-4 py-8">
        <v-row dense class="justify-center">
          <v-col cols="12" md="8">
            <v-form
                ref="agencyform"
                v-model="form.valid"
                v-on:submit.prevent="save"
                :lazy-validation="true"
            >
              <v-row dense class="justify-space-between">
                <v-col cols="12" md="4" class="align-center">
                  <v-text-field
                      label="Nom"
                      v-model="form.name"
                      dense
                      autocomplete="off"
                      :rules="rules.nonEmpty()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                      dense
                      label="Code agence"
                      v-model="form.code"
                      autocomplete="off"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="align-center">
                  <v-select
                      dense
                      label="Status agence"
                      v-model="form.status"
                      item-text="text"
                      item-value="value"
                      :items="agencyStatus"
                      :rules="rules.nonEmpty()"
                      :return-object="false"
                      autocomplete="off"
                  >
                    <template #selection="{ item }">
                      <v-chip class="mx-1" small>{{ item.text }}</v-chip>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="2" class="align-center">
                  <v-select
                      dense
                      label="Potentiel"
                      v-model="form.potential"
                      item-text="text"
                      item-value="value"
                      :items="potentials"
                      :return-object="false"
                      autocomplete="off"
                  >
                    <template #selection="{ item }">
                      <v-chip class="mx-1" small>{{ item.text }}</v-chip>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row dense class="justify-space-between">
                <v-col cols="12" md="4">
                  <v-text-field
                      dense
                      label="Email"
                      v-model="form.email"
                      :rules="rules.nonEmpty()"
                      autocomplete="off"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                      dense
                      label="Email compta"
                      v-model="form.emailAccounting"
                      autocomplete="off"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                      dense
                      label="Téléphone"
                      v-model="form.phone"
                      :rules="rules.nonEmpty()"
                      autocomplete="off"
                  ></v-text-field>
                </v-col>
                <v-col class="align-center" cols="12" md="1">
                  <v-select
                      dense
                      label="Com. hotel seul"
                      v-model="form.commission"
                      item-text="name"
                      item-value="value"
                      :items="commissions"
                      :rules="rules.nonEmpty()"
                      autocomplete="off"
                  >
                    <template #selection="{ item }">
                      <v-chip class="mx-1" small>{{ item.name }}</v-chip>
                    </template>
                  </v-select>
                </v-col>
                <v-col class="align-center" cols="12" md="1">
                  <v-select
                      dense
                      label="Com. hotel + vols"
                      v-model="form.flightCommission"
                      item-text="name"
                      item-value="value"
                      :items="commissions"
                      :rules="rules.nonEmpty()"
                      :disabled="!form.flightPriceclass || form.flightPriceclass.id != 19"
                      autocomplete="off"
                  >
                    <template #selection="{ item }">
                      <v-chip class="mx-1" small>{{ item.name }}</v-chip>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row dense class="justify-space-between">
                <v-col cols="12" md="4" class="align-center">
                  <v-select
                      dense
                      multiple
                      label="Réseau"
                      v-model="form.networks"
                      item-text="name"
                      item-value="id"
                      :items="networks"
                      :rules="rules.nonEmpty()"
                      autocomplete="off"
                      return-object
                  >
                    <template #selection="{ item }">
                      <v-chip class="mx-1" small>{{ item.name }}</v-chip>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4" class="align-center">
                  <v-select
                      dense
                      label="Mode de règlement"
                      v-model="form.paymentTermId"
                      item-text="name"
                      item-value="id"
                      :disabled="isCommercial && !isCommercialAdmin"
                      :items="paymentTerms"
                      :rules="rules.nonEmpty()"
                      autocomplete="off"
                  >
                    <template #selection="{ item }">
                      <v-chip class="mx-1" small>
                        {{ item.name }} |
                        {{ $t("paymentterm." + item.type) }}
                      </v-chip>
                    </template>
                    <template #item="{ item }"
                    >{{ item.name }} |
                      {{ $t("paymentterm." + item.type) }}
                    </template
                    >
                  </v-select>
                </v-col>
                <v-col cols="6" md="4" class="align-center">
                  <v-text-field
                      dense
                      label="Nbr max d'options"
                      v-model="form.optionMax"
                      autocomplete="off"
                  ></v-text-field>
                </v-col>
                <v-checkbox dense
                            v-model="form.touristTax"
                            label="Taxe de Séjour"
                ></v-checkbox>
              </v-row>
              <v-row dense class="justify-space-between">
                <v-col cols="12" md="4" class="align-center">
                  <v-select
                      dense
                      label="Classe de prix public"
                      v-model="form.publicPriceclass"
                      item-text="name"
                      :disabled="isCommercial  && !isCommercialAdmin"
                      :items="priceclasses"
                      :rules="rules.nonEmpty()"
                      autocomplete="off"
                      return-object
                  >
                    <template #selection="{ item }">
                      <v-chip class="mx-1" small>{{ item.name }}</v-chip>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="4" class="align-center">
                  <v-select
                      dense
                      label="Classe de prix barré"
                      v-model="form.strokePriceclass"
                      item-text="name"
                      :disabled="isCommercial  && !isCommercialAdmin"
                      :items="priceclasses"
                      :rules="rules.nonEmpty()"
                      autocomplete="off"
                      return-object
                  >
                    <template #selection="{ item }">
                      <v-chip class="mx-1" small>{{ item.name }}</v-chip>
                    </template>
                  </v-select>
                </v-col>

                <v-col cols="12" md="4" class="align-center">
                  <v-select
                      dense
                      label="Classe de prix vol"
                      v-model="form.flightPriceclass"
                      item-text="name"
                      :disabled="isCommercial && !isCommercialAdmin"
                      :items="priceclasses.filter(p => p.id == 19)"
                      :rules="[]"
                      autocomplete="off"
                      return-object
                      clearable
                  >
                    <template #selection="{ item }">
                      <v-chip class="mx-1" small>{{ item.name }}</v-chip>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row class>
                <v-col cols="12" md="4">
                  <v-text-field
                      dense
                      label="N°Siren"
                      v-model="form.siren"
                      autocomplete="off"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                      dense
                      label="N°RCS"
                      v-model="form.rcs"
                      autocomplete="off"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                      dense
                      label="Licence"
                      v-model="form.licenceNumber"
                      autocomplete="off"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider class="my-8"></v-divider>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      dense
                      label="Adresse"
                      v-model="form.address"
                      :rules="rules.nonEmpty()"
                      autocomplete="off"
                  ></v-text-field>
                  <v-text-field
                      dense
                      label="Complément d'adresse"
                      v-model="form.address2"
                      autocomplete="off"
                  ></v-text-field>
                  <v-row class="justify-space-between mt-1">
                    <v-col cols="12" md="2" class="mr-4">
                      <v-text-field
                          dense
                          label="Code postal"
                          v-model="form.postcode"
                          :rules="rules.nonEmpty()"
                          autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="mr-4">
                      <v-text-field
                          dense
                          label="Ville"
                          v-model="form.city"
                          :rules="rules.nonEmpty()"
                          autocomplete="off"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" class="mr-4">
                      <v-select
                          dense
                          small-chips
                          label="Région"
                          v-model="form.marketingAreaId"
                          :items="marketingAreas"
                          item-text="name"
                          item-value="id"
                          autocomplete="off"
                          chips deletable-chips
                       />
                    </v-col>
                    <v-col cols="12" md="3" class="justify-end">
                      <v-select
                          dense
                          label="Pays"
                          v-model="form.country"
                          :items="countries"
                          :rules="rules.nonEmpty()"
                          item-text="name"
                          autocomplete="off"
                          item-value="id"
                          return-object
                      >
                        <template #selection="{ item }">
                          <v-chip class="mx-1" small>{{ item.name }}</v-chip>
                        </template>
                      </v-select>
                    </v-col>
                  </v-row>
                  <v-divider class="my-4"></v-divider>

                  <v-textarea
                      dense
                      :outlined="_isMobile()"
                      rows="2"
                      label="Mentions légales"
                      v-model="form.mentions"
                      autocomplete="off"
                      :rules="rules.nonEmpty()"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row :class="!_isMobile() ? 'justify-end' : 'justify-center'">
                <v-col cols="6" md="2">
                  <smart-btn type="submit" :disabled="!form?.valid" @click="save"
                  >Enregistrer
                  </smart-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import SmartSelect from "../../components/commons/SmartSelect";
import FormMixin from "../mixins/FormMixin";
import {get, post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";

export default {
  name: "CrmAgencyInfo",
  mixins: [FormMixin],
  components: {
    SmartSelect,
  },
  props: {
    data: {
      type: Object,
      required: false,
    },
  },
  data: (vm) => ({
    fileSizeError: false,
    commissions: [],
    countries: [],
    marketingAreas: [],
    priceclasses: [],
    networks: [],
    paymentTerms: [],
    potentials: [
      {value: 1, text: "Ne pas démarcher"},
      {value: 2, text: "Faible potentiel"},
      {value: 3, text: "Fort potentiel"}
    ],
    agencyStatus: [
      {value: "pending", text: "En attente de validation"},
      {value: "validated", text: "Validée"},
      {value: "innactive", text: "Desactivée"},
    ],
    strokePriceclasses: [],
    flightPriceclasses: [],
    form: {
      valid: true,
      name: null,
      status: "validated",
      phone: null,
      profileImg: null,
      commission: 13,
      flightCommission: 13,
      siren: "-",
      email: null,
      emailAccounting: null,
      rcs: "-",
      code: "-",
      licenceNumber: "-",
      paymentTermId: null,
      address: null,
      address2: null,
      postcode: null,
      city: null,
      country: null,
      legalDisclaimer: null,
      marketingAreaId: null,
      publicPriceclass: {
        id: 10,
        name: "Vente hlagon",
        withFlight: false,
      },
      strokePriceclass: {
        id: 3,
        name: "Prix barré hotelissima",
        withFlight: false,
      },
      flightPriceclass: null,
      optionMax: 0,
      termType: null,
      networks: [],
      potential: null,
      touristTax: false,
    },
  }),
  created() {
    this.init();
  },
  computed: {},
  methods: {
    init() {
      this.setCommissions();
      const Countries = this.getCountries();
      const PublicPriceclasses = this.getPublicPriceclasses();
      const PaymentTerms = this.getPaymentTerms();
      const Networks = this.getNetworks();
      this.getMarketingAreas()

      Promise.allSettled([
        Countries,
        PublicPriceclasses,
        PaymentTerms,
        Networks,
      ]).then(() => {
        console.log(this.data.marketingAreaIds)
        if (this.data) {
          this.form = this.data
          this.form.potential = this.data.potential
          this.data.marketingAreaId =  this.form.marketingAreaIds ?? null

        }
        if (this.form.paymentTermId == null) {
          this.form.paymentTermId = 1;
        }
      });
    },
    getPublicPriceclasses() {
      return get("/api/search/priceclasstypes").json((priceclasses) => {
        this.priceclasses = priceclasses;
      });
    },
    getNetworks() {
      return get("/api/search/networks").json((networks) => {
        this.networks = networks;
      });
    },
    getPaymentTerms() {
      return get("/api/search/paymentterms").json((paymentTerms) => {
        this.paymentTerms = paymentTerms;
      });
    },
    getCountries() {
      const url = "/api/search/countries?q";

      return get(url)
          .badRequest((br) => {
            //handle
            console.log("Error: ", br);
          })
          .json((data) => {
            this.countries = data;
          });
    },
    setCommissions() {
      for (let i = 0; i <= 30; i++) {
        this.commissions.push({id: i, name: `${i}%`, value: i});
      }
    },
    save() {
      if (!this.$refs.agencyform.validate()) {
        return;
      }

      const xhr = this.data ? this.update() : this.create();
      xhr.then(() => EventBus.$emit("reloadLogs"));

    },
    create() {
      const url = `/api/agency/create`;

      let form = {
        ...this.form,
        ...{
          login: this.form.email,
        },
        marketingAreaIds: this.form.marketingAreaId != null ? [this.form.marketingAreaId] : [],
        commission: this.form.commission ? this.form.commission : 0,
      };

      return post(url, form)
          .badRequest((br) => {
            console.log("Error: ", br);
          })
          .json((data) => {
            EventBus.$emit("toaster-msg", "L'agence créée.");
            this.form = data;
            this.form.marketingAreaId = this.form.marketingAreaIds
          })
          .then(() => {
            this.$router.push({
              name: "CrmAgencyDetails",
              params: {id: this.form.id},
            });
            this.form.valid = true;
          });
    },
    update() {
      const url = `/api/agency/${this.form.id}/edit`;

      let form = {
        ...{
          code: "",
          siren: "",
          rcs: "",
        },
        ...this.form,
        marketingAreaIds: this.form.marketingAreaId != null ? [this.form.marketingAreaId] : [],
        commission: this.form.commission ? this.form.commission : 0,
      };

      return post(url, {userId: this.currentUser.id, agencyAccount: form})
          .badRequest((br) => {
            console.log("Error: ", br);
          })
          .json((data) => {
            EventBus.$emit(
                "toaster-msg",
                "Vos informations ont été mises à jour."
            );
            data.marketingAreaIds = data.marketingAreaIds != null && data.marketingAreaIds.length > 0 ? data.marketingAreaIds[0] : null
            this.form = data;
            this.form.marketingAreaId = data.marketingAreaIds
            this.form.valid = true;
          });
    },
    getMarketingAreas() {
      const url = "/api/search/marketingarea";

      get(url)
          .badRequest((br) => {
            console.log("Error", br);
          })
          .json((data) => {
            this.marketingAreas = data
          });
    },
    removeMarketingArea(){
      console.log("in remove func")
      this.form.marketingAreaId = null
      console.log(this.form.marketingAreaId)

    }
  },
};
</script>

