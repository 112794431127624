import SupplierInvoiceList from "@/views/accounting/supplierinvoice/SupplierInvoiceList.vue";
import SupplierInvoice from "@/views/accounting/supplierinvoice/SupplierInvoice.vue";
import PrestationList from "@/views/accounting/PrestationList.vue";
//import Accounting from '@/views/accounting/Accounting.vue'
import SupplierAccountingEdit from "@/views/accounting/SupplierAccountingEdit.vue";
import ExportVentes from "@/views/accounting/exports/ExportVentes.vue";
import ExportClients from "@/views/accounting/exports/ExportClients.vue";
import ExportEcritures from "@/views/accounting/exports/ExportEcritures.vue";
import Noop from "@/components/Noop";
import SupplierInvoiceImports from "../views/accounting/supplierinvoice/SupplierInvoiceImports";
import PrestationInvoiceReport from "../views/accounting/PrestationInvoiceReport";
import AccountingTVA from "../views/accounting/AccountingTVA";
import ExportCheck from "../views/accounting/exports/ExportCheck";
import UnpaidBookings from "../views/accounting/UnpaidBookings";
import CurrenciesPayments from "../views/accounting/currenciespayments/CurrenciesPayments";
import CurrenciesPaymentsHistory from "../views/accounting/currenciespayments/CurrenciesPaymentsHistory";
import CentralPayments from "../views/accounting/CentralPayments";
import SupplierInvoicePayment from "@/views/accounting/supplierinvoice/SupplierInvoicePayment.vue";
import PledgPayment from "@/views/accounting/supplierinvoice/PledgPayment.vue";
import PaymentsTerm from "@/views/accounting/PaymentsTerm.vue";
import WalletList from "@/views/accounting/wallets/WalletList.vue";
import {Role} from "@/utils/user";
const SupplierInvoiceRoutes = [
  {
    path: "/accounting",
    name: "accounting",
    component: Noop,
    meta: {
      breadcrumb: [
        { name: "Comptabilité", route: { name: "accounting" } },
        { name: "Factures fournisseurs" },
      ],
    },
    children: [
      {
        path: "supplier-invoice",
        name: "supplier-invoice-list",
        component: SupplierInvoiceList,
        meta: {
          breadcrumb: [
            { name: "Comptabilité", route: { name: "accounting" } },
            { name: "Factures fournisseurs" },
          ],
        },
      },
      {
        path: "supplier-invoice-imports",
        name: "supplier-invoice-imports",
        component: SupplierInvoiceImports,
        meta: {
          breadcrumb: [
            { name: "Comptabilité", route: { name: "accounting" } },
            { name: "Factures fournisseurs" },
          ],
        },
      },
      {
        path: "supplier-invoice-payment",
        name: "supplier-invoice-payment",
        component: SupplierInvoicePayment,
        meta: {
          breadcrumb: [
            { name: "Comptabilité", route: { name: "accounting" } },
            { name: "Factures fournisseurs" },
          ],
        },
      },
    ],
  },

  {
    path: "/accounting/supplier-invoice/:id",
    name: "supplier-invoice",
    component: SupplierInvoice,
    props: true,
    meta: {
      breadcrumb: [
        { name: "Comptabilité", route: { name: "accounting" } },
        {
          name: "Factures fournisseurs",
          route: { name: "supplier-invoice-list" },
        },
        {
          name: (params) =>
            params[0] === "create"
              ? "Nouvelle Facture"
              : `Facture n°${params[0]}`,
        },
      ],
    },
  },
  {
    path: "/accounting/prestations",
    name: "prestations-list",
    component: PrestationList,
    meta: {
      breadcrumb: [
        { name: "Comptabilité", route: { name: "accounting" } },
        { name: "Prestations" },
      ],
    },
  },
  {
    path: "/accounting/suppliers",
    name: "SupplierAccountingEdit",
    component: SupplierAccountingEdit,
    meta: {
      breadcrumb: [
        { name: "Comptabilité", route: { name: "accounting" } },
        { name: "Fournisseurs " },
      ],
    },
  },
  {
    path: "/accounting/exports",
    component: Noop,
    children: [
      { path: "", name: "export-ventes", component: ExportVentes },
      { path: "clients", name: "export-clients", component: ExportClients },
      {
        path: "ecritures",
        name: "export-ecritures",
        component: ExportEcritures,
      },
      { path: "check", name: "export.check", component: ExportCheck },
    ],
  },
  {
    path: "/accounting/invoices-report",
    name: "prestation-invoice-report",
    component: PrestationInvoiceReport,
    meta: {
      breadcrumb: [
        { name: "Compatibilité", route: { name: "accounting" } },
        { name: "Rapport de facturation des prestations" },
      ],
    },
  },
  {
    path: "/accounting/tva",
    name: "tva",
    component: AccountingTVA,
    meta: {
      breadcrumb: [
        { name: "Compatibilité", route: { name: "accounting" } },
        { name: "TVA" },
      ],
    },
  },
  {
    path: "/accounting/unpaid-bookings",
    name: "unpaid-bookings",
    component: UnpaidBookings,
    meta: {
      breadcrumb: [
        { name: "Compatibilité", route: { name: "accounting" } },
        { name: "Dossiers impayés" },
      ],
    },
  },
  {
    path: "/accounting/currencies",
    component: Noop,
    children: [
      {
        path: "cover",
        name: "currencies-payments",
        component: CurrenciesPayments,
      },
      {
        path: "payment-history",
        name: "currencies-payments-history",
        component: CurrenciesPaymentsHistory,
      },
    ],
  },
  {
    path: "/accounting/central-payments",
    name: "central-payments",
    component: CentralPayments,
    meta: {
      breadcrumb: [
        { name: "Compatibilité", route: { name: "accounting" } },
        { name: "Centrale de paiement" },
      ],
    },
  },
  {
    path: "/accounting/pledg",
    name: "pledg",
    component: PledgPayment,
    meta: {
      breadcrumb: [
        { name: "Compatibilité", route: { name: "accounting" } },
        { name: "Pledg" },
      ],
    },
  },
    {
    path: "/accounting/payment-term",
    name: "payment-term",
    component: PaymentsTerm,
    meta: {
      breadcrumb: [],
    },
  },
    {
    path: "/accounting/wallets",
    name: "WalletList",
    component: WalletList,
    meta: {
      roles: [Role.wallet, Role.admin],
      breadcrumb: [

      ],
    },
  },
];

export default SupplierInvoiceRoutes;
