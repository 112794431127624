<template>
  <div class>
    <v-toolbar>
      <v-container fluid class="pa-0">
        <v-row dense class>
          <v-col cols="3" class="px-3" align-self="center">
            <v-autocomplete
                v-model="ctx.from"
                :items="transferspoints"
                hide-no-data
                item-text="name"
                item-value="(item) => (item.pointType + item.id)"
                return-object
                dense
                hide-details
                :label="$t('departure')"
                :search-input.sync="searchTermFrom"
                :error="isFromError"
                :append-icon="ctx.from ? '' : 'search'"
                :clearable="ctx.from != ''"
                :filter="filterPoints"
            >
              <template #item="data" class="dense">
                <v-list-item-action>
                  <v-icon>{{ renderIcon(data.item.pointType) }}</v-icon>
                </v-list-item-action>
                <v-list-item-content
                    v-text="data.item.name"
                ></v-list-item-content>
              </template>
              <template slot="selection" slot-scope="data">
                <v-icon>{{ renderIcon(data.item.pointType) }}</v-icon>
                <span class="ml-2">{{ data.item.name }}</span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-btn
              @click="swapLocations"
              class="my-4 swap-btn text"
              icon
              align-self="center"
          >
            <v-icon>mdi-swap-horizontal</v-icon>
          </v-btn>
          <v-col cols="3" class="px-3" align-self="center">
            <v-autocomplete
                v-model="ctx.to"
                :items="transferspoints"
                hide-no-data
                item-text="name"
                item-value="(item) => (item.pointType + item.id)"
                hide-details
                dense
                :label="$t('arrival')"
                :search-input.sync="searchTermTo"
                return-object
                :error="isToError"
                :append-icon="ctx.to ? '' : 'search'"
                :clearable="ctx.to != ''"
                :filter="filterPoints"
            >
              <template slot="item" slot-scope="data">
                <v-list-item-action>
                  <v-icon>{{ renderIcon(data.item.pointType) }}</v-icon>
                </v-list-item-action>
                <v-list-item-content
                    v-text="data.item.name"
                ></v-list-item-content>
              </template>
              <template slot="selection" slot-scope="data">
                <v-icon>{{ renderIcon(data.item.pointType) }}</v-icon>
                <span class="ml-2">{{ data.item.name }}</span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="2" class="px-3" align-self="center">
            <booking-guest-repartition
                :guests="booking.globalGuests"
                v-model="ctx.guests"
            ></booking-guest-repartition>
          </v-col>
          <v-col cols="1" class="px-3" align-self="center">
            <smart-picker
                v-model="ctx.start"
                format="dd/MM/yyyy"
                :inputOpts="{ label: $t('begin') }"
            ></smart-picker>
          </v-col>
          <v-col cols="1" class="px-3" align-self="center">
            <v-select
                :items="vehicles"
                label="Véhicules"
                v-model="ctx.vehicles"
                hide-details
                multiple
                dense
            ></v-select>
          </v-col>
          <v-col cols="1" class="px-3 text-right" align-self="center">
            <v-btn @click.native="search" :loading="loading">
              <v-icon>search</v-icon>
              {{ $t("search") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>

    <v-container fluid>
      <v-row dense justify="center">
        <v-col class="text-center" cols="12" v-show="loading">
          <v-progress-circular
              v-show="loading"
              indeterminate
              color="primary"
              :width="3"
              :size="70"
          ></v-progress-circular>
        </v-col>
        <v-col
            cols="12"
            class="pl-2 pr-2 text-center"
            v-show="!loading"
            v-if="transfers.length == 0"
        >
          <span v-if="hadSearchedOnce">{{ $t("no-results") }}</span>
          <span v-else>{{ $t("please-search") }}</span>
        </v-col>
        <v-col cols="8" class="pl-2 pr-2" v-show="!loading">
          <transfer-price
              class="pa-2 mb-2"
              v-for="(transfer, i) in transfers"
              :key="i"
              :transferprice="transfer"
              :transfer-selected="transferSelected"
              :bookingid="booking.id"
              :ctx="ctx"
          ></transfer-price>
        </v-col>
        <!---------- prestation recap ---------->
        <v-col class="mt-1 pa-1" cols="2" v-if="sumPrestation">
          <v-card xs9>
            <booking-drawer-content
                v-if="booking"
                :booking="booking"
            ></booking-drawer-content>
          </v-card>
        </v-col>
        <!---------- end prestation recap ---------->
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {get, post} from "@/utils/api";
import {EventBus} from "@/components/commons/event-bus";
import TransferPrice from "@/components/booking/transfer/TransferPrice";
import RenderIconMixin from "@/components/mixins/RenderIconMixin";
import BookingGuestRepartition from "@/components/commons/BookingGuestRepartition";
import BookingDrawerContent from "@/components/commons/BookingDrawerContent";

export default {
  name: "AddTransfer",
  components: {BookingGuestRepartition, TransferPrice, BookingDrawerContent},
  mixins: [RenderIconMixin],
  props: {
    booking: {
      type: Object,
      required: true,
    },
    transferSelected: {
      type: Object,
      required: false,
    },
    sumPrestation: {required: true},
    lastPrestationDate: {required: true},
  },
  data: function () {
    return {
      loading: false,
      hadSearchedOnce: false,
      selectIndex: 0,

      isFromError: false,
      isToError: false,

      transferspoints: [],
      vehicles: [],
      transfers: [],

      searchTermFrom: null,
      searchTermTo: null,

      startMenu: null,
      startDateFormatted: null,

      start: null,
      vehiclesSelected: null,
      ctx: {
        start: null,
        stop: null,
        startBookingDate: "",
        from: null,
        to: null,
        guests: this.booking.globalGuests,
        vehicles: [],
        debug: false, //check why env isn't bind correctly on smartprice side for transfers
        pickerDate: "",
      },
    };
  },
  created() {
    this.bindCtx();
    const futPois = this.getPois();
    const futVehicles = this.getVehicles();
    this.ctx.startBookingDate = this.booking.startDate.substring(0, 10);
    this.ctx.start = this.bookingDateStop;
    Promise.all([futPois, futVehicles]).then(() => {
      if (this.transferSelected) {
        this.buildExistingTransfer();
      }
    });
  },
  watch: {
    vehiclesSelected(newVal) {
      this.ctx.vehicles = [newVal];
    },
    "ctx.from"(newVal) {
      this.isFromError = newVal == null || newVal == "";
    },
    "ctx.to"(newVal) {
      this.isToError = newVal == null || newVal == "";
    },
  },
  computed: {
    bookingDateStop: function () {
      if (
          typeof this.lastPrestationDate !== "undefined" &&
          this.lastPrestationDate !== null
      ) {
        return this.lastPrestationDate.substring(0, 10);
      } else if (this.lastPrestationDate === undefined) {
        if (
            this.booking &&
            this.booking.prestations &&
            this.booking.prestations.length == 0
        ) {
          return this.ctx.startBookingDate.substring(0, 10);
        } else if (this.booking) {
          return this.booking.stopDate !== ""
              ? this.booking.stopDate.substring(0, 10)
              : this.ctx.startBookingDate.substring(0, 10);
        }
      }
      return null;
    },
  },
  methods: {
    getPois() {
      return get("/api/prestation/add/search-pois")
          .badRequest((err) => {
            EventBus.$emit(
                "toaster-msg",
                JSON.parse(err.null).errors.map((e) => JSON.parse(e))
            );
          })
          .json((data) => {
            if(this.booking.prestations.length >0) {
              const hotelsBooking = this.booking.prestations.filter(p => p.hotelId != null)
              const listIdHotel = hotelsBooking.map(hb => hb.hotelId)
              const listNameDesti = hotelsBooking.map(hb => hb.destination.name)

              this.transferspoints = data.sort((a, b) => {
                if (a.pointType == 'POI' && listNameDesti.some(d => d == a.destination)) {
                  return -1
                }
                if (a.pointType == 'HOTEL' && listIdHotel.some(h => parseInt(a.id) == h)) {
                  return -1
                } else return 1
              });
            } else this.transferspoints = data
          });
    },
    getVehicles() {
      return get("/api/prestation/add/vehicles")
          .badRequest((err) => {
            EventBus.$emit(
                "toaster-msg",
                JSON.parse(err.null).errors.map((e) => JSON.parse(e))
            );
            this.transfers = [];
          })
          .json((data) => {
            this.vehicles = data.map((t) => {
              return {
                text: this.$t("vehicleType." + t),
                value: t,
              };
            });
          });
    },
    buildExistingTransfer() {
      this.ctx.from = this.transferspoints.find(
          (p) => p.id == this.transferSelected.fromId
      );
      this.ctx.to = this.transferspoints.find(
          (p) => p.id == this.transferSelected.toId
      );
      this.start = this._parseAndFormatDate(
          this.transferSelected.start,
          this.datePatternConfig.serverLocalDateTime,
          this.datePatternConfig.serverLocalDate
      );
      this.$nextTick(() => {
        this.doSearch();
      });
    },
    search() {
      this.isFromError = this.ctx.from === null;
      this.isToError = this.ctx.to === null;

      if (this.isFromError || this.isToError) {
        EventBus.$emit("toaster-msg", this.$t("error-form"));
      } else this.doSearch();
    },
    doSearch() {
      let ctxCopy = this.ctx;
      this.ctx.stop = this.ctx.start;
      this.hadSearchedOnce = true;
      this.loading = true;

      ctxCopy = {
        ...ctxCopy,
        start: this.ctx.start + " 00:00:00",
        stop: this.ctx.stop + " 00:00:00",
        // l'objet prestation sera fait server side car il n'y a pas d'objet.
      };

      post(`/api/prestation/add/search-transfer?bookingId=${this.booking.id}`, ctxCopy)
          .badRequest((err) => {
            EventBus.$emit("toaster-msg", JSON.parse(err.null).errors.join(","));
          })
          .json((data) => {
            this.transfers = JSON.parse(data.data);
          })
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    swapLocations() {
      let bufferFrom = this.ctx.from;
      let bufferTo = this.ctx.to;
      this.ctx.from = bufferTo;
      this.ctx.to = bufferFrom;
    },
    bindCtx() {
      this.ctx = {
        ...this.ctx,
        priceClassTypeId: this.booking.context.priceClassType.id,
        currency: {
          code: this.booking.currency,
        },
      };
    },
    filterPoints(item, query, itemText) {
      const querylc = query.toLocaleLowerCase();

      return (
          itemText.toLocaleLowerCase().indexOf(querylc) > -1 ||
          item.destination.toLocaleLowerCase().indexOf(querylc) > -1
      );
    },
  },
};
</script>

<style scoped>
.swap-btn {
  min-width: 20px !important;
}
</style>
