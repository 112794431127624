<template>
  <v-app>
    <callback-popover ref="callBack" v-if="show3cx"/>
    <v-main
        :class="$route.meta && $route.meta.plainLayout ? '' : 'app-content '+_getDevice()"
        class="grey lighten-4 "
    >
      <router-view :key="$route.fullPath"/>
    </v-main>

    <v-snackbar
        v-model="toast.show"
        :timeout="toast.timeout"
        :top="toast.top"
        :right="toast.right"
        :bottom="toast.bottom"
        :left="toast.left"
        :vertical="toast.vertical"
        :color="toast.color"
        :multi-line="toast['multi-line']"
    >
      {{ toast.text }}
      <v-btn text @click.native="toast.show = false">Ok</v-btn>
    </v-snackbar>


    <template v-if="!$route.meta.plainLayout">
      <app-menu/>
      <booking-drawer/>
    </template>

    <confirm ref="confirm" :lazy="false"/>
    <confirm-cancel ref="confirmcancel"/>
    <edit-voucher-data ref="editvoucherdata"/>

    <smart-dialog title="Mise à jour détectée" v-model="showmaj">
      <v-card-text>
        <h3>Une mise à jour a été détectée</h3>
        <p>Veuillez cliquer sur le bouton "Recharger" ou actualiser votre navigateur afin d'appliquer les nouveautés</p>
        <p>
          Si vous êtes actuellement en cours de traitement sur un dossier,
          vous pouvez fermer cette pop-up et actualiser le navigateur plus tard
        </p>
      </v-card-text>
      <v-card-actions class="actions-btn">
        <v-spacer/>
        <smart-btn @click.native="reload">
          <v-icon small class="mr-1">mdi-reload</v-icon>
          Recharger
        </smart-btn>
      </v-card-actions>
    </smart-dialog>
    <v-snackbar
        v-model="snackbars.transcription.show"
        :vertical="true"
        :timeout="-1"
    >
      <template #default>
        <div v-if="!snackbars.transcription.done">
          La transcription est en cours.
        </div>
        <div v-else>
          La transcription est terminée.
          <span v-if="isInLeadpage">
            Vous pouvez recharger la page pour mettre à jour le lead
          </span>
          <span v-else>
            Vous pouvez voir le lead mis à jour
          </span>
        </div>
      </template>
      <template v-slot:action="{ attrs }">
        <v-btn
            v-if="!snackbars.transcription.done"
            color="indigo"
            text
            v-bind="attrs"
            @click="snackbars.transcription.show = false"
        >
          Fermer
        </v-btn>
        <v-btn
            v-else
            color="indigo"
            text
            v-bind="attrs"
            @click.native="reloadLead()"
        >
          {{ isInLeadpage ? 'Recharger' : 'Voir' }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import {EventBus} from "./components/commons/event-bus";
import AppMenu from "./components/commons/AppMenu";
import Confirm from "./components/commons/Confirm";
import ConfirmCancel from "./components/commons/ConfirmCancel";
import BookingDrawer from "./components/commons/BookingDrawer";
import EditVoucherData from "./components/commons/EditVoucherData";
import CallbackPopover from "./components/commons/CallbackPopover";
import {get} from "@/utils/api";

export default {
  name: "App",
  components: {
    EditVoucherData,
    BookingDrawer,
    Confirm,
    ConfirmCancel,
    AppMenu,
    CallbackPopover
  },
  mounted() {
    if (this.$route.name !== 'pdf-quotation') {
      const payzenScript = document.createElement('script')
      payzenScript.setAttribute('src', 'https://api.payzen.eu/static/js/krypton-client/V4.0/ext/classic.js')

      const payzenLink = document.createElement('link')
      payzenLink.setAttribute('href', '/payzen/classic.css')
      payzenLink.setAttribute('rel', 'stylesheet')

      const krypton = document.createElement('link')
      krypton.setAttribute('href', 'https://api.payzen.eu/static/js/krypton-client/V4.0/ext/classic.css')
      krypton.setAttribute('rel', 'stylesheet')

      const tinymce = document.createElement('link')
      tinymce.setAttribute('href', '/tinymce/skins/ui/oxide/skin.min.css')
      tinymce.setAttribute('media', 'all')
      tinymce.setAttribute('rel', 'stylesheet')
      tinymce.setAttribute('type', 'text/css')


      document.head.appendChild(payzenLink)
      document.head.appendChild(krypton)
      document.head.appendChild(tinymce)
      document.head.appendChild(payzenScript)
    }

    this.$root.$confirm = this.$refs.confirm.open;
    this.$root.$confirmcancel = this.$refs.confirmcancel.open;
    this.$root.$editvoucherdata = this.$refs.editvoucherdata.open;
  },
  created: function () {
    // check route not /login
    EventBus.$on("unauthorized-route", () => {
      this.$root
      .$confirm(
          this.$t("confirm"),
          "Vous avez été déconnecté, veuillez vous reconnecter.",
          {width: 350}
      )
      .then(() => {
        this.$router.push({
          name: "Login",
          query: {redirect: this.$router.history.current.fullPath},
        });
      });
    });
    EventBus.$on("token", (token) => {
      this.setToken(token);
      this.show3cx = true;
    });
    EventBus.$on("logout", () => {
      this.show3cx = false;
    })
    EventBus.$on("forcelogout", () => {
      this.logout();
    });

    EventBus.$on("snackbarTranscription", (payload) => {
      this.snackbars.transcription = payload
    });

    this.toast = this.toastDefaults;

    EventBus.$on("toast", (msg) => {
      if (msg.exception) {
        console.error(msg.exception);
      }
      this.toast = Object.assign(this.toastDefaults, msg, {show: true});
      setTimeout(() => {
        this.toast = this.toastDefaults;
      }, this.toast.timeout);
    });
    EventBus.$on("toaster-msg", (msg) => {
      this.renderToaster(msg, false);
    });
    EventBus.$on("toaster-error", (msg) => {
      this.renderToaster(msg, true);
    });

    if (process.env.NODE_ENV === "production") {
      this.handleVersionning();
    }
  },
  destroyed: function () {
    EventBus.$off("toaster-msg");
    EventBus.$off("toaster-error");
    EventBus.$off("snackbarTranscription");
    if (this.connection) {
      this.connection.close();
    }
  },
  data() {
    return {
      show3cx: false,
      showmaj: false,
      showDisconnected: false,
      toast: null,
      toastDefaults: {
        show: false,
        text: "",
        color: "",
        vertical: false,
        top: true,
        right: true,
        bottom: false,
        left: false,
        timeout: 2000,
        ex: null,
        "auto-height": false,
        "multi-line": false,
      },
      snackbars: {
        transcription: {
          show: false,
          done: false,
          leadId: null
        }
      },

      connection: null
    };
  },
  computed: {
    isInLeadpage() {
      return window.location.pathname.includes('/lead/')
    }
  },
  methods: {
    renderToaster(text, error) {
      this.toast = Object.assign(this.toastDefaults, {
        text: text,
        show: true,
        color: error ? "error" : "",
      });
    },
    handleVersionning() {
      const self = this;
      const url = "/api/widget/buildinfos";

      var firstloop = true;

      const intervalBuildInfos = setInterval(() => {
        get(url).json((r) => {
          const infos = JSON.parse(r.infos);
          const isdev = r.isdev;
          if (!isdev) {
            if (
                !firstloop &&
                localStorage &&
                localStorage.getItem("buildInfos")
            ) {
              const localInfos = JSON.parse(localStorage.getItem("buildInfos"));
              const isSame = localInfos.builtAtMillis === infos.builtAtMillis;

              if (!this.showmaj) {
                self.showmaj = !isSame;
              }
            }

            if (localStorage) {
              localStorage.setItem("buildInfos", JSON.stringify(infos));
            }
          } else {
            clearInterval(intervalBuildInfos);
          }

          firstloop = false;
        });
      }, 60000);
    },
    reload() {
      document.location.reload();
    },
    reloadLead() {

      if (this.isInLeadpage) {
        this.reload()
      } else {
        this.$router.push({name: "lead", params: {id: this.snackbars.transcription.leadId}});
      }

      this.$nextTick(() => {
        this.snackbars.transcription.done = false
        this.snackbars.transcription.show = false
      })
    },
    redirectLogin() {
      this.$router.push({
        name: "Login",
        query: {redirect: this.$router.history.current.fullPath},
      });
    },
  },
};
</script>

<style scoped>
.v-main.app-content.desktop {
  padding-left: 80px !important;
}

.v-main {
  background: #eee;
}

</style>
