var render = function render(){var _vm=this,_c=_vm._self._c;return _c('layout',{attrs:{"drawerShow":_vm._isDesktop(),"drawerWidth":350}},[_c('template',{slot:"nav-tools"},[_c('smart-btn',{staticClass:"mr-1",nativeOn:{"click":function($event){_vm.createDialog = true}}},[_c('v-icon',[_vm._v("mdi-folder-plus")]),_c('span',{staticClass:"pl-1"},[_vm._v("Créer une action")])],1)],1),_c('template',{slot:"drawer"},[(_vm.searchform)?_c('v-form',{ref:"searchform",on:{"submit":function($event){$event.preventDefault();return _vm.getList.apply(null, arguments)}}},[_c('v-row',{staticClass:"pt-2 pr-1",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-1 mt-4",attrs:{"cols":"12"}},[_c('smart-multi-select',{attrs:{"label":"Responsable","items":_vm.users,"all-is-same-as-empty":true,"default-select-all":true,"item-text":"name","item-value":"id"},model:{value:(_vm.searchform.usersIds),callback:function ($$v) {_vm.$set(_vm.searchform, "usersIds", $$v)},expression:"searchform.usersIds"}})],1),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"pa-0 mb-0 mx-0 form-subheader",attrs:{"light":""}},[_vm._v("Dates limite ")]),_c('v-divider')],1),_c('v-col',{staticClass:"pr-1",attrs:{"cols":"6"}},[_c('smart-picker',{attrs:{"stopDate":_vm.searchform.taskExpirationStopDate,"inputOpts":{
              label: _vm.$t('begin'),
              clearable: true,
              placeholder: ' ',
            }},model:{value:(_vm.searchform.taskExpirationStartDate),callback:function ($$v) {_vm.$set(_vm.searchform, "taskExpirationStartDate", $$v)},expression:"searchform.taskExpirationStartDate"}})],1),_c('v-col',{staticClass:"pr-1",attrs:{"cols":"6"}},[_c('smart-picker',{attrs:{"startDate":_vm.searchform.taskExpirationStartDate,"inputOpts":{
              label: _vm.$t('end'),
              clearable: true,
              placeholder: ' ',
            }},model:{value:(_vm.searchform.taskExpirationStopDate),callback:function ($$v) {_vm.$set(_vm.searchform, "taskExpirationStopDate", $$v)},expression:"searchform.taskExpirationStopDate"}})],1),_c('v-col',{staticClass:"pr-1 mt-8",attrs:{"cols":"12"}}),_c('smart-multi-select',{attrs:{"label":"Niveaux","items":_vm.types,"all-is-same-as-empty":true,"default-select-all":true},model:{value:(_vm.searchform.types),callback:function ($$v) {_vm.$set(_vm.searchform, "types", $$v)},expression:"searchform.types"}}),_c('smart-multi-select',{staticClass:"mt-4",attrs:{"label":"Groupe","items":_vm.groups,"all-is-same-as-empty":true,"default-select-all":true},model:{value:(_vm.searchform.groups),callback:function ($$v) {_vm.$set(_vm.searchform, "groups", $$v)},expression:"searchform.groups"}})],1),_c('smart-btn',{attrs:{"loading":_vm.listing.loading,"type":"submit","block":"","primary":""}},[_vm._v("Rechercher ")]),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('v-subheader',{staticClass:"pa-0 mb-0 mx-0 form-subheader",attrs:{"light":""}},[_vm._v("Légende ")]),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{staticClass:"pastTask",attrs:{"dense":""}},[_c('small',[_vm._v("Expiration passé")])]),_c('v-row',{staticClass:"todoTask mt-1",attrs:{"dense":""}},[_c('small',[_vm._v("Expiration aujourd'hui")])])],1)],1):_vm._e()],1),_c('template',{slot:"content"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{ref:"tasktable",staticClass:"tbl",attrs:{"loading":_vm.listing.loading,"headers":_vm.listing.headers,"items":_vm.listing.items,"options":_vm.listing.pagination,"footer-props":{
              'items-per-page-options': _vm.listing.rowsPerPageItems,
              'items-per-page-text': 'Lignes par page',
            },"expand":true,"item-key":"ref","no-data-text":"Il n'y a pas d'actions disponibles"},on:{"update:options":function($event){return _vm.$set(_vm.listing, "pagination", $event)}},scopedSlots:_vm._u([{key:"item.site",fn:function({ item, value }){return [_c('div',{class:_vm.priorityColor(item),staticStyle:{"line-height":"48px","vertical-align":"middle"}},[_c('site-label',{attrs:{"sitename":value}})],1)]}},{key:"item.bookingId",fn:function({ value,item }){return [_c('span',{on:{"click":[function($event){if(!$event.ctrlKey)return null;return _vm.goto(value, true)},function($event){if(!$event.metaKey)return null;return _vm.goto(value, true)},function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.goto(value)}]}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.bookingDate",fn:function({ item, value }){return [_vm._v(" "+_vm._s(_vm._parseAndFormatDate( value, _vm.datePatternConfig.serverLocalDateTime, _vm.datePatternConfig.serverLocalDate ))+" ")]}},{key:"item.bookingStartDate",fn:function({ item, value }){return [_vm._v(" "+_vm._s(_vm._parseAndFormatDate( value, _vm.datePatternConfig.serverLocalDateTime, _vm.datePatternConfig.serverLocalDate ))+" ")]}},{key:"item.expirationDate",fn:function({ item, value }){return [_vm._v(" "+_vm._s(_vm._parseAndFormatDate( value, _vm.datePatternConfig.serverLocalDateTime, _vm.datePatternConfig.serverLocalDate ))+" ")]}},{key:"item.task.action",fn:function({ item, value }){return [_c('v-chip',{class:_vm.taskTextColor(item.task.level) +
                  ' ' +
                  _vm.taskColor(item.task.level),attrs:{"label":"","small":"","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t("tasklevel." + item.level))+" ")]),_c('span',{class:_vm.colorsTaskType(item)},[(item.prestationType && item.prestationName)?_c('v-icon',{staticClass:"ml-1",class:_vm.colorsTaskType(item),attrs:{"small":""}},[_vm._v("mdi-"+_vm._s(_vm.icon(item.prestationType)))]):_vm._e(),_vm._v(" "+_vm._s(item.message)+" "+_vm._s(item.prestationName)+" ")],1)]}},{key:"item.bookingStatus",fn:function({value, item}){return [_c('span',[_c('availability',{attrs:{"x-small":"","kind":"BookingStatus"},model:{value:(item.bookingStatus),callback:function ($$v) {_vm.$set(item, "bookingStatus", $$v)},expression:"item.bookingStatus"}}),_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(item.optionDate)?_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"small":""}},on),[_vm._v("mdi-clock-outline ")]):_vm._e()]}}],null,true)},[_vm._v(" Option jusqu'au "+_vm._s(item.optionDate)+" ")])],1)]}},{key:"item.edition",fn:function({ item, value }){return [_c('v-icon',{nativeOn:{"click":function($event){item.task.editDialog = true}}},[_vm._v("mdi-playlist-edit ")]),_c('v-icon',{nativeOn:{"click":function($event){item.task.attributeDialog = true}}},[_vm._v("mdi-swap-horizontal ")]),_c('todo-item',{attrs:{"task":item.task},on:{"save":function($event){return _vm.getList()}}}),_c('attribute-booking-task',{attrs:{"task":item.task,"users":_vm.users,"show":item.task.attributeDialog},on:{"update:show":function($event){return _vm.$set(item.task, "attributeDialog", $event)},"attributed":function($event){item.task.attributeDialog = false;
                  _vm.getList();}}})]}}])}),_c('smart-dialog',{attrs:{"title":"Création"},model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('task-edit',{on:{"saved":function($event){_vm.createDialog = false;
                _vm.getList();},"change":_vm.change}})],1)],1)],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }